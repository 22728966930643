export const SET_PHOTO_UPLOAD_MODAL_VISIBLE = 'SET_PHOTO_UPLOAD_MODAL_VISIBLE';
export const SET_TUTORIAL_STEP = 'SET_TUTORIAL_STEP';
export const SET_EDITABLE_INPUT_ID = 'SET_EDITABLE_INPUT_ID';
export const SET_SHOW_REMOVED_TASKS = 'SET_SHOW_REMOVED_TASKS';
export const SET_IS_LOADING_SHARED_NOTES = 'SET_IS_LOADING_SHARED_NOTES';
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';

// Database Testing Action Types
export const FETCH_TABLE_DATA_REQUEST = 'FETCH_TABLE_DATA_REQUEST';
export const FETCH_TABLE_DATA_SUCCESS = 'FETCH_TABLE_DATA_SUCCESS';
export const FETCH_TABLE_DATA_FAILURE = 'FETCH_TABLE_DATA_FAILURE';

export const CREATE_TEST_RECORD_REQUEST = 'CREATE_TEST_RECORD_REQUEST';
export const CREATE_TEST_RECORD_SUCCESS = 'CREATE_TEST_RECORD_SUCCESS';
export const CREATE_TEST_RECORD_FAILURE = 'CREATE_TEST_RECORD_FAILURE';

export const CLEAR_DATABASE_LOGS = 'CLEAR_DATABASE_LOGS';
export const ADD_DATABASE_LOG = 'ADD_DATABASE_LOG';
export const SET_PROCESSING_TABLE = 'SET_PROCESSING_TABLE';
export const SET_SHOW_LOGS = 'SET_SHOW_LOGS';
