import React, { useState, useEffect, useRef } from 'react';
import { LIFESTYLE_FACTORS } from './data/contextData';
import "../../../assets/css/components/productChatbot.css";
import useChatGPT from '../../redux/chatgpt/hooks';

const ProductChatbot = ({ categoryId, categoryName, onProductSelect, onNoThanks }) => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [lifestyleContext, setLifestyleContext] = useState({});
  const [currentFactor, setCurrentFactor] = useState('living_situation');
  const messagesEndRef = useRef(null);
  const { sendMessage, loading, error } = useChatGPT();

  useEffect(() => {
    let initialMessage;
    
    // Special case for list suggestions
    if (categoryId === "suggestions") {
      initialMessage = {
        type: 'bot',
        content: "Hi there! I'm here to help you create a personalized list for your baby. I'll ask you a few questions about your preferences and needs to recommend the best items. What's your living situation like? (apartment, house, living with family, etc.)",
        educational_tip: "Your living space can impact what baby products will work best for you. For example, apartment dwellers might need more compact, multi-purpose items."
      };
    } else {
      initialMessage = {
        type: 'bot',
        content: LIFESTYLE_FACTORS[currentFactor].question,
        educational_tip: LIFESTYLE_FACTORS[currentFactor].educational_context
      };
    }
    
    setMessages([initialMessage]);
  }, [currentFactor, categoryId]);

  const getNextFactor = (currentFactor) => {
    const factors = Object.keys(LIFESTYLE_FACTORS);
    const currentIndex = factors.indexOf(currentFactor);
    return currentIndex < factors.length - 1 ? factors[currentIndex + 1] : null;
  };

  const formatTokenUsage = (tokenUsage) => {
    if (!tokenUsage) return null;
    const { current_request } = tokenUsage;
    return `Tokens used: ${current_request.tokens.total} (Cost: $${current_request.cost.toFixed(4)})`;
  };

  const handleUserInput = async (input) => {
    setMessages(prev => [...prev, {
      type: 'user',
      content: input
    }]);
    setUserInput('');
    setIsTyping(true);

    try {
      // data is here to prevent errors with the below else if statements.
      const data = {content: "placeholder"}
      const response = await sendMessage(input);

      if (typeof response === "string") {
        setMessages(prev => [...prev, {
          type: 'bot',
          content: response
        }]);
        setIsTyping(false);
        return;
      } else if (data.status === 'error') {
        setMessages(prev => [...prev, {
          type: 'bot',
          content: data.content,
          usage_message: formatTokenUsage(data.token_usage)
        }]);
      } else if (data.requires_clarification) {
        setMessages(prev => [...prev, {
          type: 'bot',
          content: data.content,
          understanding: data.understanding,
          educational_tip: data.educational_tip,
          requires_clarification: true,
          usage_message: formatTokenUsage(data.token_usage)
        }]);
      } else if (data.validation_result?.valid) {
        const updatedContext = {
          ...lifestyleContext,
          [currentFactor]: data.validation_result.matched_option
        };
        setLifestyleContext(updatedContext);

        if (data.understanding) {
          setMessages(prev => [...prev, {
            type: 'bot',
            content: data.understanding,
            is_understanding: true,
            usage_message: formatTokenUsage(data.token_usage)
          }]);
        }

        if (data.validation_result.needs_confirmation) {
          setMessages(prev => [...prev, {
            type: 'bot',
            content: `I understand you meant "${data.validation_result.matched_option.replace('_', ' ')}". Is that correct?`,
            requires_confirmation: true,
            usage_message: formatTokenUsage(data.token_usage)
          }]);
          return;
        }

        const nextFactor = getNextFactor(currentFactor);
        if (nextFactor) {
          setCurrentFactor(nextFactor);
          setMessages(prev => [...prev, {
            type: 'bot',
            content: LIFESTYLE_FACTORS[nextFactor].question,
            educational_tip: data.educational_tip,
            usage_message: formatTokenUsage(data.token_usage)
          }]);
        } else {
          setCurrentFactor(null);
          
          // Special case for list suggestions
          if (categoryId === "suggestions") {
            setMessages(prev => [...prev, {
              type: 'bot',
              content: "Great! Based on your lifestyle needs, I've created a personalized list for you.",
              is_transition: true,
              usage_message: formatTokenUsage(data.token_usage)
            }, {
              type: 'bot',
              content: "I've created 'Emma's Personalized Baby Essentials' list with items tailored to your needs. Would you like to add this list to your plan?",
              educational_tip: "This list includes only the essentials based on your specific situation, saving you time and money.",
              impacts: ["Compact items for your space", "Budget-friendly essentials", "Items suitable for your lifestyle"],
              usage_message: formatTokenUsage(data.token_usage),
              show_actions: true
            }]);
          } else {
            setMessages(prev => [...prev, {
              type: 'bot',
              content: "Great! Now I understand your lifestyle needs. Let me help you find the perfect product.",
              is_transition: true,
              usage_message: formatTokenUsage(data.token_usage)
            }, {
              type: 'bot',
              content: data.content,
              educational_tip: data.educational_tip,
              impacts: data.impacts,
              usage_message: formatTokenUsage(data.token_usage)
            }]);
          }
        }
      } else {
        setMessages(prev => [...prev, {
          type: 'bot',
          content: data.content,
          educational_tip: data.educational_tip,
          usage_message: formatTokenUsage(data.token_usage)
        }]);
      }
    } catch (error) {
      console.error('Chat API error:', error);
      setMessages(prev => [...prev, {
        type: 'bot',
        content: "I'm having trouble processing your response. Could you try rephrasing it?",
        usage_message: "Token usage unavailable due to error"
      }]);
    }
    setIsTyping(false);
  };

  return (
    <div className="chatbot-container">
      <div className="chatbot-messages">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.type} ${msg.is_understanding ? 'understanding' : ''} ${msg.is_transition ? 'transition' : ''}`}>
            <div className="message-content">
              {msg.content}
            </div>
            {msg.educational_tip && (
              <div className="educational-tip">
                <i className="tip-icon" />
                {msg.educational_tip}
              </div>
            )}
            {msg.impacts && (
              <div className="impacts">
                <h4>How this affects your choices:</h4>
                {msg.impacts.map((impact, i) => (
                  <div key={i} className="impact-item">
                    {impact}
                  </div>
                ))}
              </div>
            )}
            {msg.requires_clarification && (
              <div className="clarification-note">
                Please help me understand your preference better.
              </div>
            )}
            {msg.show_actions && (
              <div className="chatbot-actions" style={{ marginTop: '20px', display: 'flex', gap: '10px', justifyContent: 'center' }}>
                <button 
                  style={{ 
                    padding: '8px 16px', 
                    background: '#5c9dff', 
                    color: 'white', 
                    border: 'none', 
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }}
                  onClick={() => onProductSelect('suggestions', 'list-suggestion', "Emma's Personalized Baby Essentials")}
                >
                  Add to My Plan
                </button>
                <button 
                  style={{ 
                    padding: '8px 16px', 
                    background: 'transparent', 
                    color: '#666', 
                    border: '1px solid #ddd', 
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }}
                  onClick={onNoThanks}
                >
                  No Thanks
                </button>
              </div>
            )}
            {msg.usage_message && (
              <div className="usage-info">
                <small>{msg.usage_message}</small>
              </div>
            )}
          </div>
        ))}
        {isTyping && (
          <div className="typing-indicator">
            Typing...
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      <div className="chatbot-input">
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Type your response..."
          onKeyPress={(e) => e.key === 'Enter' && userInput.trim() && handleUserInput(userInput)}
        />
        <button
          onClick={() => userInput.trim() && handleUserInput(userInput)}
          disabled={!userInput.trim()}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default ProductChatbot;
