// contextData.js

export const LIFESTYLE_FACTORS = {
  living_situation: {
    key: 'living_situation',
    question: "What's your living situation like? (apartment, house, living with family, etc.)",
    educational_context: "Your living space can impact what baby products will work best for you. For example, apartment dwellers might need more compact, multi-purpose items.",
    options: ['urban_apartment', 'suburban_house', 'rural', 'living_with_family'],
    impacts: {
      'urban_apartment': [
        {
          category: 'Strollers',
          explanation: 'Living in an apartment often means navigating elevators, stairs, and limited storage. This suggests a lightweight, compact stroller would be most practical.',
        },
        {
          category: 'Storage',
          explanation: 'With limited space, consider furniture that serves multiple purposes or items that can be folded away.',
        }
      ],
      'suburban_house': [
        {
          category: 'Strollers',
          explanation: 'With more storage space and likely car usage, you might prefer a full-featured stroller with ample storage.',
        },
        {
          category: 'Nursery',
          explanation: 'You likely have space for a dedicated nursery with full-sized furniture.',
        }
      ],
      'rural': [
        {
          category: 'Strollers',
          explanation: 'Rural living often means uneven terrain and longer trips. Consider all-terrain or jogging strollers.',
        },
        {
          category: 'Travel Gear',
          explanation: 'You might need more self-contained solutions since stores aren\'t always nearby.',
        }
      ],
      'living_with_family': [
        {
          category: 'Nursery',
          explanation: 'You may need to create a nursery space within a shared room or area.',
        },
        {
          category: 'Storage',
          explanation: 'Consider compact, portable items that don\'t permanently take up shared space.',
        }
      ]
    }
  },

  transportation: {
    key: 'transportation',
    question: "How do you plan to get around most days with your baby?",
    educational_context: "Your primary transportation method affects which types of baby gear will be most useful for your lifestyle.",
    options: ['own_car', 'public_transit', 'mixed', 'mostly_walking'],
    impacts: {
      'public_transit': [
        {
          category: 'Strollers',
          explanation: 'Using public transit means you\'ll want a stroller that\'s lightweight and quick to fold.',
        },
        {
          category: 'Carriers',
          explanation: 'A baby carrier would be very useful for public transit when a stroller might be impractical.',
        }
      ],
      'own_car': [
        {
          category: 'Car Seats',
          explanation: 'With your own car, you might prefer a car seat that stays installed, possibly with multiple bases.',
        },
        {
          category: 'Strollers',
          explanation: 'You can consider larger strollers since you\'ll be transporting them by car.',
        }
      ],
      'mixed': [
        {
          category: 'Car Seats',
          explanation: 'You\'ll need versatile car seats that work well both installed with a base and without.',
        },
        {
          category: 'Strollers',
          explanation: 'Consider a mid-weight stroller that balances features with portability.',
        }
      ],
      'mostly_walking': [
        {
          category: 'Strollers',
          explanation: 'Prioritize good suspension and maneuverability for frequent walking.',
        },
        {
          category: 'Carriers',
          explanation: 'A comfortable carrier would be great for times when a stroller isn\'t practical.',
        }
      ]
    }
  },

  budget_approach: {
    key: 'budget_approach',
    question: "What's your approach to buying baby gear?",
    educational_context: "Your budget priorities will help determine whether to focus on multi-stage items, essentials only, or premium features.",
    options: ['invest_long_term', 'balanced', 'budget_conscious', 'second_hand'],
    impacts: {
      'invest_long_term': [
        {
          category: 'Car Seats',
          explanation: 'Consider convertible seats that will last through multiple stages.',
        },
        {
          category: 'Strollers',
          explanation: 'Look for versatile, high-quality strollers that can handle multiple children.',
        }
      ],
      'budget_conscious': [
        {
          category: 'Travel Gear',
          explanation: 'Focus on essential, multi-purpose items rather than specialized gear.',
        },
        {
          category: 'Strollers',
          explanation: 'Consider lighter-weight options that meet basic needs effectively.',
        }
      ],
      'balanced': [
        {
          category: 'Nursery',
          explanation: 'Invest in quality for key items like the crib and car seat, while being practical about items used for shorter periods.',
        }
      ],
      'second_hand': [
        {
          category: 'Safety Items',
          explanation: 'For safety-critical items like car seats, new is recommended. Consider second-hand for clothing, toys, and furniture.',
        }
      ]
    }
  }
};

function getImpacts(factor, response) {
  const factorData = LIFESTYLE_FACTORS[factor];
  if (!factorData || !factorData.impacts[response]) {
    return [];
  }
  return factorData.impacts[response];
}

function getInitialQuestions() {
  return Object.values(LIFESTYLE_FACTORS)
    .sort((a, b) => (a.key === 'living_situation' ? -1 : 1))
    .map(factor => factor.question);
}

export { getImpacts, getInitialQuestions };