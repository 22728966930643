import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Menu, Drawer, FloatButton } from 'antd';
import { 
  UnorderedListOutlined, 
  CompassOutlined, 
  MessageOutlined, 
  UserOutlined, 
  CloseOutlined
} from '@ant-design/icons';
import '../../../assets/css/components/navigation.css';
import Chat from '../../views/Chat';
import BibiLogo from '../../../assets/images/BibiLogo';

const MainNavigation = () => {
  const location = useLocation();
  const history = useHistory();
  const currentPath = location.pathname;
  const [chatDrawerVisible, setChatDrawerVisible] = useState(false);
  const [chatContext, setChatContext] = useState(null);
  
  // Listen for custom events to show chat drawer with context
  useEffect(() => {
    const handleShowChatDrawer = (event) => {
      setChatContext(event.detail);
      setChatDrawerVisible(true);
    };
    
    window.addEventListener('showChatDrawer', handleShowChatDrawer);
    
    return () => {
      window.removeEventListener('showChatDrawer', handleShowChatDrawer);
    };
  }, []);
  
  // Determine which nav item should be active
  const getSelectedKey = () => {
    if (currentPath.includes('/plan')) return '1';
    if (currentPath.includes('/explore')) return '2';
    if (currentPath.includes('/profile')) return '3';
    return '1'; // Default to plan
  };

  // Show chat drawer
  const showChatDrawer = () => {
    setChatContext(null); // Reset context when opening via button
    setChatDrawerVisible(true);
  };

  // Hide chat drawer
  const onCloseChatDrawer = () => {
    setChatDrawerVisible(false);
    setChatContext(null); // Clear context when closing drawer
  };

  return (
    <>
      {/* Main navigation */}
      <div className="main-navigation">
        <Menu
          mode="horizontal"
          selectedKeys={[getSelectedKey()]}
          className="nav-menu"
        >
          <Menu.Item key="1" style={{ flex: 1 }}>
            <div className="nav-item-content">
              <UnorderedListOutlined className="nav-icon" />
              <Link to="/plan">Plan</Link>
            </div>
          </Menu.Item>
          <Menu.Item key="2" style={{ flex: 1 }}>
            <div className="nav-item-content">
              <CompassOutlined className="nav-icon" />
              <Link to="/explore">Explore</Link>
            </div>
          </Menu.Item>
          <Menu.Item key="3" style={{ flex: 1 }}>
            <div className="nav-item-content">
              <UserOutlined className="nav-icon" />
              <Link to="/profile">Profile</Link>
            </div>
          </Menu.Item>
        </Menu>
      </div>

      {/* Floating chat button */}
      <FloatButton 
        icon={<MessageOutlined />}
        type="primary"
        className="chat-float-button"
        style={{ 
          right: 24, 
          bottom: 84,
          width: 48,
          height: 48,
          backgroundColor: '#6B4EFF',
          boxShadow: '0 4px 12px rgba(107, 78, 255, 0.3)'
        }}
        onClick={showChatDrawer}
      />

      {/* Chat Drawer */}
      <Drawer
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <BibiLogo width={200} height={28} />
            <CloseOutlined onClick={onCloseChatDrawer} style={{ cursor: 'pointer' }} />
          </div>
        }
        placement="right"
        closable={false}
        onClose={onCloseChatDrawer}
        open={chatDrawerVisible}
        width="100%"
        bodyStyle={{ padding: 0, height: 'calc(100% - 55px)' }}
        headerStyle={{ padding: '16px 24px', borderBottom: '1px solid #f0f0f0' }}
        className="chat-drawer"
      >
        <div className="chat-drawer-content">
          <Chat 
            inDrawer={true} 
            onClose={onCloseChatDrawer}
            fromPlanDetails={chatContext?.fromPlanDetails}
            forProductSuggestion={chatContext?.forProductSuggestion}
            productName={chatContext?.productName}
            babyName={chatContext?.babyName}
            activateProductExpert={chatContext?.activateProductExpert}
          />
        </div>
      </Drawer>
    </>
  );
};

export default MainNavigation;