import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LoginRouter from './routing/LoginRouter';
import AppRouter from './routing/AppRouter';
import LoadingSpinner from './components/LoadingSpinner';
import { checkLogin, logout } from './redux/authentication/actions';
import { isEmpty } from './helperFunctions';
import '../assets/css/App.css';

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUserLogin = async () => {
      try {
        await dispatch(checkLogin());
      } catch (error) {
        console.error("Login check failed:", error);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };

    checkUserLogin();
  }, [dispatch]);

  // We've removed the beforeunload event handler that was logging users out on page refresh
  // Now users will stay logged in when refreshing the page since we're using localStorage

  if (loading) {
    return <LoadingSpinner />;
  }

  return user ? <AppRouter /> : <LoginRouter />;
};

export default App;