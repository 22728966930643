import initialState from './initialState';
import * as types from './actionTypes';

export default function listsReducer(state = initialState, action) {
  switch (action.type) {
    // Read user lists
    case types.READ_USER_LISTS_IN_PROCESS:
      return {
        ...state,
        readUserListsInProcess: action.readUserListsInProcess
      };
    case types.READ_USER_LISTS_SUCCESS:
      return {
        ...state,
        userLists: action.userLists,
        readUserListsError: null
      };
    case types.READ_USER_LISTS_ERROR:
      return {
        ...state,
        readUserListsError: action.error
      };

    // Read suggested lists
    case types.READ_SUGGESTED_LISTS_IN_PROCESS:
      return {
        ...state,
        readSuggestedListsInProcess: action.readSuggestedListsInProcess
      };
    case types.READ_SUGGESTED_LISTS_SUCCESS:
      return {
        ...state,
        suggestedLists: action.suggestedLists,
        readSuggestedListsError: null
      };
    case types.READ_SUGGESTED_LISTS_ERROR:
      return {
        ...state,
        readSuggestedListsError: action.error
      };

    // Read suggested list items
    case types.READ_SUGGESTED_LIST_ITEMS_IN_PROCESS:
      return {
        ...state,
        readSuggestedListItemsInProcess: action.readSuggestedListItemsInProcess
      };
    case types.READ_SUGGESTED_LIST_ITEMS_SUCCESS:
      return {
        ...state,
        suggestedListItems: action.suggestedListItems,
        readSuggestedListItemsError: null
      };
    case types.READ_SUGGESTED_LIST_ITEMS_ERROR:
      return {
        ...state,
        readSuggestedListItemsError: action.error
      };

    // Generate list suggestions
    case types.GENERATE_LIST_SUGGESTIONS_IN_PROCESS:
      return {
        ...state,
        generateListSuggestionsInProcess: action.generateListSuggestionsInProcess
      };
    case types.GENERATE_LIST_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        generateListSuggestionsError: null
      };
    case types.GENERATE_LIST_SUGGESTIONS_ERROR:
      return {
        ...state,
        generateListSuggestionsError: action.error
      };

    // Generate list item suggestions
    case types.GENERATE_LIST_ITEM_SUGGESTIONS_IN_PROCESS:
      return {
        ...state,
        generateListItemSuggestionsInProcess: action.generateListItemSuggestionsInProcess
      };
    case types.GENERATE_LIST_ITEM_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        generateListItemSuggestionsError: null
      };
    case types.GENERATE_LIST_ITEM_SUGGESTIONS_ERROR:
      return {
        ...state,
        generateListItemSuggestionsError: action.error
      };
      
    // Create list
    case types.CREATE_LIST_IN_PROCESS:
      return {
        ...state,
        createListInProcess: action.createListInProcess
      };
    case types.CREATE_LIST_SUCCESS:
      // Add the new list to the userLists array if it has a valid uuid
      if (action.list) {
        console.log('CREATE_LIST_SUCCESS action:', action);
        
        // Check for the uuid in different ways since the backend might return it in various formats
        const listUuid = action.list.uuid || (action.list.Success && action.list.uuid);
        
        if (listUuid) {
          // Format the list to match the expected structure
          const newList = {
            ...action.list,
            uuid: listUuid, // Ensure uuid is explicitly set
            stats: {
              total_items: 0,
              completed_items: 0,
              completion_percentage: 0,
              sampling_items: 0,
              training_items: 0,
              building_items: 0
            },
            modules: ['General']
          };
          
          console.log('Adding new list to Redux store:', newList);
          
          return {
            ...state,
            userLists: [...state.userLists, newList],
            createListError: null
          };
        } else {
          console.warn('List created but no UUID found in response:', action.list);
        }
      }
      return {
        ...state,
        createListError: null
      };
    case types.CREATE_LIST_ERROR:
      return {
        ...state,
        createListError: action.error
      };

    // Accept list suggestion
    case types.ACCEPT_LIST_SUGGESTION_IN_PROCESS:
      return {
        ...state,
        acceptListSuggestionInProcess: action.acceptListSuggestionInProcess
      };
    case types.ACCEPT_LIST_SUGGESTION_SUCCESS:
      return {
        ...state,
        // Remove the accepted suggestion from the list
        suggestedLists: state.suggestedLists.filter(
          suggestion => suggestion.uuid !== action.suggestionUuid
        ),
        acceptListSuggestionError: null
      };
    case types.ACCEPT_LIST_SUGGESTION_ERROR:
      return {
        ...state,
        acceptListSuggestionError: action.error
      };

    // Reject list suggestion
    case types.REJECT_LIST_SUGGESTION_IN_PROCESS:
      return {
        ...state,
        rejectListSuggestionInProcess: action.rejectListSuggestionInProcess
      };
    case types.REJECT_LIST_SUGGESTION_SUCCESS:
      return {
        ...state,
        // Remove the rejected suggestion from the list
        suggestedLists: state.suggestedLists.filter(
          suggestion => suggestion.uuid !== action.suggestionUuid
        ),
        rejectListSuggestionError: null
      };
    case types.REJECT_LIST_SUGGESTION_ERROR:
      return {
        ...state,
        rejectListSuggestionError: action.error
      };

    // Add item to list
    case types.ADD_ITEM_TO_LIST_IN_PROCESS:
      return {
        ...state,
        addItemToListInProcess: action.addItemToListInProcess
      };
    case types.ADD_ITEM_TO_LIST_SUCCESS:
      return {
        ...state,
        addItemToListError: null
      };
    case types.ADD_ITEM_TO_LIST_ERROR:
      return {
        ...state,
        addItemToListError: action.error
      };

    // Clear list data
    case types.CLEAR_LIST_DATA:
      return {
        ...state,
        list: null,
        userLists: [],
        suggestedLists: [],
        suggestedListItems: []
      };

    // Clear list errors  
    case types.CLEAR_LIST_ERRORS:
      return {
        ...state,
        readUserListsError: null,
        readSuggestedListsError: null,
        readSuggestedListItemsError: null,
        readListError: null,
        createListError: null,
        updateListError: null,
        deleteListError: null,
        acceptListSuggestionError: null,
        rejectListSuggestionError: null,
        generateListSuggestionsError: null,
        generateListItemSuggestionsError: null,
        addItemToListError: null
      };

    default:
      return state;
  }
}