import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Typography, Tabs, Card, Space, Input, Radio, Button, List, Tag, Modal, Collapse, Spin } from 'antd';
import BibiLogo from '../../assets/images/BibiLogo';
import { useHistory, useLocation } from 'react-router-dom';
import { 
  UserOutlined, 
  TeamOutlined, 
  GlobalOutlined, 
  SearchOutlined, 
  FilterOutlined, 
  PlusOutlined,
  ShareAltOutlined,
  DeleteOutlined,
  RightCircleOutlined,
  ExperimentOutlined,
  ToolOutlined,
  BuildOutlined
} from '@ant-design/icons';
import DatabaseTestView from '../components/DatabaseTestView';
import '../../assets/css/components/explore.css';
import '../../assets/css/plans.css'; // Add plans css for baby-centric design
import PlanDetails from './PlanDetails';
import ProductChatbot from '../components/checklist/productChatbot';
import ProductList from '../components/newProducts/ProductList'; // Import the ProductList component
import { connect } from 'react-redux';
import { readAllCategories } from '../redux/categories/actions';
import { readCategoriesWithProducts } from '../redux/products/actions';
import { readAllTopics } from '../redux/topics/actions';

// Sample category data for demonstration
const sampleCategoryData = [
  {
    uuid: 'cat1',
    name: 'Cribs & Bassinets',
    products: [
      { uuid: 'p1', name: 'Babyletto Hudson 3-in-1 Convertible Crib', brand: 'Babyletto', rating: 4.8, price: '$379.99', review_count: 1250 },
      { uuid: 'p2', name: 'HALO BassiNest Swivel Sleeper', brand: 'HALO', rating: 4.7, price: '$229.99', review_count: 890 },
      { uuid: 'p3', name: 'Graco Pack \'n Play', brand: 'Graco', rating: 4.6, price: '$89.99', review_count: 2300 },
      { uuid: 'p4', name: 'DaVinci Kalani 4-in-1 Convertible Crib', brand: 'DaVinci', rating: 4.5, price: '$199.99', review_count: 1100 }
    ]
  },
  {
    uuid: 'cat2',
    name: 'Car Seats',
    products: [
      { uuid: 'p5', name: 'Chicco KeyFit 30 Infant Car Seat', brand: 'Chicco', rating: 4.9, price: '$199.99', review_count: 3420 },
      { uuid: 'p6', name: 'Britax B-Safe Gen2 Infant Car Seat', brand: 'Britax', rating: 4.7, price: '$229.99', review_count: 1780 },
      { uuid: 'p7', name: 'Graco 4Ever DLX 4-in-1 Car Seat', brand: 'Graco', rating: 4.8, price: '$299.99', review_count: 5200 }
    ]
  },
  {
    uuid: 'cat3',
    name: 'Strollers',
    products: [
      { uuid: 'p8', name: 'UPPAbaby VISTA V2 Stroller', brand: 'UPPAbaby', rating: 4.9, price: '$969.99', review_count: 1250 },
      { uuid: 'p9', name: 'Baby Jogger City Mini GT2 Stroller', brand: 'Baby Jogger', rating: 4.8, price: '$399.99', review_count: 980 },
      { uuid: 'p10', name: 'BOB Gear Revolution Flex 3.0 Jogging Stroller', brand: 'BOB Gear', rating: 4.8, price: '$499.99', review_count: 1120 }
    ]
  },
  {
    uuid: 'cat4',
    name: 'Diapering',
    products: [
      { uuid: 'p11', name: 'Pampers Swaddlers Disposable Diapers', brand: 'Pampers', rating: 4.8, price: '$39.99', review_count: 8950 },
      { uuid: 'p12', name: 'Huggies Little Snugglers Baby Diapers', brand: 'Huggies', rating: 4.7, price: '$37.99', review_count: 7850 },
      { uuid: 'p13', name: 'Ubbi Steel Diaper Pail', brand: 'Ubbi', rating: 4.6, price: '$79.99', review_count: 3120 }
    ]
  },
  {
    uuid: 'cat5',
    name: 'Feeding',
    products: [
      { uuid: 'p14', name: 'Philips Avent Natural Baby Bottle', brand: 'Philips', rating: 4.7, price: '$29.99', review_count: 4780 },
      { uuid: 'p15', name: 'Dr. Brown\'s Options+ Anti-Colic Bottles', brand: 'Dr. Brown\'s', rating: 4.8, price: '$24.99', review_count: 6120 },
      { uuid: 'p16', name: 'Comotomo Baby Bottle', brand: 'Comotomo', rating: 4.6, price: '$23.99', review_count: 3240 }
    ]
  },
  {
    uuid: 'cat6',
    name: 'Nursery Furniture',
    products: [
      { uuid: 'p17', name: 'Delta Children 3-Drawer Dresser', brand: 'Delta Children', rating: 4.5, price: '$189.99', review_count: 980 },
      { uuid: 'p18', name: 'Babyletto Hudson Changing Table Dresser', brand: 'Babyletto', rating: 4.7, price: '$299.99', review_count: 760 },
      { uuid: 'p19', name: 'Carter\'s by DaVinci Morgan 3-Drawer Dresser', brand: 'Carter\'s', rating: 4.4, price: '$249.99', review_count: 570 }
    ]
  },
  {
    uuid: 'cat7',
    name: 'Activity Centers',
    products: [
      { uuid: 'p20', name: 'Skip Hop Explore & More Baby Activity Center', brand: 'Skip Hop', rating: 4.7, price: '$124.99', review_count: 1350 },
      { uuid: 'p21', name: 'Baby Einstein Neptune\'s Ocean Discovery Jumper', brand: 'Baby Einstein', rating: 4.6, price: '$84.99', review_count: 1120 },
      { uuid: 'p22', name: 'Fisher-Price Rainforest Jumperoo', brand: 'Fisher-Price', rating: 4.8, price: '$104.99', review_count: 2450 }
    ]
  },
  {
    uuid: 'cat8',
    name: 'Baby Monitors',
    products: [
      { uuid: 'p23', name: 'Infant Optics DXR-8 Video Baby Monitor', brand: 'Infant Optics', rating: 4.7, price: '$165.99', review_count: 4250 },
      { uuid: 'p24', name: 'Nanit Pro Smart Baby Monitor & Wall Mount', brand: 'Nanit', rating: 4.8, price: '$299.99', review_count: 1870 },
      { uuid: 'p25', name: 'Owlet Smart Sock Baby Monitor', brand: 'Owlet', rating: 4.5, price: '$299.99', review_count: 2340 }
    ]
  },
  {
    uuid: 'cat9',
    name: 'Baby Clothing',
    products: [
      { uuid: 'p26', name: 'Carter\'s Baby 4-Pack Bodysuits', brand: 'Carter\'s', rating: 4.8, price: '$19.99', review_count: 5240 },
      { uuid: 'p27', name: 'Simple Joys by Carter\'s Baby 6-Pack Sleep and Play', brand: 'Simple Joys', rating: 4.7, price: '$29.99', review_count: 4120 },
      { uuid: 'p28', name: 'Burt\'s Bees Baby Organic Pajamas', brand: 'Burt\'s Bees Baby', rating: 4.6, price: '$15.99', review_count: 2870 }
    ]
  },
  {
    uuid: 'cat10',
    name: 'Pacifiers',
    products: [
      { uuid: 'p29', name: 'Philips Avent Soothie Pacifier', brand: 'Philips', rating: 4.7, price: '$4.99', review_count: 7650 },
      { uuid: 'p30', name: 'MAM Comfort Pacifier', brand: 'MAM', rating: 4.6, price: '$5.99', review_count: 3490 },
      { uuid: 'p31', name: 'Dr. Brown\'s HappyPaci Silicone Pacifier', brand: 'Dr. Brown\'s', rating: 4.5, price: '$6.99', review_count: 2350 }
    ]
  },
  {
    uuid: 'cat11',
    name: 'Baby Carriers',
    products: [
      { uuid: 'p32', name: 'Ergobaby 360 All-Position Baby Carrier', brand: 'Ergobaby', rating: 4.7, price: '$179.99', review_count: 3240 },
      { uuid: 'p33', name: 'Baby Björn Baby Carrier Mini', brand: 'Baby Björn', rating: 4.6, price: '$99.99', review_count: 2180 },
      { uuid: 'p34', name: 'Infantino Flip 4-in-1 Convertible Carrier', brand: 'Infantino', rating: 4.5, price: '$34.99', review_count: 4560 }
    ]
  },
  {
    uuid: 'cat12',
    name: 'Baby Safety',
    products: [
      { uuid: 'p35', name: 'Safety 1st Childproofing Kit', brand: 'Safety 1st', rating: 4.6, price: '$24.99', review_count: 1230 },
      { uuid: 'p36', name: 'Dreambaby Chelsea Auto Close Security Gate', brand: 'Dreambaby', rating: 4.7, price: '$79.99', review_count: 980 },
      { uuid: 'p37', name: 'Summer Infant Multi-Use Deco Extra Tall Walk-Thru Gate', brand: 'Summer Infant', rating: 4.5, price: '$69.99', review_count: 1450 }
    ]
  }
];

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;
const { Search } = Input;
const { Panel } = Collapse;

// Sample data for the Explore page - Using IDs that match mockPlans in PlanDetails
// Using plans with product focus, similar to what was in the All Plans section
const sampleLists = [
  {
    id: 'plan-5',
    title: 'Baby Gear Essentials',
    creator: 'Bibi Team',
    creatorType: 'official',
    description: 'Must-have products for your newborn',
    stats: {
      total_items: 12,
      completed_items: 5,
      completion_percentage: 42,
      sampling_items: 4,
      training_items: 3,
      building_items: 5
    },
    modules: ['Shopping', 'Research', 'Reviews'],
    category: 'Products'
  },
  {
    id: 'plan-6',
    title: 'Nursery Setup',
    creator: 'Sarah Johnson',
    creatorType: 'friend',
    description: 'Create a safe and cozy nursery',
    stats: {
      total_items: 9,
      completed_items: 4,
      completion_percentage: 44,
      sampling_items: 3,
      training_items: 2,
      building_items: 4
    },
    modules: ['Furniture', 'Decor', 'Organization'],
    category: 'Products'
  },
  {
    id: 'plan-11',
    title: 'Car Seat Installation',
    creator: 'Melissa Chen',
    creatorType: 'following',
    description: 'Prepare for safe travel with baby',
    stats: {
      total_items: 5,
      completed_items: 1,
      completion_percentage: 20,
      sampling_items: 2,
      training_items: 1,
      building_items: 2
    },
    modules: ['Safety', 'Installation', 'Inspection'],
    category: 'Products'
  },
  {
    id: 'plan-14',
    title: 'Baby Registry Creation',
    creator: 'Community Guide',
    creatorType: 'public',
    description: 'Build a thoughtful registry for baby',
    stats: {
      total_items: 10,
      completed_items: 7,
      completion_percentage: 70,
      sampling_items: 3,
      training_items: 2,
      building_items: 5
    },
    modules: ['Essentials', 'Nice-to-haves', 'Organization'],
    category: 'Products'
  },
  {
    id: 'plan-17',
    title: 'Diapering Strategy',
    creator: 'Bibi Team',
    creatorType: 'official',
    description: 'Choose between cloth and disposable',
    stats: {
      total_items: 6,
      completed_items: 4,
      completion_percentage: 67,
      sampling_items: 2,
      training_items: 1,
      building_items: 3
    },
    modules: ['Research', 'Supplies', 'Setup'],
    category: 'Products'
  },
  {
    id: 'plan-21',
    title: 'Baby Proofing Home',
    creator: 'Jennifer Miller',
    creatorType: 'friend',
    description: 'Make your home safe for baby',
    stats: {
      total_items: 9,
      completed_items: 2,
      completion_percentage: 22,
      sampling_items: 3,
      training_items: 2,
      building_items: 4
    },
    modules: ['Safety', 'Installation', 'Products'],
    category: 'Products'
  }
];

// We'll map product clicks to the first list for demo purposes
const sampleProducts = [
  {
    id: '123e4567-e89b-12d3-a456-426614174000', // Using the list ID for demo
    name: 'Dr. Brown\'s Natural Flow Bottles',
    brand: 'Dr. Brown\'s',
    category: 'Feeding',
    rating: 4.7,
    price: '$24.99',
    reviewCount: 1250,
    image: require('../../assets/images/Categories_Bottles.png'),
  },
  {
    id: '123e4567-e89b-12d3-a456-426614174001', // Using the list ID for demo
    name: 'Halo Sleepsack Swaddle',
    brand: 'Halo',
    category: 'Sleeping',
    rating: 4.9,
    price: '$29.99',
    reviewCount: 3420,
    image: require('../../assets/images/Categories_Footed_Sleepers.png'),
  },
  {
    id: '123e4567-e89b-12d3-a456-426614174000', // Using the list ID for demo
    name: 'Pampers Swaddlers Diapers',
    brand: 'Pampers',
    category: 'Diapering',
    rating: 4.8,
    price: '$39.99',
    reviewCount: 8752,
    image: require('../../assets/images/Categories_Disposable_Diapers.png'),
  },
];

const Explore = ({ 
  // Redux state
  categories,
  allCategories,
  categoriesWithProducts,
  products,
  allCategoriesLoading,
  categoriesWithProductsLoading,
  allTopics,
  // Redux actions
  readAllCategories,
  readCategoriesWithProducts,
  readAllTopics,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState('');
  const [filterVisible, setFilterVisible] = useState(true);
  const [filterHeight, setFilterHeight] = useState(320); // Track filter height - increased by another 100px
  const [filterCompact, setFilterCompact] = useState(false);
  const maxFilterHeight = 320; // Maximum filter height when expanded - increased by another 100px
  const compactFilterHeight = 90; // Minimum filter height when collapsed - increased by 30px
  const scrollThreshold = 150; // Scroll amount to fully collapse the filter
  const [viewType, setViewType] = useState('lists'); // Default to 'lists' not 'products'
  const [feedFilter, setFeedFilter] = useState('popular');
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [sourceFilter, setSourceFilter] = useState([]);
  const [selectedTopicId, setSelectedTopicId] = useState(null);
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [showChatbot, setShowChatbot] = useState(false);
  const [testModalVisible, setTestModalVisible] = useState(false);

  // Check if we're coming from Plans for suggestions
  const fromPlans = location.state?.fromPlans || false;
  const forSuggestions = location.state?.forSuggestions || false;
  const babyName = location.state?.babyName || "Emma";
  
  // Show chatbot if coming from Plans for suggestions
  useEffect(() => {
    if (fromPlans && forSuggestions) {
      setShowChatbot(true);
    }
  }, [fromPlans, forSuggestions]);
  
  // State for load more functionality
  const [visibleCategories, setVisibleCategories] = useState(5); // Start with 5 categories
  const categoriesPerPage = 2; // Number of categories to load on scroll (reduced from 5 to 2)
  const [isLoading, setIsLoading] = useState(false); // Track loading state
  const productsContainerRef = useRef(null); // Reference to products container for scroll detection
  
  // Fetch categories, topics and products from Redux when component mounts
  useEffect(() => {
    readAllCategories();
    readCategoriesWithProducts();
    readAllTopics();
    
    // Debug logging to check what's in categoriesWithProducts
    console.log('Initial categoriesWithProducts:', categoriesWithProducts);
  }, [readAllCategories, readCategoriesWithProducts, readAllTopics]);
  
  // Add a debug effect to log when categoriesWithProducts changes
  useEffect(() => {
    console.log('categoriesWithProducts updated:', categoriesWithProducts);
    console.log('Length:', categoriesWithProducts?.length || 0);
    
    if (categoriesWithProducts && categoriesWithProducts.length > 0) {
      // Log each category
      categoriesWithProducts.forEach((cat, index) => {
        console.log(`Category ${index+1}:`, cat.name, cat.uuid, 'Products:', cat.products?.length || 0);
      });
    }
  }, [categoriesWithProducts]);
  
  // Function to handle loading more categories
  const loadMoreCategories = useCallback(() => {
    // Get the current categories (either from Redux or sample data)
    const categories = sampleCategoryData;
    const totalCategories = categories.length;
    
    // Only load more if there are more categories to show and not currently loading
    if (visibleCategories < totalCategories && !isLoading) {
      setIsLoading(true);
      console.log(`Loading more categories: ${visibleCategories} -> ${Math.min(visibleCategories + categoriesPerPage, totalCategories)}`);
      
      // Simulate a brief loading delay
      setTimeout(() => {
        setVisibleCategories(prev => Math.min(prev + categoriesPerPage, totalCategories));
        setIsLoading(false);
      }, 300);
    }
  }, [visibleCategories, isLoading, categoriesPerPage]);
  
  // Function to check if user has scrolled to the bottom
  const handleScroll = useCallback(() => {
    if (!productsContainerRef.current) return;
    
    // Get scroll position
    const scrollContainer = window;
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.body.scrollHeight;
    
    // Check if scrolled near the bottom (within 300px)
    const nearBottom = scrollTop + windowHeight >= documentHeight - 300;
    
    if (nearBottom) {
      // Load more categories when near the bottom
      loadMoreCategories();
    }
  }, [loadMoreCategories]);
  
  // Add scroll event listener for filter collapse behavior
  useEffect(() => {
    let ticking = false;
    
    const handleScrollWithFilters = () => {
      // Call the original handleScroll for pagination
      handleScroll();
      
      // Handle progressive filter compression based on scroll position
      const scrollPosition = window.scrollY;
      
      if (!ticking) {
        // Use requestAnimationFrame to avoid performance issues
        requestAnimationFrame(() => {
          if (scrollPosition <= 0) {
            // Fully expanded at the top
            setFilterHeight(maxFilterHeight);
            setFilterCompact(false);
            // Update CSS variables
            document.documentElement.style.setProperty('--content-top-position', `${70 + maxFilterHeight + 10}px`);
            document.documentElement.style.setProperty('--fade-opacity', '1');
          } else if (scrollPosition >= scrollThreshold) {
            // Fully compact after threshold
            setFilterHeight(compactFilterHeight);
            setFilterCompact(true);
            // Update CSS variables
            document.documentElement.style.setProperty('--content-top-position', `${70 + compactFilterHeight + 10}px`);
            document.documentElement.style.setProperty('--fade-opacity', '0');
          } else {
            // Progressive transition based on scroll position percentage
            const completionRatio = scrollPosition / scrollThreshold;
            const newHeight = maxFilterHeight - ((maxFilterHeight - compactFilterHeight) * completionRatio);
            setFilterHeight(newHeight);
            
            // Set compact mode immediately as soon as scrolling starts
            setFilterCompact(scrollPosition > 5);
            
            // Update CSS variable for content container
            const contentPosition = 70 + newHeight + 10; // 70px is the filter top position, +10px is the requested gap
            document.documentElement.style.setProperty('--content-top-position', `${contentPosition}px`);
            
            // Add opacity transition for fade sections based on scroll ratio
            const fadeOpacity = Math.max(0, 1 - (completionRatio * 2)); // Fade out twice as fast as the collapse
            document.documentElement.style.setProperty('--fade-opacity', fadeOpacity);
          }
          ticking = false;
        });
      }
      ticking = true;
    };
    
    // Add scroll event listener when component mounts
    window.addEventListener('scroll', handleScrollWithFilters, { passive: true });
    
    // Initialize filter to expanded state on mount
    setFilterHeight(maxFilterHeight);
    
    // Initialize CSS variables for positioning and opacity
    document.documentElement.style.setProperty('--content-top-position', `${70 + maxFilterHeight + 10}px`);
    document.documentElement.style.setProperty('--fade-opacity', '1');
    
    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScrollWithFilters);
    };
  }, [handleScroll, maxFilterHeight, compactFilterHeight, scrollThreshold]);
  
  // Helper function to get a valid category image or fallback to a default
  const getCategoryImage = (categoryName) => {
    // Available category images based on our glob search
    const availableImages = [
      'Activity_Center', 'Baby_Monitor', 'Baby_Swing', 'Bassinet', 'Bottles',
      'Bouncy_Seat', 'CarSeat', 'Changing_Table', 'Crib', 'Diaper_Rash_Cream',
      'Disposable_Diapers', 'Dresser', 'Footed_Sleepers', 'Glider', 'Infant_Booties',
      'Infant_Laundry_Detergent', 'Newborn_Cap', 'Pacifier', 'Snowsuit', 'Strollers',
      'Sun_Hat'
    ];
    
    try {
      // Normalize category name to match image file naming convention
      const normalizedName = categoryName?.replace(/\s+/g, '_');
      
      // If the normalized name exists in our availableImages array, use it
      if (normalizedName && availableImages.includes(normalizedName)) {
        return require(`../../assets/images/Categories_${normalizedName}.png`);
      }
      
      // For car seats, match to CarSeat
      if (/car\s*seat/i.test(categoryName)) {
        return require('../../assets/images/Categories_CarSeat.png');
      }
      
      // For activity centers, match to Activity_Center
      if (/activity/i.test(categoryName)) {
        return require('../../assets/images/Categories_Activity_Center.png');
      }
      
      // Default fallbacks based on broad category types
      if (/seat|chair/i.test(categoryName)) {
        return require('../../assets/images/Categories_Bouncy_Seat.png');
      }
      
      if (/diaper|wipe/i.test(categoryName)) {
        return require('../../assets/images/Categories_Disposable_Diapers.png');
      }
      
      if (/clothing|clothes|wear|outfit/i.test(categoryName)) {
        return require('../../assets/images/Categories_Footed_Sleepers.png');
      }
      
      if (/feed|bottle|nursing/i.test(categoryName)) {
        return require('../../assets/images/Categories_Bottles.png');
      }
      
      if (/stroller|carrier|travel/i.test(categoryName)) {
        return require('../../assets/images/Categories_Strollers.png');
      }
      
      if (/crib|bed|sleep/i.test(categoryName)) {
        return require('../../assets/images/Categories_Crib.png');
      }
      
      // Final fallback to a common image
      return require('../../assets/images/Categories_Crib.png');
    } catch (error) {
      // If there's any error, return a safe default
      return require('../../assets/images/Categories_Crib.png');
    }
  };

  // Handle product selection from chatbot
  const handleProductSelect = (categoryId, productId, productName) => {
    // Here we would handle the actual product selection logic
    // For now, just show a success message
    Modal.success({
      title: 'List Generated',
      content: `A new list has been created based on your preferences.`,
      onOk: () => {
        // Navigate back to Plans
        history.push('/plan');
      }
    });
  };
  
  // Handle "No Thanks" from chatbot
  const handleNoThanks = () => {
    setShowChatbot(false);
    
    // If coming from Plans, go back
    if (fromPlans) {
      history.push('/plan');
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    // Here you would typically filter your data based on the search value
  };

  const toggleFilter = () => {
    setFilterVisible(!filterVisible);
  };

  const handleFeedFilterChange = (e) => {
    setFeedFilter(e.target.value);
  };
  
  // Handle topic selection and scroll to products
  const handleTopicSelect = (topicId) => {
    setSelectedTopicId(topicId);
    
    // If products view isn't active, switch to it
    if (viewType !== 'products') {
      setViewType('products');
    }
    
    // Small delay to ensure view has changed to products
    setTimeout(() => {
      // Find the topic element in the product list and scroll to it
      const topicElement = document.querySelector(`[data-topic-id="${topicId}"]`);
      if (topicElement) {
        topicElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const handleAddToPlan = (item) => {
    // Here you would typically handle adding the item to a plan
    console.log(`Adding ${item.title || item.name} to plan`);
    // In a real app, you would add the item to the plan via API/Redux
    // For now, just show a success message
    Modal.success({
      title: 'Added to Plan',
      content: `${item.title || item.name} has been added to your plan.`,
    });
  };

  const handleShowDetails = (item) => {
    setSelectedItemId(item.id);
    setDetailsModalVisible(true);
  };

  const handleCloseDetails = () => {
    setDetailsModalVisible(false);
    setSelectedItemId(null);
  };

  // Render plan card in the same style as in Plans.js
  const renderPlanCard = (item) => (
    <Card 
      key={item.id}
      hoverable
      onClick={() => handleShowDetails(item)}
      className="plan-card"
      bodyStyle={{ padding: 0 }}
    >      
      <div className="plan-content">
        <div className="plan-info">
          <Title level={4} style={{ margin: 0, marginBottom: '4px', fontSize: '16px' }}>{item.title}</Title>
          
          {item.description && (
            <Paragraph ellipsis={{ rows: 1 }} style={{ marginBottom: '6px', fontSize: '13px' }}>
              {item.description}
            </Paragraph>
          )}
          
          <div className="plan-meta">
            {/* Creator type tags */}
            <div style={{ marginBottom: '8px' }}>
              {item.creatorType === 'official' ? (
                <span className="creator-tag creator-official" style={{ color: '#389e0d', fontSize: '12px' }}>
                  <TeamOutlined style={{ marginRight: '4px' }} />
                  Bibi Team
                </span>
              ) : item.creatorType === 'friend' ? (
                <span className="creator-tag creator-friend" style={{ color: '#d48806', fontSize: '12px' }}>
                  <UserOutlined style={{ marginRight: '4px' }} />
                  Shared by {item.creator}
                </span>
              ) : item.creatorType === 'following' ? (
                <span className="creator-tag creator-following" style={{ color: '#5c9dff', fontSize: '12px' }}>
                  <UserOutlined style={{ marginRight: '4px' }} />
                  Published by {item.creator}, who you follow
                </span>
              ) : (
                <span className="creator-tag creator-public" style={{ color: '#722ed1', fontSize: '12px' }}>
                  <GlobalOutlined style={{ marginRight: '4px' }} />
                  Public
                </span>
              )}
            </div>
            
            <div className="plan-modules">
              {item.modules && item.modules.map((module, idx) => (
                <Tag key={idx} style={{ marginRight: 0, fontSize: '11px', padding: '0 6px' }}>
                  {module}
                </Tag>
              ))}
            </div>
            
            <div className="plan-stats-group">
              <div className="plan-item-count" style={{ fontSize: '12px', color: '#5a73b2', marginTop: '4px' }}>
                {item.stats.total_items} items
              </div>
            </div>
          </div>
        </div>
        
        <div className="plan-actions">
          <ShareAltOutlined 
            className="plan-share-button" 
            onClick={(e) => {
              e.stopPropagation();
              Modal.success({
                title: 'Share List',
                content: `Share "${item.title}" with friends`,
              });
            }} 
            style={{ fontSize: '16px', marginRight: '12px', color: '#5c9dff' }} 
          />
          <Button 
            type="primary" 
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleAddToPlan(item);
            }}
            style={{ 
              whiteSpace: 'nowrap', 
              padding: '0 12px', 
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              fontSize: '13px',
              borderRadius: '15px',
              background: '#5c9dff',
              borderColor: '#4b87e8',
              boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
              marginRight: '10px'
            }}
          >
            Add to Plan
          </Button>
          <RightCircleOutlined style={{ fontSize: '18px', color: '#5c9dff' }} />
        </div>
      </div>
    </Card>
  );

  const renderProductItem = (item) => (
    <Card 
      hoverable 
      className="explore-card"
      onClick={() => handleShowDetails(item)}
    >
      <div className="explore-card-content">
        <div className="explore-card-image">
          <img src={item.image} alt={item.name} />
        </div>
        <div className="explore-card-details">
          <Title level={5}>{item.name}</Title>
          <div className="explore-card-meta">
            <Text type="secondary">{item.brand}</Text>
            <Text type="secondary">{typeof item.price === 'string' ? item.price : `$${item.price || '29.99'}`}</Text>
          </div>
          <div className="explore-card-categories">
            <Tag>{item.category}</Tag>
            <Tag color="gold">{item.rating} ★ ({item.reviewCount})</Tag>
            {item.favorited && <Tag color="blue">Favorited</Tag>}
            {item.researched && <Tag color="green">Researched</Tag>}
            {item.purchased && <Tag color="purple">Purchased</Tag>}
          </div>
        </div>
      </div>
      <div className="explore-card-actions">
        <Button 
          type="primary" 
          size="small" 
          onClick={(e) => {
            e.stopPropagation();
            handleAddToPlan(item);
          }}
          style={{ 
            background: '#5c9dff',
            borderColor: '#4b87e8',
            borderRadius: '15px',
            fontSize: '13px'
          }}
        >
          Add to Emma's Plan
        </Button>
      </div>
    </Card>
  );
  
  // Function to render products grouped by category
  const renderProductsByCategory = () => {
    // We'll use sampleCategoryData since it has more categories to demonstrate
    const categories = sampleCategoryData;
    
    // If we wanted to use Redux data, we would use the commented line instead:
    // const categories = categoriesWithProducts && categoriesWithProducts.length > 0 ? categoriesWithProducts : sampleCategoryData;
    
    if (categories.length === 0) {
      return (
        <div style={{ 
          padding: '30px', 
          textAlign: 'center', 
          background: '#f9f9f9', 
          borderRadius: '4px',
          marginBottom: '16px' 
        }}>
          <Text type="secondary" style={{ fontSize: '16px' }}>No products found.</Text>
        </div>
      );
    }

    // Calculate total number of categories
    const totalCategories = categories.length;
    const hasMoreCategories = visibleCategories < totalCategories;

    // Function to load more categories
    const loadMoreCategories = () => {
      setVisibleCategories(prev => Math.min(prev + categoriesPerPage, totalCategories));
    };

    return (
      <div ref={productsContainerRef}>
        {/* Show only the visible number of categories */}
        {categories.slice(0, visibleCategories).map(category => (
          <div key={category.uuid} className="category-section" style={{ marginBottom: '24px' }}>
            <Title level={5} style={{ 
              borderBottom: '1px solid #f0f0f0', 
              paddingBottom: '8px',
              marginBottom: '16px'
            }}>
              {category.name} ({category.products?.length || 0} products)
            </Title>
            
            {category.products && category.products.length > 0 ? (
              <List
                grid={{
                  gutter: [15, 16], // 15px horizontal space, 16px vertical space
                  xs: 1,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                  xxl: 2,
                }}
                // No pagination - show all products
                pagination={false}
                dataSource={category.products || []}
                renderItem={(product) => renderProductItem({
                  id: product.uuid,
                  name: product.name,
                  brand: product.brand || 'Unknown Brand',
                  category: category.name,
                  rating: product.rating || 4.5,
                  price: product.price || '$29.99',
                  reviewCount: product.review_count || 120,
                  image: getCategoryImage(category.name),
                  favorited: product.favorited,
                  researched: product.researched,
                  purchased: product.purchased
                })}
              />
            ) : (
              <Text type="secondary">No products in this category</Text>
            )}
          </div>
        ))}
        
        {/* Load More indicator or automatic loading message */}
        <div style={{ textAlign: 'center', margin: '20px 0 40px', padding: '20px', border: hasMoreCategories || isLoading ? '1px dashed #5c9dff' : 'none', borderRadius: '8px', backgroundColor: hasMoreCategories || isLoading ? '#f7faff' : 'transparent' }}>
          {isLoading ? (
            <div>
              <Spin size="large" />
              <div style={{ marginTop: '16px' }}>
                <Text>Loading more categories...</Text>
              </div>
            </div>
          ) : hasMoreCategories ? (
            <>
              <Button 
                onClick={loadMoreCategories} 
                type="primary"
                size="large"
                style={{ 
                  background: '#5c9dff',
                  borderColor: '#4b87e8',
                  borderRadius: '20px',
                  padding: '0 40px',
                  height: '45px',
                  fontSize: '16px',
                  fontWeight: 'bold'
                }}
                icon={<PlusOutlined />}
              >
                Load More Categories ({visibleCategories} of {totalCategories})
              </Button>
              <div style={{ marginTop: '8px' }}>
                <Text type="secondary">
                  Showing {visibleCategories} of {totalCategories} categories
                </Text>
              </div>
              <div style={{ marginTop: '16px', fontSize: '14px', color: '#666' }}>
                <i>Pro tip: Scroll to the bottom of the page to automatically load more categories</i>
              </div>
            </>
          ) : (
            totalCategories > 0 && (
              <Text type="secondary" style={{ fontSize: '16px' }}>
                All {totalCategories} categories are loaded ✓
              </Text>
            )
          )}
        </div>
      </div>
    );
  };

  // Mock data for baby info - in a real app this would come from user profile
  const babyInfo = {
    name: "Emma",
    dueDate: "June 15, 2025",
    weekCount: 24,
    photoUrl: "../../assets/images/Ultrasound2.png"
  };

  // Recommendations
  const recommendations = [
    "Childcare options",
    "Nursery setup",
    "Baby-proofing home",
    "Hospital bag essentials"
  ];

  return (
    <div className="explore-container">
      {/* White background that covers the entire page */}
      <div className="full-page-white-background" style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'white',
        zIndex: 5
      }}></div>
      <div className="logo-container" style={{ 
        display: 'flex', 
        justifyContent: 'flex-start', 
        alignItems: 'center', 
        padding: '16px 24px',
        margin: 0,
        marginBottom: '15px',
        backgroundColor: 'white',
        borderBottom: 'none',
        boxShadow: '0 1px 4px rgba(0, 0, 0, 0.05), 0 0 0 0 rgba(0,0,0,0)',
        position: 'sticky',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 50,
        width: '100vw',
        marginLeft: 'calc(-50vw + 50%)',
        paddingLeft: 'calc(50vw - 50% + 24px)',
        paddingRight: 'calc(50vw - 50% + 24px)'
      }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BibiLogo width={200} height={28} />
          <Button 
            type="primary" 
            size="small"
            onClick={() => setTestModalVisible(true)}
            style={{ 
              marginLeft: '10px', 
              background: '#5c9dff',
              borderColor: '#4b87e8',
              borderRadius: '4px',
              fontSize: '12px'
            }}
          >
            Test
          </Button>
        </div>
      </div>
      {/* Baby profile header */}
      <div className="baby-profile-header">
        <div className="baby-profile-info" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="baby-photo-container">
              <img src={require("../../assets/images/HospitalBag.png")} alt="Hospital Bag" className="baby-photo" />
            </div>
            <div className="baby-details">
              <Title level={2} style={{ fontSize: '24px', margin: 0 }}>Emma's Ideas</Title>
              <div className="baby-stats">
                <span className="baby-stat-item">
                  <span className="baby-stat-label">Due: </span>
                  <span className="baby-stat-value">{babyInfo.dueDate}</span>
                </span>
                <span className="baby-stat-item">
                  <span className="baby-stat-label">Week: </span>
                  <span className="baby-stat-value">{babyInfo.weekCount}</span>
                </span>
              </div>
            </div>
          </div>
          <div className="baby-journey-phase" style={{ position: 'absolute', right: '5px', top: '50%', transform: 'translateY(-50%)' }}>
            <div className="journey-phase-badge">2nd Trimester</div>
          </div>
        </div>
      </div>
      
      {/* Recommendations */}
      <div className="progress-overview">
        <Card className="progress-card" size="small" bordered={false}>
          <div style={{ marginBottom: '12px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="section-title-with-icon">
              <i className="section-icon">📋</i>
              <Title level={5} style={{ fontSize: '16px', margin: 0 }}>Recommendations</Title>
            </div>
            <Button 
              type="primary"
              onClick={() => setShowChatbot(true)}
              style={{ 
                background: '#5c9dff',
                borderColor: '#4b87e8',
                borderRadius: '15px',
                fontSize: '13px'
              }}
            >
              Get Personalized Suggestions
            </Button>
          </div>
          
          <div className="current-needs-tags">
            {recommendations.map((item, index) => (
              <Tag 
                key={index} 
                color="#5c9dff"
                style={{ 
                  marginBottom: '8px', 
                  borderRadius: '12px', 
                  padding: '4px 12px',
                  fontSize: '12px',
                  color: '#fff',
                  border: 'none'
                }}
              >
                {item}
              </Tag>
            ))}
          </div>
        </Card>
      </div>

      <div className="explore-search-section">
        <div className="plan-title-section">
          <div className="section-title-with-icon">
            <i className="section-icon">🔍</i>
            <Title level={4} style={{ fontSize: '16px', margin: 0 }}>Find Products & Lists</Title>
          </div>
          <div className="search-filter-container">
            <Search
              placeholder="Search for lists, products, or categories"
              onSearch={handleSearch}
              enterButton={<SearchOutlined />}
              className="explore-search"
            />
            <Button 
              icon={<FilterOutlined />} 
              onClick={toggleFilter}
              className={filterVisible ? 'filter-active' : ''}
              style={{ marginLeft: '8px' }}
            />
          </div>
        </div>
      </div>
      
      {/* List Details Modal */}
      <Modal
        title={null}
        open={detailsModalVisible}
        onCancel={handleCloseDetails}
        footer={null}
        width="100%"
        style={{ top: 0, maxWidth: '100vw', margin: 0, padding: 0 }}
        bodyStyle={{ height: 'calc(100vh - 60px)', padding: 0, overflow: 'auto' }}
        maskClosable={true}
        destroyOnClose={true}
        closable={true}
      >
        {selectedItemId && <PlanDetails 
          listId={selectedItemId} 
          isInExploreModal={true} 
          onBackToExplore={handleCloseDetails} 
        />}
      </Modal>

      {filterVisible && (
        <div className={`explore-filters ${filterCompact ? 'compact' : ''}`} style={{ height: `${filterHeight}px` }}>
          <div className="filter-section remain-visible">
            <Title level={5} style={{ fontSize: '14px' }}>View</Title>
            <div className="filter-view-options">
              <Tag 
                className={viewType === 'lists' ? 'checked' : ''}
                onClick={() => setViewType('lists')}
              >
                Lists
              </Tag>
              <Tag 
                className={viewType === 'products' ? 'checked' : ''}
                onClick={() => setViewType('products')}
              >
                Products
              </Tag>
            </div>
          </div>
          
          <div className="filter-section remain-visible">
            <Title level={5} style={{ fontSize: '14px' }}>Search</Title>
            <Search
              placeholder="Search for lists, products, or categories"
              onSearch={handleSearch}
              enterButton={<SearchOutlined />}
              className="explore-search"
              size="small"
            />
          </div>
          
          <div className="filter-section fade-section">
            <Title level={5} style={{ fontSize: '14px' }}>Topics</Title>
            <div className="filter-tags">
              {allTopics && allTopics.length > 0 ? (
                allTopics.map(topic => (
                  <Tag.CheckableTag
                    key={topic.uuid}
                    checked={selectedTopicId === topic.uuid}
                    onChange={checked => {
                      if (checked) {
                        handleTopicSelect(topic.uuid);
                      } else {
                        setSelectedTopicId(null);
                      }
                    }}
                  >
                    {topic.name}
                  </Tag.CheckableTag>
                ))
              ) : (
                // Fallback to hardcoded topics if Redux data isn't available
                ['Feeding', 'Sleeping', 'Diapering', 'Nursery', 'Gear', 'Health', 'Toys'].map(cat => (
                  <Tag.CheckableTag
                    key={cat}
                    checked={categoryFilter.includes(cat)}
                    onChange={checked => {
                      const newFilters = checked 
                        ? [...categoryFilter, cat] 
                        : categoryFilter.filter(t => t !== cat);
                      setCategoryFilter(newFilters);
                    }}
                  >
                    {cat}
                  </Tag.CheckableTag>
                ))
              )}
            </div>
          </div>
          
          <div className="filter-section fade-section">
            <Title level={5} style={{ fontSize: '14px' }}>Shared By</Title>
            <div className="filter-tags">
              <Tag.CheckableTag
                checked={sourceFilter.includes('bibi')}
                onChange={checked => {
                  const newFilters = checked 
                    ? [...sourceFilter, 'bibi'] 
                    : sourceFilter.filter(t => t !== 'bibi');
                  setSourceFilter(newFilters);
                }}
              >
                <TeamOutlined /> Bibi Team
              </Tag.CheckableTag>
              <Tag.CheckableTag
                checked={sourceFilter.includes('friends')}
                onChange={checked => {
                  const newFilters = checked 
                    ? [...sourceFilter, 'friends'] 
                    : sourceFilter.filter(t => t !== 'friends');
                  setSourceFilter(newFilters);
                }}
              >
                <UserOutlined /> Friends
              </Tag.CheckableTag>
              <Tag.CheckableTag
                checked={sourceFilter.includes('public')}
                onChange={checked => {
                  const newFilters = checked 
                    ? [...sourceFilter, 'public'] 
                    : sourceFilter.filter(t => t !== 'public');
                  setSourceFilter(newFilters);
                }}
              >
                <GlobalOutlined /> Public
              </Tag.CheckableTag>
            </div>
          </div>
        </div>
      )}
      
      {/* Filter placeholder to maintain proper spacing in page flow - moved up by total 800px */}
      <div className="filter-placeholder" style={{ height: `${maxFilterHeight - 830}px` }}></div>

      
      {/* Content based on viewType - positioned exactly 10px below the expanded filter */}
      <div style={{ 
        position: 'relative',
        zIndex: 20,
        backgroundColor: 'white',
        border: '1px solid #e6ecff',
        borderRadius: '16px',
        boxShadow: '0 2px 8px rgba(76, 111, 255, 0.1)',
        padding: '10px',
        marginTop: '10px'
      }}>
        {viewType === 'lists' ? (
          <div>
            <Space direction="vertical" size={12} style={{ width: '100%', display: 'flex', padding: '16px' }}>
              {sampleLists.map(renderPlanCard)}
            </Space>
            <div className="add-custom-button" style={{ padding: '0 16px 16px' }}>
              <Button 
                type="dashed" 
                icon={<PlusOutlined />}
                style={{ 
                  borderColor: '#5c9dff',
                  color: '#5c9dff'
                }}
              >
                Create Your Own List for Emma
              </Button>
            </div>
          </div>
        ) : (
          <div>
            {/* Products by Category Section */}
            <div className="products-by-category" style={{ padding: '0 16px 16px' }}>
              {/* ProductList Component */}
              <div style={{ 
                backgroundColor: '#fff', 
                padding: '0',
                paddingBottom: '20px', 
                borderRadius: '8px', 
                boxShadow: '0 1px 4px rgba(0, 0, 0, 0.05)', 
                overflow: 'visible'
              }}>
                <ProductList 
                  isOpen={false} 
                  show={true}
                  selectedTopicId={selectedTopicId} 
                />
              </div>
              
              <div className="add-custom-button" style={{ marginTop: '20px', textAlign: 'center' }}>
                <Button 
                  type="dashed" 
                  icon={<PlusOutlined />}
                  style={{ 
                    borderColor: '#5c9dff',
                    color: '#5c9dff'
                  }}
                >
                  Add a Different Product for Emma
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      
      {/* Product Chatbot Modal */}
      <Modal
        title={`Get List Suggestions for ${babyName}`}
        open={showChatbot}
        onCancel={handleNoThanks}
        footer={null}
        width="100%"
        style={{ top: 0, maxWidth: '100vw', margin: 0, padding: 0 }}
        bodyStyle={{ height: 'calc(100vh - 60px)', padding: '20px', overflow: 'auto' }}
        maskClosable={true}
        destroyOnClose={true}
        closable={true}
      >
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <Title level={4}>Let's build a personalized list for {babyName}</Title>
          <Paragraph>
            Tell me about your preferences, lifestyle, and needs so I can suggest the perfect list for you.
          </Paragraph>
        </div>
        <ProductChatbot
          categoryId="suggestions"
          categoryName="Baby List Suggestions"
          onProductSelect={handleProductSelect}
          onNoThanks={handleNoThanks}
        />
      </Modal>

      {/* Add smaller space at the bottom to prevent cut-off */}
      <div style={{ height: '80px' }}></div>
      
      {/* Database Test Modal */}
      <DatabaseTestView 
        visible={testModalVisible}
        onClose={() => setTestModalVisible(false)}
      />
    </div>
  );
};

// Map Redux state to component props
const mapStateToProps = (state) => ({
  categories: state.categories,
  allCategories: state.categories.allCategories,
  categoriesWithProducts: state.products.categoriesWithProducts,
  products: state.products.products, // Add products to the mapping
  allCategoriesLoading: state.categories.readAllCategoriesInProgress,
  categoriesWithProductsLoading: state.products.readCategoriesWithProductsInProgress,
  allTopics: state.topics.allTopics, // Include topics from Redux store
});

// Map Redux actions to component props
const mapDispatchToProps = {
  readAllCategories,
  readCategoriesWithProducts,
  readAllTopics
};

export default connect(mapStateToProps, mapDispatchToProps)(Explore);