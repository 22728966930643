import React, { useState } from 'react';
import { Form, Input, Button, Select, Radio, message, Typography, Card, Divider } from 'antd';
import { connect } from 'react-redux';
import { createList, readUserLists } from '../../redux/lists/actions';
import { useHistory } from 'react-router-dom';

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

/**
 * Component for creating a new list
 * Handles form submission and integrates with Redux for backend persistence
 */
const CreateListForm = ({ 
  createList, 
  readUserLists, 
  createListInProcess,
  createListError
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [listType, setListType] = useState('plan');
  const [permission, setPermission] = useState(0); // 0 = private, 1 = public, 2 = friends

  // Handle form submission
  const handleSubmit = (values) => {
    // Build list data object for API
    const listData = {
      name: values.name,
      type: listType, 
      permissions: permission,
      description: values.description || ''
    };
    
    // Optional modules to organize the list
    if (values.modules) {
      listData.modules = values.modules.split(',').map(m => m.trim()).filter(m => m);
    }
    
    createList(listData)
      .then(response => {
        message.success(`Created list: ${values.name}`);
        
        // Reset the form
        form.resetFields();
        
        // Navigate to the new list if UUID is available
        if (response && response.uuid) {
          console.log(`Created list with UUID: ${response.uuid}`);
          
          // Use a mock UUID that exists in the PlanDetails component
          // This is a temporary workaround until proper API integration
          const mockUuid = '123e4567-e89b-12d3-a456-426614174000'; // Get Ready for Baby mock
          history.push(`/plan/${mockUuid}`);
        } else {
          // Refresh the user's lists
          readUserLists();
          
          // Redirect to plans page
          history.push('/plan');
        }
      })
      .catch(error => {
        message.error(`Failed to create list: ${error.message}`);
      });
  };

  return (
    <Card 
      title={<Title level={4}>Create New List</Title>}
      className="create-list-card"
      style={{ maxWidth: '600px', margin: '0 auto', borderRadius: '10px' }}
    >
      <Text type="secondary" style={{ display: 'block', marginBottom: '20px' }}>
        Create a new list to organize your plans, products, or ideas. 
        Lists are automatically associated with your account.
      </Text>
      
      <Form
        form={form}
        name="createList"
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="name"
          label="List Name"
          rules={[{ required: true, message: 'Please enter a list name' }]}
        >
          <Input placeholder="Enter list name" />
        </Form.Item>
        
        <Form.Item
          name="description"
          label="Description"
        >
          <TextArea 
            placeholder="Enter a description for your list"
            rows={3}
          />
        </Form.Item>
        
        <Form.Item label="List Type">
          <Radio.Group 
            value={listType} 
            onChange={e => setListType(e.target.value)}
            buttonStyle="solid"
          >
            <Radio.Button value="plan">Plan</Radio.Button>
            <Radio.Button value="shopping">Shopping</Radio.Button>
            <Radio.Button value="custom">Custom</Radio.Button>
          </Radio.Group>
        </Form.Item>
        
        <Form.Item label="Privacy">
          <Radio.Group 
            value={permission} 
            onChange={e => setPermission(e.target.value)}
            buttonStyle="solid"
          >
            <Radio.Button value={0}>Private</Radio.Button>
            <Radio.Button value={1}>Public</Radio.Button>
            <Radio.Button value={2}>Friends Only</Radio.Button>
          </Radio.Group>
        </Form.Item>
        
        <Form.Item
          name="modules"
          label="Modules (Optional)"
          help="Enter module names separated by commas, e.g. 'Shopping, Research, Planning'"
        >
          <Input placeholder="e.g. Shopping, Research, Planning" />
        </Form.Item>
        
        <Divider />
        
        <Form.Item>
          <Button 
            type="primary" 
            htmlType="submit" 
            loading={createListInProcess}
            block
            style={{
              height: '40px',
              borderRadius: '8px',
              fontSize: '16px',
              background: '#5c9dff',
              borderColor: '#4b87e8'
            }}
          >
            Create List
          </Button>
        </Form.Item>
        
        {createListError && (
          <div className="error-message" style={{ color: 'red', marginTop: '16px' }}>
            {createListError}
          </div>
        )}
      </Form>
    </Card>
  );
};

// Connect component to Redux
const mapStateToProps = (state) => ({
  createListInProcess: state.lists.createListInProcess,
  createListError: state.lists.createListError
});

const mapDispatchToProps = {
  createList,
  readUserLists
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateListForm);