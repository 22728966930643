import axios from 'axios';
import * as types from './actionTypes';
import { isEmpty } from '../../../app/helperFunctions';

function readAllProductsInCategoryInProgress(bool) {
  return {
    type: types.READ_PRODUCTS_IN_CATEGORY_IN_PROGRESS,
    readAllProductsInCategoryInProgress: bool
  }
}

function readAllProductsInCategorySuccess(products) {
  return {
    type: types.READ_PRODUCTS_IN_CATEGORY_SUCCESS,
    products
  }
}

function readAllProductsInCategoryError(errMessage) {
  return {
    type: types.READ_PRODUCTS_IN_CATEGORY_ERROR,
    readAllProductsInCategoryError: errMessage
  }
}

function createUserLinkProductError(errMessage) {
  return {
    type: types.CREATE_USER_LINK_PRODUCT_ERROR,
    createUserLinkProductError: errMessage
  }
}

function updateUserLinkProductError(errMessage) {
  return {
    type: types.UPDATE_USER_LINK_PRODUCT_ERROR,
    updateUserLinkProductError: errMessage
  }
}

function deleteUserLinkProductError(errMessage) {
  return {
    type: types.DELETE_USER_LINK_PRODUCT_ERROR,
    deleteUserLinkProductError: errMessage
  }
}

function readCategoriesWithProductsInProgress(bool) {
  return {
    type: types.READ_CATEGORIES_WITH_PRODUCTS_IN_PROGRESS,
    readCategoriesWithProductsInProgress: bool
  }
}

function readCategoriesWithProductsError(errMessage) {
  return {
    type: types.READ_CATEGORIES_WITH_PRODUCTS_ERROR,
    readCategoriesWithProductsError: errMessage
  };
}

function readCategoriesWithProductsSuccess(bool, categoriesWithProducts) {
  return {
    type: types.READ_CATEGORIES_WITH_PRODUCTS_SUCCESS,
    readCategoriesWithProductsSuccess: bool,
    categoriesWithProducts: categoriesWithProducts
  };
}

export function getCategoryProductData(favorited, friendUuid, productSearch, currentPage, perPage) {
  let url = `${process.env.REACT_APP_API_URL}/categories/products/pages`;
  let data = {};
  if (favorited !== null && favorited !== "null") {
    data.favorited = favorited;
  }
  if (friendUuid !== null) {
    data.friendUuid = friendUuid;
  }
  if (productSearch !== null) {
    data.productSearch = productSearch;
  }
  if (currentPage !== null) {
    data.page = currentPage;
  }
  if (perPage !== null) {
    data.perPage = perPage;
  }
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}`,
    },
    params: data,
  };
  // dispatch(readCategoriesWithProductsInProgress(true));

  // axios.post(url, data, config)

  return axios.get(url, config).then((response) => {
    if (!isEmpty(response)) {
      return response.data;
    }
    return [];
  });
}

export function getAllCategoryProductData(categoryId, friendUuid, favorited) {
  let url = `${process.env.REACT_APP_API_URL}/categories/${categoryId}/products`;
  let data = {
    "friendUuid": friendUuid,
    "favorited": favorited
  };
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}`,
    },
    params: data,
  };
  // dispatch(readCategoriesWithProductsInProgress(true));

  // axios.post(url, data, config)

  return axios.get(url, config).then((response) => {
    if (!isEmpty(response)) {
      return response.data;
    }
    return [];
  });
}

export function getCategoryListData() {
  let url = `${process.env.REACT_APP_API_URL}/categories`;
  let data = {};
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}`,
    },
    params: data,
  };
  // dispatch(readCategoriesWithProductsInProgress(true));

  // axios.post(url, data, config)

  return axios.get(url, config).then((response) => {
    if (!isEmpty(response)) {
      return response.data;
    }
    return [];
  });
}

export function updateProductSetFavorite(productId, favorited) {
  let url = `${process.env.REACT_APP_API_URL}/users/products/${productId}`;
  let config = { headers: { Authorization: "Bearer " + sessionStorage.getItem("jwt") } };

  let data = {
    favorited: favorited,
  };

  return axios.patch(url, data, config);
}

export function updateProductSetResearched(productId, researched) {
  let url = `${process.env.REACT_APP_API_URL}/users/products/${productId}`;
  let config = { headers: { Authorization: "Bearer " + sessionStorage.getItem("jwt") } };

  let data = {
    researched: researched,
  };

  return axios.patch(url, data, config);
}

export function updateProductSetRating(productId, product_rating) {
  let url = `${process.env.REACT_APP_API_URL}/users/products/${productId}`;
  let config = { headers: { Authorization: "Bearer " + sessionStorage.getItem("jwt") } };

  let data = {
    product_rating: product_rating,
  };

  return axios
    .patch(url, data, config)
    .then((response) => {
      return true;
    })
    .catch((error) => {
      return false;
    });
}

export function updateProductSetPurchased(productId, purchased) {
  let url = `${process.env.REACT_APP_API_URL}/users/products/${productId}`;
  let config = { headers: { Authorization: "Bearer " + sessionStorage.getItem("jwt") } };

  let data = {
    purchased: purchased,
  };

  return axios
    .patch(url, data, config)
    .then((response) => {
      return true;
    })
    .catch((error) => {
      return false;
    });
}

export function readAllUsersFriends() {
  let url = `${process.env.REACT_APP_API_URL}/users/friends`;
  let config = {headers: {Authorization: 'Bearer '+localStorage.getItem('jwt')}};

  return axios.get(url, config).then((response) => {
    if (!isEmpty(response)) {
      return response.data;
    }
    return [];
  });
}

export function readCategoriesWithProducts(favorited=null, friendUuid=null, productSearch=null, page=null, perPage=null) {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/products/all`;
    let config = {headers: {Authorization: `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}`}};
    let data = {};
    if (favorited !== null && favorited !== "null") {
      data.favorited = favorited
    }
    if (friendUuid !== null) {
      data.friendUuid = friendUuid
    }
    if (productSearch !== null) {
      data.productSearch = productSearch
    }
    if (page !== null) {
      data.page = page
    }
    if (perPage !== null) {
      data.perPage = perPage
    }
    dispatch(readCategoriesWithProductsInProgress(true));
    axios.post(url, data, config)
      .then((response) => {
        if (!isEmpty(response)) {
          dispatch(readCategoriesWithProductsSuccess(true, response.data));
        } else {
          dispatch(readCategoriesWithProductsSuccess(false, null));
        }
      })
      .catch(error => {
        let errMessage = 'Error fetching product data. Please try again later.';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(readCategoriesWithProductsError(errMessage));
      })
      .finally(() => {
        dispatch(readCategoriesWithProductsInProgress(false));
        dispatch(readCategoriesWithProductsError(''));
      });
  }
}

export function readProductsInCategory(category_uuid) {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/categories/${category_uuid}/products`;
    let config = {headers: {Authorization: `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}`}};
    dispatch(readAllProductsInCategoryInProgress(true));
    axios.get(url, config)
      .then((response) => {
        if (!isEmpty(response)) {
          dispatch(readAllProductsInCategorySuccess(response.data));
        } else {
          dispatch(readAllProductsInCategorySuccess([]));
        }
      })
      .catch(error => {
        let errMessage = 'Error fetching product data. Please try again later.';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(readAllProductsInCategoryError(errMessage));
      })
      .finally(() => {
        dispatch(readAllProductsInCategoryInProgress(false));
        dispatch(readAllProductsInCategoryError(''));
      });
  }
}

export function createUserLinkProduct(product, categoriesWithProducts, researched, favorited, purchased, product_rating, context, view_mode) {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/users/products`;
    let config = {headers: {Authorization: `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}`}};

    let data = {
      products_uuid: product.uuid,
      researched: researched,
      favorited: favorited,
      purchased: purchased,
      product_rating: product_rating
    }

    if (!isEmpty(categoriesWithProducts)) {
      for (var i = 0; i < categoriesWithProducts.length; i++) {
        for (var x = 0; x < categoriesWithProducts[i].products.length; x++) {
          if (categoriesWithProducts[i].products[x].uuid === product.uuid) {
            categoriesWithProducts[i].products[x].researched = researched;
            categoriesWithProducts[i].products[x].favorited = favorited;
            categoriesWithProducts[i].products[x].purchased = purchased;
            categoriesWithProducts[i].products[x].product_rating = product_rating;
          }
        }
      }
    }

    axios.post(url, data, config)
      .then((response) => {
        if (context === "page") {
          dispatch(readCategoriesWithProductsSuccess(false, categoriesWithProducts));
        } else if (context === "drawer") {
          dispatch(readProductsInCategory(product.categories_uuid));
        }
      })
      .catch(error => {
        let errMessage = 'Error creating user_link_product data. Please try again later.';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(createUserLinkProductError(errMessage));
      })
      .finally(() => {
        dispatch(createUserLinkProductError(''));
      });
  }
}

export function updateUserLinkProduct(product, categoriesWithProducts, researched, favorited, purchased, product_rating, context, view_mode) {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/users/products/${product.uuid}`;
    let config = {headers: {Authorization: `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}`}};

    let data = {
      researched: researched,
      favorited: favorited,
      purchased: purchased,
      product_rating: product_rating
    }

    if (!isEmpty(categoriesWithProducts)) {
      for (var i = 0; i < categoriesWithProducts.length; i++) {
        for (var x = 0; x < categoriesWithProducts[i].products.length; x++) {
          if (categoriesWithProducts[i].products[x].uuid === product.uuid) {
            categoriesWithProducts[i].products[x].researched = researched;
            categoriesWithProducts[i].products[x].favorited = favorited;
            categoriesWithProducts[i].products[x].purchased = purchased;
            categoriesWithProducts[i].products[x].product_rating = product_rating;
          }
        }
      }
    }

    axios.patch(url, data, config)
      .then((response) => {
        if (context === "page") {
          dispatch(readCategoriesWithProductsSuccess(false, categoriesWithProducts));
        } else if (context === "drawer") {
          dispatch(readProductsInCategory(product.categories_uuid));
        }
      })
      .catch(error => {
        let errMessage = 'Error updating user_link_product data. Please try again later.';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(updateUserLinkProductError(errMessage));
      })
      .finally(() => {
        dispatch(updateUserLinkProductError(''));
      });
  }
}

export function deleteUserLinkProduct(category_uuid, products_uuid) {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/users/products/${products_uuid}`;
    let config = {headers: {Authorization: `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}`}};

    axios.delete(url, config)
      .then((response) => {
        dispatch(readProductsInCategory(category_uuid));
      })
      .catch(error => {
        let errMessage = 'Error deleting user_link_product data. Please try again later.';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(deleteUserLinkProductError(errMessage));
      })
      .finally(() => {
        dispatch(deleteUserLinkProductError(''));
      });
  }
}

export const saveProductState = (categoryId, productId, isInPlan) => {
  return async (dispatch) => {
    dispatch({ type: types.PRODUCT_LOADING, payload: true });
    
    try {
      const config = { 
        headers: { 
          Authorization: 'Bearer ' + localStorage.getItem('jwt') 
        } 
      };
      
      const data = {
        products_uuid: productId,
        researched: true,
        favorited: isInPlan,
        purchased: false,
        product_rating: null
      };

      // Try to create first
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/users/products`, data, config);
      } catch (error) {
        if (error.response && error.response.status === 409) {
          // If record already exists (409 Conflict), then update it
          await axios.patch(`${process.env.REACT_APP_API_URL}/users/products/${productId}`, data, config);
        } else {
          throw error;
        }
      }

      // Only update Redux after successful API call
      dispatch({
        type: types.SAVE_PRODUCT_STATE,
        payload: { categoryId, productId, isInPlan }
      });

      // Update localStorage after successful API call
      const userUuid = localStorage.getItem('userUuid');
      const localStorageKey = `productStates_${userUuid}`;
      const savedStates = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
      if (!savedStates[categoryId]) {
        savedStates[categoryId] = {};
      }
      savedStates[categoryId][productId] = isInPlan;
      localStorage.setItem(localStorageKey, JSON.stringify(savedStates));

      dispatch({ type: types.PRODUCT_SUCCESS, payload: true });
    } catch (error) {
      console.error('Error saving product state:', error);
      dispatch({ type: types.PRODUCT_ERROR, payload: error.message });
      throw error;
    } finally {
      dispatch({ type: types.PRODUCT_LOADING, payload: false });
    }
  };
};

export const loadSavedProductStates = () => {
  return async (dispatch) => {
    const userUuid = localStorage.getItem('userUuid');
    
    if (!userUuid) {
      console.log('No userUuid found in localStorage');
      return;
    }
    
    try {
      // First load from localStorage for immediate UI response
      const localStorageKey = `productStates_${userUuid}`;
      const cachedStates = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
      
      if (Object.keys(cachedStates).length > 0) {
        dispatch({
          type: types.LOAD_SAVED_PRODUCT_STATES,
          payload: cachedStates
        });
      }

      // Then fetch from backend
      const url = `${process.env.REACT_APP_API_URL}/users/products`;
      const config = { 
        headers: { 
          Authorization: 'Bearer ' + localStorage.getItem('jwt') 
        } 
      };

      const response = await axios.get(url, config);
      const productStates = {};
      
      response.data.forEach(product => {
        const categoryId = product.categories_uuid;
        if (!productStates[categoryId]) {
          productStates[categoryId] = {};
        }
        productStates[categoryId][product.products_uuid] = product.favorited;
      });

      localStorage.setItem(localStorageKey, JSON.stringify(productStates));
      
      dispatch({
        type: types.LOAD_SAVED_PRODUCT_STATES,
        payload: productStates
      });
    } catch (error) {
      console.error('Error loading product states:', error);
      dispatch({ type: types.PRODUCT_ERROR, payload: error.message });
    }
  };
};

export const clearProductStates = () => {
  return (dispatch) => {
    const userUuid = localStorage.getItem('userUuid');
    if (userUuid) {
      localStorage.removeItem(`productStates_${userUuid}`);
    }
    dispatch({
      type: types.CLEAR_PRODUCT_STATES,
      payload: {}
    });
  };
};