const initialState = {
  photoUploadModalVisible: false,
  tutorialStep: 1,
  editableInputId: null,
  showRemovedTasks: false,
  isLoadingSharedNotes: {},
  currentTab: 'life',
  showTutorial: true,
  isAddMomentExpanded: false,
  
  // Database testing state
  databaseTestState: {
    tables: [],
    loading: false,
    error: null,
    apiLogs: [],
    showLogs: true,
    processingTable: null,
    tableDefinitions: [
      {
        name: 'lists',
        description: 'Stores the main list information',
        endpoint: '/api/v1/lists',
        columns: [
          { name: 'uuid', type: 'uuid', primary: true },
          { name: 'name', type: 'character varying(255)' },
          { name: 'type', type: 'character varying(255)' },
          { name: 'permissions', type: 'integer' },
          { name: 'inserted_at', type: 'timestamp with time zone' },
          { name: 'updated_at', type: 'timestamp with time zone' }
        ]
      },
      {
        name: 'list_items',
        description: 'Contains items within each list',
        endpoint: '/api/v1/list_items',
        columns: [
          { name: 'uuid', type: 'uuid', primary: true },
          { name: 'lists_uuid', type: 'uuid', foreign: 'lists.uuid' },
          { name: 'entity_uuid', type: 'uuid' },
          { name: 'inserted_at', type: 'timestamp with time zone' },
          { name: 'updated_at', type: 'timestamp with time zone' }
        ]
      },
      {
        name: 'users',
        description: 'Stores user information',
        endpoint: '/api/v1/users',
        columns: [
          { name: 'uuid', type: 'uuid', primary: true },
          { name: 'email', type: 'character varying(255)' },
          { name: 'first_name', type: 'character varying(255)' },
          { name: 'last_name', type: 'character varying(255)' },
          { name: 'password_hash', type: 'character varying(1024)' },
          { name: 'inserted_at', type: 'timestamp with time zone' },
          { name: 'updated_at', type: 'timestamp with time zone' }
        ]
      },
      {
        name: 'users_link_lists',
        description: 'Associates users with the lists they own',
        endpoint: '/api/v1/users_link_lists',
        columns: [
          { name: 'uuid', type: 'uuid', primary: true },
          { name: 'users_uuid', type: 'uuid', foreign: 'users.uuid' },
          { name: 'lists_uuid', type: 'uuid', foreign: 'lists.uuid' },
          { name: 'inserted_at', type: 'timestamp with time zone' },
          { name: 'updated_at', type: 'timestamp with time zone' }
        ]
      },
      {
        name: 'categories',
        description: 'Stores product categories',
        endpoint: '/api/v1/categories',
        columns: [
          { name: 'uuid', type: 'uuid', primary: true },
          { name: 'name', type: 'character varying(255)' },
          { name: 'description', type: 'text' },
          { name: 'inserted_at', type: 'timestamp with time zone' },
          { name: 'updated_at', type: 'timestamp with time zone' }
        ]
      },
      {
        name: 'products',
        description: 'Stores product information',
        endpoint: '/api/v1/products',
        columns: [
          { name: 'uuid', type: 'uuid', primary: true },
          { name: 'name', type: 'character varying(255)' },
          { name: 'description', type: 'text' },
          { name: 'category_uuid', type: 'uuid', foreign: 'categories.uuid' },
          { name: 'inserted_at', type: 'timestamp with time zone' },
          { name: 'updated_at', type: 'timestamp with time zone' }
        ]
      },
      {
        name: 'topics',
        description: 'Stores topic information',
        endpoint: '/api/v1/topics',
        columns: [
          { name: 'uuid', type: 'uuid', primary: true },
          { name: 'name', type: 'character varying(255)' },
          { name: 'description', type: 'text' },
          { name: 'inserted_at', type: 'timestamp with time zone' },
          { name: 'updated_at', type: 'timestamp with time zone' }
        ]
      }
    ]
  }
};

export default initialState;
