import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Button, 
  Form, 
  Radio, 
  Card, 
  message, 
  Breadcrumb,
  Space, 
  Tag, 
  Row,
  Col 
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { 
  PlusOutlined, 
  HomeOutlined,
  ArrowLeftOutlined,
  UserOutlined,
  TeamOutlined,
  GlobalOutlined,
  RightCircleOutlined
} from '@ant-design/icons';
import BibiLogo from '../../assets/images/BibiLogo';
import '../../assets/css/plans.css'; // Add plans css for consistent styling
import axios from 'axios';
import { 
  readUserLists, 
  createList
} from '../redux/lists/actions';
import {
  verifyUserAuthentication
} from '../redux/users/actions';

const { Title, Text, Paragraph } = Typography;

const TemplateSelectionView = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  
  // Get data from Redux store
  const { 
    createListInProcess
  } = useSelector(state => state.lists);
  
  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
  };
  
  // Run authentication check and sync when component mounts
  useEffect(() => {
    const syncAuth = async () => {
      console.log('Running authentication check and token sync on component mount...');
      const authResult = await dispatch(verifyUserAuthentication());
      console.log('Auth check result:', authResult);
      
      // If we have a token mismatch but authentication succeeded, this will fix it for future requests
      if (authResult.jwtLocal !== authResult.jwtSession) {
        console.log('JWT token storage locations were synchronized');
      }
    };
    
    syncAuth();
  }, [dispatch]);


  const handleCreateFromTemplate = async () => {
    if (selectedTemplate) {
      // Show loading indicator
      const loadingMessage = message.loading(`Adding ${selectedTemplate.name} to your plan...`, 0);
      
      console.log('Debug: Checking token before create');
      console.log('JWT in localStorage:', localStorage.getItem('jwt'));
      console.log('JWT in sessionStorage:', sessionStorage.getItem('jwt'));
      
      // Always ensure tokens are synchronized first
      const localJwt = localStorage.getItem('jwt');
      const sessionJwt = sessionStorage.getItem('jwt');
      
      if (localJwt && !sessionJwt) {
        console.log('Syncing from localStorage to sessionStorage');
        sessionStorage.setItem('jwt', localJwt);
      } else if (!localJwt && sessionJwt) {
        console.log('Syncing from sessionStorage to localStorage');
        localStorage.setItem('jwt', sessionJwt);
      }
    
      // Create list data for API
      const listData = {
        name: `My ${selectedTemplate.name} Plan`,
        type: 'plan', // Using type = plan to identify this as a plan
        permissions: 0,  // Default permissions
        description: selectedTemplate.description || ''
      };
      
      // First check the user authentication to diagnose any issues and sync JWT tokens
      console.log('Checking user authentication before creating list...');
      const authResult = await dispatch(verifyUserAuthentication());
      console.log('User authentication status:', authResult);
      
      if (!authResult.authenticated) {
        loadingMessage(); // Close loading message
        
        if (authResult.jwtLocal || authResult.jwtSession) {
          message.warning(`Authentication issue: ${authResult.error}. Using demo mode.`, 3);
          console.error('JWT token is present but authentication failed. This suggests an issue with the token or backend user data.');
          console.error('JWT in localStorage:', authResult.jwtLocal);
          console.error('JWT in sessionStorage:', authResult.jwtSession);
        } else {
          message.warning('Please log in to save lists. Using demo mode for now.', 3);
        }
        
        // Fall back to demo mode
        setTimeout(() => {
          const mockUuid = '123e4567-e89b-12d3-a456-426614174000';
          history.push(`/plan/${mockUuid}`);
        }, 800);
        return;
      }
      
      // Authentication was successful, now try to create the list
      console.log('User is authenticated. User data:', authResult.userData);
      console.log('Creating list with data:', listData);
      
      try {
        // Make a direct axios call to ensure proper JWT usage
        // (Using the Redux action was failing with 403)
        const jwt = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');
        console.log('Using JWT for list creation request:', jwt ? `${jwt.substring(0, 10)}...` : 'None');
        
        // Make sure both storage locations have the token
        if (jwt) {
          localStorage.setItem('jwt', jwt);
          sessionStorage.setItem('jwt', jwt);
        }
        
        // Create the list
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/lists`, 
          listData,
          { headers: { Authorization: `Bearer ${jwt}` } }
        );
        
        loadingMessage(); // Close loading message
        console.log('List creation successful:', response);
        message.success(`Added ${selectedTemplate.name} to your plan`, 2);
        
        // For navigation - use the returned UUID if available, otherwise fallback to mock
        const listUuid = response?.data?.uuid || '123e4567-e89b-12d3-a456-426614174000';
        console.log('Using list UUID for navigation:', listUuid);
        
        // Provide a slight delay to show the success message before navigation
        setTimeout(() => {
          console.log(`Navigating to plan details: /plan/${listUuid}`);
          history.push(`/plan/${listUuid}`);
          
          // Refresh the lists in the background to ensure up-to-date data on return
          dispatch(readUserLists()).catch(error => {
            console.warn('Background list refresh error:', error);
          });
        }, 500);
      } catch (error) {
        loadingMessage(); // Close loading message
        
        console.error('API Error creating list:', error);
        
        // If we get a 403 error, it might be a permissions issue
        if (error.response && error.response.status === 403) {
          console.error('403 Forbidden error details:');
          console.error('- Response data:', error.response.data);
          console.error('- Auth JWT localStorage:', !!localStorage.getItem('jwt'));
          console.error('- Auth JWT sessionStorage:', !!sessionStorage.getItem('jwt'));
          console.error('- Previous auth check result:', authResult);
          
          message.warning('Server permission issue. Using demo mode for now.', 3);
          
          // Fall back to mock behavior for demo purposes
          setTimeout(() => {
            const mockUuid = '123e4567-e89b-12d3-a456-426614174000';
            history.push(`/plan/${mockUuid}`);
          }, 800);
        } else {
          // For other errors, show a more specific message
          const errorMsg = error.response?.data?.Error || error.message || 'Unknown error';
          message.error(`Failed to create list: ${errorMsg}`);
          
          // Log detailed error information
          console.error('Error type:', typeof error);
          console.error('Error message:', error.message);
          console.error('Error response:', error.response);
          console.error('Error response data:', error.response?.data);
          console.error('Error response status:', error.response?.status);
          
          // After a longer delay, still navigate to the mock plan to let the demo continue
          setTimeout(() => {
            const mockUuid = '123e4567-e89b-12d3-a456-426614174000';
            history.push(`/plan/${mockUuid}`);
          }, 2000);
        }
      }
    }
  };

  return (
    <div className="template-selection-view">
      <div className="logo-container" style={{ 
        display: 'flex', 
        justifyContent: 'flex-start', 
        alignItems: 'center', 
        padding: '16px 24px',
        margin: 0,
        marginBottom: '15px',
        backgroundColor: 'white',
        borderBottom: '1px solid #f0f0f0',
        boxShadow: '0 1px 4px rgba(0, 0, 0, 0.05), 0 0 0 0 rgba(0,0,0,0)',
        position: 'sticky',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 10,
        width: '100vw',
        marginLeft: 'calc(-50vw + 50%)',
        paddingLeft: 'calc(50vw - 50% + 24px)',
        paddingRight: 'calc(50vw - 50% + 24px)'
      }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BibiLogo width={200} height={28} />
        </div>
      </div>
      
      <div style={{ padding: '0 20px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>
            <a href="#/plan">
              <HomeOutlined /> Plan
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Add List to Plan</Breadcrumb.Item>
        </Breadcrumb>
        
        <Button 
          icon={<ArrowLeftOutlined />} 
          style={{ marginBottom: '20px' }}
          onClick={() => history.push('/plan')}
        >
          Back to Plans
        </Button>
        
        <Title level={3}>Add List to Plan</Title>
        
        <div className="template-selection">
          <div className="recommended-lists-section">
            <div className="section-title-with-icon" style={{ marginBottom: '15px' }}>
              <i className="section-icon">⭐</i>
              <Title level={4} style={{ fontSize: '16px', margin: 0 }}>Suggested for You</Title>
            </div>
            <Paragraph style={{ marginBottom: '15px' }}>
              Quick-start with pre-built lists curated for your baby's stage
            </Paragraph>
            
            <Radio.Group 
              onChange={(e) => handleTemplateSelect({
                uuid: '123e4567-e89b-12d3-a456-426614174000', // Use a valid mock ID from mockPlans
                name: e.target.dataset.title,
                description: e.target.dataset.description,
                modules: ['Shopping', 'Planning']
              })}
              value={selectedTemplate?.uuid}
              style={{ width: '100%' }}
            >
              <div style={{ 
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: '12px',
                padding: '8px 0'
              }}>
                {/* Sample Recommended List Cards - Using explore list style */}
                {[
                  { 
                    id: 'rec-1', 
                    title: 'Essential Baby Gear', 
                    description: 'Must-have items for your newborn',
                    recommendReason: 'you\'re in your second trimester',
                    creator: 'Bibi Team',
                    creatorType: 'official',
                    items: 12,
                    stats: {
                      total_items: 12,
                      completed_items: 0,
                      completion_percentage: 0,
                      sampling_items: 4,
                      training_items: 3,
                      building_items: 5
                    },
                    modules: ['Shopping', 'Research', 'Reviews'],
                    image: require('../../assets/images/Categories_Strollers.png')
                  },
                  { 
                    id: 'rec-2', 
                    title: 'Hospital Bag Checklist', 
                    description: 'Everything you need for delivery day',
                    recommendReason: 'your due date is approaching',
                    creator: 'Sarah Johnson',
                    creatorType: 'friend',
                    items: 15,
                    stats: {
                      total_items: 15,
                      completed_items: 0,
                      completion_percentage: 0,
                      sampling_items: 5,
                      training_items: 3,
                      building_items: 7
                    },
                    modules: ['Essentials', 'Hospital', 'Comfort'],
                    image: require('../../assets/images/HospitalBag.png')
                  },
                  { 
                    id: 'rec-3', 
                    title: 'Nursery Setup', 
                    description: 'Creating a safe and cozy space',
                    recommendReason: 'you searched for cribs recently',
                    creator: 'Bibi Team',
                    creatorType: 'official',
                    items: 10,
                    stats: {
                      total_items: 10,
                      completed_items: 0,
                      completion_percentage: 0,
                      sampling_items: 3,
                      training_items: 2,
                      building_items: 5
                    },
                    modules: ['Furniture', 'Decor', 'Organization'],
                    image: require('../../assets/images/Categories_Crib.png')
                  },
                  { 
                    id: 'rec-4', 
                    title: 'Feeding Essentials', 
                    description: 'Everything for bottle or breastfeeding',
                    recommendReason: 'many parents prepare this in trimester 2',
                    creator: 'Community Guide',
                    creatorType: 'public',
                    items: 8,
                    stats: {
                      total_items: 8,
                      completed_items: 0,
                      completion_percentage: 0,
                      sampling_items: 3,
                      training_items: 2,
                      building_items: 3
                    },
                    modules: ['Bottles', 'Accessories', 'Supplies'],
                    image: require('../../assets/images/Categories_Bottles.png')
                  }
                ].map(item => (
                  <Card 
                    key={item.id}
                    hoverable
                    onClick={() => handleTemplateSelect({
                      uuid: item.id,
                      name: item.title,
                      description: item.description,
                      modules: item.modules,
                      stats: item.stats
                    })}
                    className="plan-card"
                    bodyStyle={{ padding: 0 }}
                    style={{ 
                      border: selectedTemplate?.uuid === item.id ? '2px solid #5c9dff' : '1px solid #e8e8e8'
                    }}
                  >      
                    <div className="plan-content">
                      <div className="plan-info">
                        <Title level={4} style={{ margin: 0, marginBottom: '4px', fontSize: '16px' }}>{item.title}</Title>
                        
                        {item.description && (
                          <Paragraph ellipsis={{ rows: 1 }} style={{ marginBottom: '4px', fontSize: '13px' }}>
                            {item.description}
                          </Paragraph>
                        )}
                        
                        {item.recommendReason && (
                          <div style={{ marginBottom: '6px' }}>
                            <Text type="secondary" style={{ fontSize: '12px', display: 'block', color: '#5c9dff' }}>
                              <span style={{ fontWeight: 'bold' }}>Recommended</span> because {item.recommendReason}
                            </Text>
                          </div>
                        )}
                        
                        <div className="plan-meta">
                          {/* Creator type tags */}
                          <div style={{ marginBottom: '8px' }}>
                            {item.creatorType === 'official' ? (
                              <span className="creator-tag creator-official" style={{ color: '#389e0d', fontSize: '12px' }}>
                                <TeamOutlined style={{ marginRight: '4px' }} />
                                Bibi Team
                              </span>
                            ) : item.creatorType === 'friend' ? (
                              <span className="creator-tag creator-friend" style={{ color: '#d48806', fontSize: '12px' }}>
                                <UserOutlined style={{ marginRight: '4px' }} />
                                Shared by {item.creator}
                              </span>
                            ) : item.creatorType === 'following' ? (
                              <span className="creator-tag creator-following" style={{ color: '#5c9dff', fontSize: '12px' }}>
                                <UserOutlined style={{ marginRight: '4px' }} />
                                Published by {item.creator}, who you follow
                              </span>
                            ) : (
                              <span className="creator-tag creator-public" style={{ color: '#722ed1', fontSize: '12px' }}>
                                <GlobalOutlined style={{ marginRight: '4px' }} />
                                Public
                              </span>
                            )}
                          </div>
                          
                          <div className="plan-modules">
                            {item.modules.map((module, idx) => (
                              <Tag key={idx} style={{ marginRight: 0, fontSize: '11px', padding: '0 6px' }}>
                                {module}
                              </Tag>
                            ))}
                          </div>
                          
                          <div className="plan-stats-group">
                            <div className="plan-item-count" style={{ fontSize: '12px', color: '#5a73b2', marginTop: '4px' }}>
                              {item.stats.total_items} items
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="plan-actions">
                        <Radio 
                          value={item.id} 
                          checked={selectedTemplate?.uuid === item.id}
                          style={{ marginRight: '10px' }}
                        />
                        <RightCircleOutlined style={{ fontSize: '18px', color: '#5c9dff' }} />
                      </div>
                    </div>
                  </Card>
                ))}
              </div>
            </Radio.Group>
          </div>
          
          <div className="build-from-scratch" style={{ marginTop: '30px', padding: '20px', borderTop: '1px solid #f0f0f0' }}>
            <div className="section-title-with-icon" style={{ marginBottom: '15px' }}>
              <i className="section-icon">🛠️</i>
              <Title level={4} style={{ fontSize: '16px', margin: 0 }}>Build from Scratch</Title>
            </div>
            <Paragraph style={{ marginBottom: '15px' }}>
              Create your own custom list with exactly what you need
            </Paragraph>
            <Button 
              type="primary" 
              onClick={() => history.push('/plan/create')} 
              style={{ 
                height: '36px',
                borderRadius: '18px',
                background: '#5c9dff',
                borderColor: '#4b87e8'
              }}
            >
              Create Custom List
            </Button>
          </div>
        </div>
        
        <div style={{ 
          marginTop: '30px', 
          borderTop: '1px solid #f0f0f0', 
          paddingTop: '20px', 
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '12px'
        }}>
          {selectedTemplate && (
            <Paragraph style={{ margin: 0, fontSize: '14px' }}>
              Continue with <strong>{selectedTemplate.name}</strong>
            </Paragraph>
          )}
          <Button 
            type="primary" 
            size="large"
            onClick={handleCreateFromTemplate}
            disabled={!selectedTemplate}
            loading={createListInProcess}
            style={{ 
              height: '45px',
              borderRadius: '22px',
              padding: '0 40px',
              background: '#5c9dff',
              borderColor: '#4b87e8',
              fontSize: '16px',
              fontWeight: 'bold'
            }}
          >
            Add to Plan
          </Button>
        </div>
      </div>
      
      {/* Add space at the bottom to prevent content being hidden by the navigation menu */}
      <div style={{ height: '120px' }}></div>
    </div>
  );
};

export default TemplateSelectionView;