import React, { useEffect, useState, useRef, useCallback } from "react";
import { List, Card, Spin, Empty, Tag, Typography, Button } from "antd";
import { DownOutlined } from '@ant-design/icons';
import { useQuery } from "@tanstack/react-query";
import { getAllCategoryProductData } from "../../redux/products/actions.js";
import ProductItem from "./ProductItem.js";
import { readAllTopics, readAllCategoriesInAllTopics } from "../../redux/topics/actions";
import { readAllCategories } from "../../redux/categories/actions";

const { Title, Text } = Typography;

const ProductList = ({ categoryId, friendUuid, favorited, isOpen, show, selectedTopicId: externalSelectedTopicId }) => {
  const [topics, setTopics] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryProducts, setCategoryProducts] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedTopicId, setSelectedTopicId] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [productsPage, setProductsPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const loadMoreRef = useRef(null);
  const PRODUCTS_PER_CATEGORY = 1000; // Show all products per category (using a high number)
  const TARGET_INITIAL_PRODUCTS = 1000; // Target all products for initial load
  const [totalShownProducts, setTotalShownProducts] = useState(0); // Track total shown products
  const [scrolling, setScrolling] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [loadedCategories, setLoadedCategories] = useState(10); // Initially show 10 categories
  // Removed tracking of visible categories

  // Fetch products for the specified category
  const { isPending, error, data } = useQuery({
    queryKey: ["categoryAllProductList", categoryId, favorited, friendUuid],
    queryFn: () => getAllCategoryProductData(categoryId, friendUuid, favorited),
    enabled: show && categoryId != null,
  });

  // Variable to track if we should fetch data for the hierarchical view
  const shouldFetchHierarchicalData = !categoryId && show;
  
  // Update visible products based on selected category and page
  const updateVisibleProducts = useCallback((categoryId, products, page) => {
    if (!categoryId || !products[categoryId]) return;
    
    const allProducts = products[categoryId] || [];
    const startIdx = 0;
    const endIdx = page * PRODUCTS_PER_CATEGORY;
    const pagedProducts = allProducts.slice(startIdx, endIdx);
    
    setVisibleProducts(pagedProducts);
    setHasMore(endIdx < allProducts.length);
    setProductsPage(page);
  }, [PRODUCTS_PER_CATEGORY]);
  
  // Handle topic selection
  const handleTopicSelect = useCallback((topicId) => {
    setSelectedTopicId(topicId);
    
    // Find first category in this topic
    const topicCategories = categories.filter(cat => cat.topics_uuid === topicId);
    if (topicCategories.length > 0) {
      const firstCategoryId = topicCategories[0].uuid;
      setSelectedCategoryId(firstCategoryId);
      
      // Determine if this category is currently loaded
      let categoryIndex = 0;
      
      // Calculate the index of this category across all topics
      for (const topic of topics) {
        const currentTopicCategories = categories.filter(cat => cat.topics_uuid === topic.uuid);
        
        if (topic.uuid === topicId) {
          // Found our topic, so the index is the current count
          break;
        }
        
        // Add the categories from previous topics to our count
        categoryIndex += currentTopicCategories.length;
      }
      
      // Store the selected topic ID in a ref to prevent scroll handler from changing it
      const preserveSelectedTopic = () => {
        // This function runs during the scroll event to keep our selection
        const originalSelectedTopic = topicId;
        const originalSelectedCategory = firstCategoryId;
        
        // Create a temporary handler that runs only once
        const tempScrollHandler = () => {
          // Force reset the selection back to our chosen topic after scrolling
          setTimeout(() => {
            setSelectedTopicId(originalSelectedTopic);
            setSelectedCategoryId(originalSelectedCategory);
          }, 100);
          
          // Remove this handler after it runs once
          window.removeEventListener('scroll', tempScrollHandler);
        };
        
        // Add the temporary handler
        window.addEventListener('scroll', tempScrollHandler, { once: true });
      };
      
      // Check if this category would be within our loaded range
      if (categoryIndex >= loadedCategories) {
        // Category is not loaded yet, so we need to load enough categories to include it
        setLoading(true);
        
        // Calculate how many categories we need to load
        const categoriesNeeded = categoryIndex + 1;
        
        // Set loadedCategories to include this category
        setLoadedCategories(categoriesNeeded);
        
        // Short delay to ensure the category is rendered before scrolling
        setTimeout(() => {
          preserveSelectedTopic();
          
          const categoryElement = document.getElementById(`category-${firstCategoryId}`);
          if (categoryElement) {
            // Set attribute to indicate this is a manual scroll from topic selection
            document.body.setAttribute('data-manual-scroll', 'true');
            
            // Calculate position with additional offset to prevent first product from being hidden
            const topOffset = categoryElement.getBoundingClientRect().top + window.pageYOffset - 65;
            window.scrollTo({
              top: topOffset - 15, // Scroll to 15px higher than the category element
              behavior: 'smooth'
            });
          }
          setLoading(false);
        }, 500);
      } else {
        // Category is already loaded, just scroll to it
        preserveSelectedTopic();
        
        const categoryElement = document.getElementById(`category-${firstCategoryId}`);
        if (categoryElement) {
          // Set attribute to indicate this is a manual scroll from topic selection
          document.body.setAttribute('data-manual-scroll', 'true');
          
          // Calculate position with additional offset to prevent first product from being hidden
          const topOffset = categoryElement.getBoundingClientRect().top + window.pageYOffset - 65;
          window.scrollTo({
            top: topOffset - 15, // Scroll to 15px higher than the category element
            behavior: 'smooth'
          });
        }
      }
    } else {
      setSelectedCategoryId(null);
      setVisibleProducts([]);
    }
  }, [categories, topics, loadedCategories]);
  
  // Handle category selection
  const handleCategorySelect = useCallback((categoryId) => {
    setSelectedCategoryId(categoryId);
    
    // Find the category in our data
    const selectedCategory = categories.find(cat => cat.uuid === categoryId);
    if (!selectedCategory) return;
    
    // Find the index of this category across all topics
    let categoryIndex = 0;
    let foundCategory = false;
    
    for (const topic of topics) {
      const topicCategories = categories.filter(cat => cat.topics_uuid === topic.uuid);
      
      for (const category of topicCategories) {
        if (category.uuid === categoryId) {
          foundCategory = true;
          break;
        }
        categoryIndex++;
      }
      
      if (foundCategory) break;
    }
    
    // Check if this category would be within our loaded range
    if (categoryIndex >= loadedCategories) {
      // Category is not loaded yet, so we need to load enough categories to include it
      setLoading(true);
      
      // Set loadedCategories to include this category
      setLoadedCategories(categoryIndex + 1);
      
      // Short delay to ensure the category is rendered before scrolling
      setTimeout(() => {
        const categoryElement = document.getElementById(`category-${categoryId}`);
        if (categoryElement) {
          // Calculate position with additional offset to prevent first product from being hidden
          const topOffset = categoryElement.getBoundingClientRect().top + window.pageYOffset - 65;
          window.scrollTo({
            top: topOffset - 15, // Scroll to 15px higher than the category element
            behavior: 'smooth'
          });
        }
        setLoading(false);
      }, 500);
    } else {
      // Category is already loaded, just scroll to it
      const categoryElement = document.getElementById(`category-${categoryId}`);
      if (categoryElement) {
        // Calculate position with additional offset to prevent first product from being hidden
        const topOffset = categoryElement.getBoundingClientRect().top + window.pageYOffset - 65;
        window.scrollTo({
          top: topOffset - 15, // Scroll to 15px higher than the category element
          behavior: 'smooth'
        });
      }
    }
  }, [categories, topics, loadedCategories]);
  
  // Handle load more categories
  const handleLoadMore = useCallback(() => {
    // Load 10 more categories when the user scrolls to the bottom
    setLoading(true);
    
    // Small delay to allow loading indicator to show
    setTimeout(() => {
      setLoadedCategories(prevCount => prevCount + 10);
      setLoading(false);
    }, 500);
  }, []);
  
  // Function to scroll back to top
  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  
  // Set up intersection observer for infinite scrolling
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '100px',
      threshold: 0.1
    };
    
    const observer = new IntersectionObserver(entries => {
      const [entry] = entries;
      if (entry.isIntersecting && !loading && loadedCategories < categories.length) {
        handleLoadMore();
      }
    }, options);
    
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }
    
    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [loading, handleLoadMore, loadedCategories, categories.length]);
  
  // Add scroll event listener to highlight active category
  // Calculate total products being shown across all loaded categories
  useEffect(() => {
    if (!shouldFetchHierarchicalData || categories.length === 0) return;

    let totalProducts = 0;
    let categoryCount = 0;
    
    // Calculate optimal initial categories to reach TARGET_INITIAL_PRODUCTS
    for (const topic of topics) {
      const topicCategories = categories.filter(cat => cat.topics_uuid === topic.uuid);
      
      for (const category of topicCategories) {
        const productsInCategory = Math.min(
          (categoryProducts[category.uuid] || []).length,
          PRODUCTS_PER_CATEGORY
        );
        
        totalProducts += productsInCategory;
        categoryCount++;
        
        // Once we've reached our target, stop counting
        if (categoryCount >= loadedCategories) {
          break;
        }
      }
      
      if (categoryCount >= loadedCategories) {
        break;
      }
    }
    
    setTotalShownProducts(totalProducts);
  }, [shouldFetchHierarchicalData, categories, topics, categoryProducts, PRODUCTS_PER_CATEGORY, loadedCategories]);

  useEffect(() => {
    if (!shouldFetchHierarchicalData || categories.length === 0) return;
    
    const handleScroll = () => {
      if (scrolling) return;
      
      setScrolling(true);
      // Use requestAnimationFrame to throttle scroll event processing
      window.requestAnimationFrame(() => {
        const scrollPosition = window.scrollY;
        
        // Show/hide back to top button based on scroll position
        if (scrollPosition > 300) {
          setShowBackToTop(true);
        } else {
          setShowBackToTop(false);
        }
        
        // Handle sticky headers pushing effect
        const stickyHeaders = document.querySelectorAll('.sticky-header-container');
        
        if (stickyHeaders.length > 1) {
          for (let i = 0; i < stickyHeaders.length - 1; i++) {
            const currentHeader = stickyHeaders[i];
            const nextHeader = stickyHeaders[i + 1];
            
            // Check if headers are overlapping
            const currentRect = currentHeader.getBoundingClientRect();
            const nextRect = nextHeader.getBoundingClientRect();
            
            // Consider any following header as interacting
            // This ensures we always close any gap between headers
            const approaching = true;
            
            if (approaching) {
              // Calculate the gap or overlap between headers
              const gap = nextRect.top - currentRect.bottom;
              
              // If there's a gap (positive value), push the current header down to close it
              // If there's overlap (negative value), push the current header up by that amount
              const pushDistance = gap <= 0 ? -gap : 0;
              
              // Force the headers to be flush against each other
              if (gap > 0 && gap < 20) {  // If there's a small gap (<20px)
                // Push the next header up to meet this one
                nextHeader.style.transform = `translateY(-${gap}px)`;
              }
              
              // Apply transform to move it up precisely the amount of overlap
              currentHeader.style.transform = `translateY(-${pushDistance}px)`;
              
              // Calculate how far along the pushing process we are (0 to 1)
              const progress = Math.min(1, pushDistance / currentHeader.offsetHeight);
              
              // Fade out gradually based on how much it's being pushed
              // Start fading immediately with any overlap
              if (pushDistance > 0) {
                const opacity = Math.max(0, 1 - progress);
                currentHeader.style.opacity = opacity.toString();
              } else {
                // Not yet overlapping
                currentHeader.style.opacity = '1';
              }
            } else {
              // Reset transform and opacity when headers are not overlapping
              currentHeader.style.transform = 'translateY(0)';
              currentHeader.style.opacity = '1';
            }
          }
        }
        
        // Find the current visible category - only update if not during a manual topic selection
        // Check if any scroll was initiated by a topic selection
        const isManualScroll = document.body.hasAttribute('data-manual-scroll');
        
        // Check if this is a manual topic selection (from the filter)
        const isManualTopicSelect = document.body.hasAttribute('data-manual-topic-select');
        
        if (!isManualScroll && !isManualTopicSelect) {
          for (let i = 0; i < categories.length; i++) {
            const category = categories[i];
            const element = document.getElementById(`category-${category.uuid}`);
            
            if (element) {
              const { top, bottom } = element.getBoundingClientRect();
              const headerHeight = 60; // Height of sticky header
              
              if (top <= headerHeight && bottom >= headerHeight) {
                if (selectedCategoryId !== category.uuid) {
                  setSelectedCategoryId(category.uuid);
                  
                  // Always update the topic menu selection based on the current category
                  if (category.topics_uuid && category.topics_uuid !== selectedTopicId) {
                    setSelectedTopicId(category.topics_uuid);
                  }
                }
                break;
              }
            }
          }
        } else if (isManualScroll) {
          // Remove the attribute after processing this scroll event
          document.body.removeAttribute('data-manual-scroll');
        }
        
        setScrolling(false);
      });
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [shouldFetchHierarchicalData, categories, selectedTopicId, selectedCategoryId, scrolling, scrollToTop]);
  
  // When a topic is selected, ensure its categories are loaded
  useEffect(() => {
    if (!selectedTopicId || !shouldFetchHierarchicalData || categories.length === 0) return;
    
    // Update selectedTopicId when externalSelectedTopicId changes
    if (externalSelectedTopicId !== selectedTopicId) {
      if (externalSelectedTopicId) {
        setSelectedTopicId(externalSelectedTopicId);
      }
    }
    
    // Find the categories for this topic
    const topicCategories = categories.filter(cat => cat.topics_uuid === selectedTopicId);
    if (topicCategories.length === 0) return;
    
    // Calculate the total categories that come before this topic's categories
    let categoriesBeforeTopic = 0;
    for (const topic of topics) {
      if (topic.uuid === selectedTopicId) break;
      categoriesBeforeTopic += categories.filter(cat => cat.topics_uuid === topic.uuid).length;
    }
    
    // Check if we need to load more categories to show at least the first 3 from this topic
    const categoriesNeededToShow = categoriesBeforeTopic + Math.min(topicCategories.length, 3);
    if (categoriesNeededToShow > loadedCategories) {
      setLoadedCategories(categoriesNeededToShow);
    }
  }, [selectedTopicId, shouldFetchHierarchicalData, categories, topics, loadedCategories, externalSelectedTopicId]);

  // This effect fetches hierarchical data (topics, categories, products)
  useEffect(() => {
    if (!shouldFetchHierarchicalData) {
      // Don't load hierarchical data if we're showing a specific category
      return;
    }
    
    setLoading(true);
    
    // Function to fetch topics
    const fetchTopics = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/topics`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}` }
        });
        const data = await response.json();
        setTopics(data);
        return data;
      } catch (error) {
        console.error("Error fetching topics:", error);
        return [];
      }
    };
    
    // Function to fetch categories
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/categories`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}` }
        });
        const data = await response.json();
        setCategories(data);
        return data;
      } catch (error) {
        console.error("Error fetching categories:", error);
        return [];
      }
    };
    
    // Function to fetch products for a category using direct SQL-like approach
    const fetchProductsForCategory = async (catId) => {
      try {
        // Make API call to a special endpoint that returns all products for a category
        // This is an approach that bypasses pagination limits
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/categories/${catId}/all`, 
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}` }
          }
        );
        
        if (!response.ok) {
          // If the special endpoint fails, fallback to standard endpoint with large page size
          console.log(`Falling back to paginated endpoint for category ${catId}`);
          return fetchProductsPaginated(catId);
        }
        
        const data = await response.json();
        console.log(`Fetched ${data.length} products for category ${catId}`);
        return data;
      } catch (error) {
        console.error(`Error fetching products for category ${catId}:`, error);
        // Fall back to paginated approach
        return fetchProductsPaginated(catId);
      }
    };
    
    // Fallback function to fetch products with pagination
    const fetchProductsPaginated = async (catId, page = 1, limit = 1000) => {
      try {
        console.log(`Fetching page ${page} for category ${catId} with limit ${limit}`);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/categories/${catId}/products?page=${page}&perPage=${limit}`, 
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}` }
          }
        );
        const data = await response.json();
        
        // Check if there are more pages to fetch
        if (data && data.length === limit) {
          // Recursively fetch the next page and combine results
          const nextPageData = await fetchProductsPaginated(catId, page + 1, limit);
          return [...data, ...nextPageData];
        }
        
        return data;
      } catch (error) {
        console.error(`Error fetching paginated products for category ${catId}:`, error);
        return [];
      }
    };
    
    // Main data loading function
    const loadAllData = async () => {
      const topics = await fetchTopics();
      const categories = await fetchCategories();
      
      // Show loading indicator
      setLoading(true);
      
      // Fetch products for all categories
      const products = {};
      
      // Get total count for progress tracking
      let loadedCount = 0;
      let totalLoaded = 0;
      
      // Process categories in smaller batches to prevent overwhelming the browser
      const batchSize = 5;
      for (let i = 0; i < categories.length; i += batchSize) {
        const batch = categories.slice(i, i + batchSize);
        
        // Use Promise.all to fetch multiple categories in parallel
        const batchResults = await Promise.all(
          batch.map(async (category) => {
            const categoryProducts = await fetchProductsForCategory(category.uuid);
            loadedCount += categoryProducts.length;
            totalLoaded += 1;
            
            // Update loading status periodically
            if (totalLoaded % batchSize === 0) {
              console.log(`Loaded ${totalLoaded}/${categories.length} categories, ${loadedCount} products so far`);
            }
            
            return { categoryId: category.uuid, products: categoryProducts };
          })
        );
        
        // Add batch results to products object
        batchResults.forEach(result => {
          products[result.categoryId] = result.products;
        });
      }
      
      console.log(`Finished loading ${loadedCount} products from ${categories.length} categories`);
      setCategoryProducts(products);
      
      // Set default selections if topics and categories exist
      if (topics.length > 0) {
        // Use external selected topic ID if provided, otherwise default to first topic
        const topicToUse = externalSelectedTopicId || topics[0].uuid;
        setSelectedTopicId(topicToUse);
        
        // Find categories for the selected topic
        const topicCategories = categories.filter(cat => cat.topics_uuid === topicToUse);
        if (topicCategories.length > 0) {
          setSelectedCategoryId(topicCategories[0].uuid);
          
          // Set initial visible products
          updateVisibleProducts(topicCategories[0].uuid, products, 1);
          
          // If an external topic ID was provided, scroll to that section after a delay
          if (externalSelectedTopicId) {
            setTimeout(() => {
              const firstCategoryId = topicCategories[0].uuid;
              const categoryElement = document.getElementById(`category-${firstCategoryId}`);
              if (categoryElement) {
                // Add flag to prevent scroll detection from changing selected topic
                document.body.setAttribute('data-manual-topic-select', 'true');
                
                // Scroll to element with offset
                const topOffset = categoryElement.getBoundingClientRect().top + window.scrollY - 80;
                window.scrollTo({ top: topOffset, behavior: 'smooth' });
                
                // Remove flag after scroll animation completes
                setTimeout(() => {
                  document.body.removeAttribute('data-manual-topic-select');
                }, 800);
              }
            }, 300);
          }
        }
      }
      
      setLoading(false);
    };
    
    loadAllData();
  }, [shouldFetchHierarchicalData, externalSelectedTopicId]);

  // Render products for a specific category
  if (categoryId) {
    if (isPending) {
      return (
        <div
          style={{
            margin: 0,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: 36,
          }}
        >
          Loading
        </div>
      );
    }

    if (error) return "An error has occurred: " + error.message;

    return (
      <List
        grid={{
          gutter: 16,
          xs: 2,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 4,
        }}
        className="categoriesContainer"
        dataSource={data || []}
        renderItem={(product) => <ProductItem product={product} categoryId={categoryId} isOpen={isOpen} />}
      />
    );
  }

  // Loading state for hierarchical view - only show full screen loader for initial load
  if (shouldFetchHierarchicalData && loading && totalShownProducts === 0) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        <Spin size="large" />
        <Text style={{ marginLeft: '10px' }}>Loading topics, categories and products...</Text>
      </div>
    );
  }

  // Render hierarchical view of topics, categories, and products
  if (shouldFetchHierarchicalData) {
    // Show a loading indicator overlay when loading more content (not initial load)
    const LoadingOverlay = loading && totalShownProducts > 0 ? (
      <div style={{ 
        position: 'fixed', 
        bottom: '20px', 
        left: '50%', 
        transform: 'translateX(-50%)',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        padding: '8px 16px',
        borderRadius: '20px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
        zIndex: 100,
        display: 'flex',
        alignItems: 'center'
      }}>
        <Spin size="small" />
        <Text style={{ marginLeft: '10px' }}>Loading more products...</Text>
      </div>
    ) : null;
    // Get categories for selected topic
    const currentTopicCategories = selectedTopicId 
      ? categories.filter(category => category.topics_uuid === selectedTopicId)
      : [];
    
    // Get current category and its products
    const currentCategory = categories.find(cat => cat.uuid === selectedCategoryId);
    const totalProducts = currentCategory 
      ? (categoryProducts[currentCategory.uuid] || []).length 
      : 0;
    
    return (
      <div className="topics-categories-products-container" style={{ padding: 0, margin: 0, width: '100%', position: 'relative' }}>
        {/* Loading Overlay */}
        {LoadingOverlay}
        
        {/* Back to Top Button */}
        {showBackToTop && (
          <div 
            onClick={scrollToTop}
            style={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              width: '40px',
              height: '40px',
              backgroundColor: '#1890ff',
              color: 'white',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
              zIndex: 100,
              transition: 'all 0.3s'
            }}
          >
            <svg viewBox="64 64 896 896" focusable="false" data-icon="arrow-up" width="1em" height="1em" fill="currentColor" aria-hidden="true">
              <path d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
            </svg>
          </div>
        )}
        
        <div style={{ width: '100%', margin: 0 }}>
          {/* Categories and Products - Full Width */}
          <div style={{ padding: 0, width: '100%' }}>
            {/* Products Grouped by Categories */}
            <div style={{ margin: 0, width: '100%' }}>
              {categories.length === 0 ? (
                <Empty description="No categories available" />
              ) : (
                <div>
                  {/* First, group categories by topic */}
                  {topics.map((topic, topicIndex) => {
                    // Get all categories for this topic
                    const topicCategories = categories.filter(category => category.topics_uuid === topic.uuid);
                    
                    // Determine which categories to display
                    let categoriesToDisplay = [];
                    let allCategoriesCount = 0;
                    
                    // Count all categories up to this topic
                    topics.forEach((t, idx) => {
                      if (idx < topicIndex) {
                        allCategoriesCount += categories.filter(cat => cat.topics_uuid === t.uuid).length;
                      }
                    });
                    
                    // For each topic, calculate which categories should be shown
                    if (allCategoriesCount < loadedCategories) {
                      // How many more categories we can show from this topic
                      const remainingSlots = loadedCategories - allCategoriesCount;
                      categoriesToDisplay = topicCategories.slice(0, remainingSlots);
                    }
                    
                    return (
                      <React.Fragment key={`topic-group-${topic.uuid}`}>
                        {/* For each topic, display its categories */}
                        {categoriesToDisplay.map((category, categoryIndex) => {
                          const categoryProductsData = categoryProducts[category.uuid] || [];
                          // Show all products in each category with no limit
                          const productsToShow = categoryProductsData.slice(0, PRODUCTS_PER_CATEGORY); // Show all products
                          
                          return (
                            <div key={category.uuid} id={`category-${category.uuid}`} style={{ 
                              marginBottom: '0px', // Remove bottom margin between categories
                              position: 'relative',
                              paddingTop: '0px', // Remove top padding
                              marginTop: '0px', // Remove top margin
                            }}>
                              {/* Sticky header for category */}
                              <div 
                                className="sticky-header-container"
                                style={{ 
                                  position: 'sticky',
                                  top: '0', // Stick to the top of the product container
                                  backgroundColor: '#ffffff',
                                  marginTop: '0', // Ensure no space above
                                  marginBottom: '0', // Ensure no space below
                                  padding: '0', // No padding
                                  // Use a lower z-index value (below 10) to ensure it doesn't overlay the site header
                                  // which typically has z-index: 10 or higher
                                  zIndex: 10 - categoryIndex, // Lower z-index so it doesn't interfere with the site header
                                  transition: 'transform 0.2s ease-out, opacity 0.15s ease-out', // Slightly slower transitions for visibility
                                  borderBottom: '1px solid rgba(0,0,0,0.08)',
                                  willChange: 'transform, opacity', // Optimize for animation
                                  height: '50px',  // Further increased height for two lines of text
                                  overflow: 'hidden', // Prevent content from overflowing during transitions
                                  // Box shadow only at the bottom
                                  boxShadow: '0 4px 6px -4px rgba(0,0,0,0.1)' 
                                }}
                              >
                                <div 
                                  className="sticky-header-content"
                                  style={{ 
                                    padding: '0 16px 2px', 
                                    backgroundColor: '#ffffff',
                                    borderRadius: '0',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start', // Align items to the left
                                    justifyContent: 'center',
                                    paddingBottom: '0' // Remove bottom padding
                                  }}
                                >
                                  <Text strong style={{ fontSize: '16px', lineHeight: '1.2', marginBottom: '2px' }}>
                                    {category.name}
                                  </Text>
                                  <Text type="secondary" style={{ fontSize: '12px', lineHeight: '1', margin: 0 }}>
                                    {categoryProductsData.length} products
                                  </Text>
                                </div>
                              </div>
                              <div style={{ padding: '0', marginBottom: '40px', marginTop: '12px' }}>
                              
                              {productsToShow.length === 0 ? (
                                <Empty description="No products in this category" style={{ margin: '16px 0' }} />
                              ) : (
                                <>
                                  <List
                                    id={`products-${category.uuid}`}
                                    className="product-list-container"
                                    grid={{
                                      gutter: 12,
                                      xs: 1,
                                      sm: 2,
                                      md: 3,
                                      lg: 3,
                                      xl: 3,
                                      xxl: 4,
                                    }}
                                    dataSource={productsToShow}
                                    renderItem={(product) => (
                                      <ProductItem
                                        product={product}
                                        categoryId={category.uuid}
                                        isOpen={isOpen}
                                      />
                                    )}
                                  />
                                  
                                  {/* No "View All" button needed since all products are shown */}
                                </>
                              )}
                              
                              {/* Removed View More button */}
                              </div>
                            </div>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                  {/* Load More Categories Button */}
                  {loadedCategories < categories.length && (
                    <div 
                      ref={loadMoreRef}
                      style={{ 
                        textAlign: 'center', 
                        padding: '20px 0', 
                        margin: '20px 0'
                      }}
                    >
                      <Button 
                        type="primary"
                        onClick={handleLoadMore}
                        loading={loading}
                        icon={!loading ? <DownOutlined /> : null}
                      >
                        {loading ? 'Loading...' : `Load More Categories (${Math.min(10, categories.length - loadedCategories)} more)`}
                      </Button>
                      <div style={{ marginTop: '8px' }}>
                        <Text type="secondary">
                          Showing {Math.min(loadedCategories, categories.length)} of {categories.length} categories
                        </Text>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Default case - nothing to show
  return null;
};

export default ProductList;