import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Button, 
  Modal, 
  Form, 
  Input, 
  message, 
  Card, 
  Space, 
  Tag, 
  Progress, 
  Tabs,
  Row,
  Col,
  List,
  Radio,
  Breadcrumb,
  Divider
} from 'antd';
import DatabaseTestView from '../components/DatabaseTestView';
import BibiLogo from '../../assets/images/BibiLogo';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { 
  RightOutlined, 
  PlusOutlined, 
  CheckCircleOutlined, 
  ClockCircleOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  ExperimentOutlined,
  ToolOutlined,
  BuildOutlined,
  RightCircleOutlined,
  HomeOutlined,
  ShareAltOutlined
} from '@ant-design/icons';
import '../../assets/css/plans.css';
import ShareModal from '../components/modals/ShareModal';
import { 
  readUserLists, 
  readSuggestedLists, 
  generateListSuggestions,
  acceptListSuggestion,
  rejectListSuggestion,
  createList
} from '../redux/lists/actions';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;

// Additional dummy plans data
const allDummyPlans = [
  {
    uuid: 'plan-1',
    name: 'Prepare for Birth',
    description: 'Essential preparations for labor and delivery',
    category: 'Health',
    stats: {
      total_items: 8,
      completed_items: 3,
      completion_percentage: 38,
      sampling_items: 2,
      training_items: 3,
      building_items: 3
    },
    modules: ['Healthcare', 'Classes', 'Wellness']
  },
  {
    uuid: 'plan-2',
    name: 'Childcare Planning',
    description: 'Research and arrange childcare options',
    category: 'Childcare',
    stats: {
      total_items: 6,
      completed_items: 1,
      completion_percentage: 17,
      sampling_items: 2,
      training_items: 1,
      building_items: 3
    },
    modules: ['Research', 'Interviews', 'Planning']
  },
  {
    uuid: 'plan-3',
    name: 'Financial Preparation',
    description: 'Budget and plan for baby expenses',
    category: 'Financial',
    stats: {
      total_items: 7,
      completed_items: 0,
      completion_percentage: 0,
      sampling_items: 3,
      training_items: 2,
      building_items: 2
    },
    modules: ['Insurance', 'Savings', 'Budget']
  },
  {
    uuid: 'plan-4',
    name: 'Maternity Leave',
    description: 'Plan for time off work and transition',
    category: 'Work',
    stats: {
      total_items: 5,
      completed_items: 2,
      completion_percentage: 40,
      sampling_items: 1,
      training_items: 2,
      building_items: 2
    },
    modules: ['HR', 'Planning', 'Documentation']
  },
  {
    uuid: 'plan-5',
    name: 'Baby Gear Essentials',
    description: 'Must-have products for your newborn',
    category: 'Products',
    stats: {
      total_items: 12,
      completed_items: 5,
      completion_percentage: 42,
      sampling_items: 4,
      training_items: 3,
      building_items: 5
    },
    modules: ['Shopping', 'Research', 'Reviews']
  },
  {
    uuid: 'plan-6',
    name: 'Nursery Setup',
    description: 'Create a safe and cozy nursery',
    category: 'Products',
    stats: {
      total_items: 9,
      completed_items: 4,
      completion_percentage: 44,
      sampling_items: 3,
      training_items: 2,
      building_items: 4
    },
    modules: ['Furniture', 'Decor', 'Organization']
  },
  {
    uuid: 'plan-7',
    name: 'Birth Plan Creation',
    description: 'Document your preferences for delivery',
    category: 'Health',
    stats: {
      total_items: 7,
      completed_items: 3,
      completion_percentage: 43,
      sampling_items: 2,
      training_items: 3,
      building_items: 2
    },
    modules: ['Preferences', 'Research', 'Communication']
  },
  {
    uuid: 'plan-8',
    name: 'Breastfeeding Prep',
    description: 'Prepare for successful breastfeeding',
    category: 'Health',
    stats: {
      total_items: 8,
      completed_items: 2,
      completion_percentage: 25,
      sampling_items: 3,
      training_items: 2,
      building_items: 3
    },
    modules: ['Education', 'Supplies', 'Support']
  },
  {
    uuid: 'plan-9',
    name: 'Health Insurance Review',
    description: 'Ensure coverage for pregnancy and baby',
    category: 'Financial',
    stats: {
      total_items: 6,
      completed_items: 4,
      completion_percentage: 67,
      sampling_items: 1,
      training_items: 2,
      building_items: 3
    },
    modules: ['Coverage', 'Claims', 'Planning']
  },
  {
    uuid: 'plan-10',
    name: 'Daycare Selection',
    description: 'Research and choose childcare options',
    category: 'Childcare',
    stats: {
      total_items: 9,
      completed_items: 3,
      completion_percentage: 33,
      sampling_items: 4,
      training_items: 2,
      building_items: 3
    },
    modules: ['Interviews', 'Tours', 'Registration']
  },
  {
    uuid: 'plan-11',
    name: 'Car Seat Installation',
    description: 'Prepare for safe travel with baby',
    category: 'Products',
    stats: {
      total_items: 5,
      completed_items: 1,
      completion_percentage: 20,
      sampling_items: 2,
      training_items: 1,
      building_items: 2
    },
    modules: ['Safety', 'Installation', 'Inspection']
  },
  {
    uuid: 'plan-12',
    name: 'Pediatrician Selection',
    description: 'Find the right doctor for your baby',
    category: 'Health',
    stats: {
      total_items: 6,
      completed_items: 3,
      completion_percentage: 50,
      sampling_items: 2,
      training_items: 2,
      building_items: 2
    },
    modules: ['Research', 'Interviews', 'Selection']
  },
  {
    uuid: 'plan-13',
    name: 'Baby Budget Planning',
    description: 'Prepare financially for baby expenses',
    category: 'Financial',
    stats: {
      total_items: 8,
      completed_items: 2,
      completion_percentage: 25,
      sampling_items: 3,
      training_items: 2,
      building_items: 3
    },
    modules: ['Budgeting', 'Savings', 'Planning']
  },
  {
    uuid: 'plan-14',
    name: 'Baby Registry Creation',
    description: 'Build a thoughtful registry for baby',
    category: 'Products',
    stats: {
      total_items: 10,
      completed_items: 7,
      completion_percentage: 70,
      sampling_items: 3,
      training_items: 2,
      building_items: 5
    },
    modules: ['Essentials', 'Nice-to-haves', 'Organization']
  },
  {
    uuid: 'plan-15',
    name: 'Work Transition Plan',
    description: 'Prepare for leave and return to work',
    category: 'Work',
    stats: {
      total_items: 7,
      completed_items: 3,
      completion_percentage: 43,
      sampling_items: 2,
      training_items: 3,
      building_items: 2
    },
    modules: ['Handoff', 'Communication', 'Planning']
  },
  {
    uuid: 'plan-16',
    name: 'Nanny Search',
    description: 'Find and hire the perfect nanny',
    category: 'Childcare',
    stats: {
      total_items: 8,
      completed_items: 2,
      completion_percentage: 25,
      sampling_items: 3,
      training_items: 2,
      building_items: 3
    },
    modules: ['Search', 'Interviews', 'Hiring']
  },
  {
    uuid: 'plan-17',
    name: 'Diapering Strategy',
    description: 'Choose between cloth and disposable',
    category: 'Products',
    stats: {
      total_items: 6,
      completed_items: 4,
      completion_percentage: 67,
      sampling_items: 2,
      training_items: 1,
      building_items: 3
    },
    modules: ['Research', 'Supplies', 'Setup']
  },
  {
    uuid: 'plan-18',
    name: 'Flexible Spending Setup',
    description: 'Optimize tax benefits for childcare',
    category: 'Financial',
    stats: {
      total_items: 5,
      completed_items: 1,
      completion_percentage: 20,
      sampling_items: 2,
      training_items: 1,
      building_items: 2
    },
    modules: ['Enrollment', 'Planning', 'Documentation']
  },
  {
    uuid: 'plan-19',
    name: 'Freezer Meal Prep',
    description: 'Stock your freezer for postpartum',
    category: 'Health',
    stats: {
      total_items: 7,
      completed_items: 5,
      completion_percentage: 71,
      sampling_items: 2,
      training_items: 2,
      building_items: 3
    },
    modules: ['Planning', 'Shopping', 'Cooking']
  },
  {
    uuid: 'plan-20',
    name: 'Return to Work Childcare',
    description: 'Arrange childcare for your return',
    category: 'Work',
    stats: {
      total_items: 8,
      completed_items: 3,
      completion_percentage: 38,
      sampling_items: 3,
      training_items: 2,
      building_items: 3
    },
    modules: ['Planning', 'Scheduling', 'Backup Plans']
  },
  {
    uuid: 'plan-21',
    name: 'Baby Proofing Home',
    description: 'Make your home safe for baby',
    category: 'Products',
    stats: {
      total_items: 9,
      completed_items: 2,
      completion_percentage: 22,
      sampling_items: 3,
      training_items: 2,
      building_items: 4
    },
    modules: ['Safety', 'Installation', 'Products']
  },
  {
    uuid: 'plan-22',
    name: 'Postpartum Support',
    description: 'Line up help for after baby arrives',
    category: 'Health',
    stats: {
      total_items: 6,
      completed_items: 4,
      completion_percentage: 67,
      sampling_items: 2,
      training_items: 1,
      building_items: 3
    },
    modules: ['Family', 'Professional Help', 'Self-care']
  },
  {
    uuid: 'plan-23',
    name: "Baby's First Year Budget",
    description: 'Financial planning for the first year',
    category: 'Financial',
    stats: {
      total_items: 10,
      completed_items: 3,
      completion_percentage: 30,
      sampling_items: 4,
      training_items: 3,
      building_items: 3
    },
    modules: ['Expenses', 'Savings', 'Adjustments']
  },
  {
    uuid: 'plan-24',
    name: 'Family Care Arrangements',
    description: 'Setup care for older children',
    category: 'Childcare',
    stats: {
      total_items: 5,
      completed_items: 3,
      completion_percentage: 60,
      sampling_items: 1,
      training_items: 2,
      building_items: 2
    },
    modules: ['Scheduling', 'Backup', 'Communication']
  }
];

// Sample plans data for testing
const sampleUserPlans = [
  {
    uuid: '123e4567-e89b-12d3-a456-426614174000',
    name: 'Get Ready for Baby',
    description: 'Prepare everything for the baby\'s arrival',
    stats: {
      total_items: 5,
      completed_items: 2,
      completion_percentage: 40,
      sampling_items: 2,
      training_items: 1,
      building_items: 3
    },
    modules: ['Nursery', 'Hospital', 'Clothing']
  },
  {
    uuid: '123e4567-e89b-12d3-a456-426614174001',
    name: 'Build Nursery',
    description: 'Create a cozy and functional nursery for baby',
    stats: {
      total_items: 3,
      completed_items: 1,
      completion_percentage: 33,
      sampling_items: 1,
      training_items: 0,
      building_items: 2
    },
    modules: ['Research', 'Plan', 'Setup']
  }
];

// Sample suggested plans
const sampleSuggestedPlans = [
  {
    uuid: 'sugg-1',
    name: 'First Trimester Checklist',
    description: 'Essential tasks for your first trimester',
    reason: 'Based on your due date',
    modules: ['Healthcare', 'Wellness', 'Planning'],
    accepted: false
  },
  {
    uuid: 'sugg-2',
    name: 'Newborn Essentials',
    description: 'Everything you need for a newborn',
    reason: 'Popular for first-time parents',
    modules: ['Shopping', 'Preparation'],
    accepted: false
  }
];

// Initial progress stats are now managed in the Plan component state

const Plans = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [selectedPlanForShare, setSelectedPlanForShare] = useState(null);
  const [testModalVisible, setTestModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [progressStats, setProgressStats] = useState({
    plans_completed: 0,
    plans_in_progress: 0,
    plans_not_started: 0,
    total_items: 0,
    completed_items: 0,
    completion_percentage: 0
  });
  
  // Get data from Redux store
  const { 
    userLists, 
    readUserListsInProcess,
    suggestedLists, 
    readSuggestedListsInProcess,
    generateListSuggestionsInProcess,
    createListInProcess
  } = useSelector(state => state.lists);
  
  // Use userLists from Redux when available, otherwise use sample data
  const plans = userLists && userLists.length > 0 ? userLists : sampleUserPlans;
  // Use suggestedLists from Redux when available, otherwise use sample data
  const suggestedPlans = suggestedLists && suggestedLists.length > 0 ? suggestedLists : sampleSuggestedPlans;
  
  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleSubmit = () => {
    form.validateFields()
      .then(values => {
        // Process modules if provided
        let modules = ['General'];
        if (values.modules) {
          modules = values.modules.split(',').map(m => m.trim()).filter(m => m);
          if (modules.length === 0) modules = ['General'];
        }
        
        // Create list data for API
        const listData = {
          name: values.name,
          type: 'plan', // Using type = plan to identify this as a plan
          permissions: 0,  // Default permissions
          description: values.description || ''
        };
        
        // Create the list via API
        dispatch(createList(listData))
          .then(response => {
            message.success('Added list to your plan: ' + values.name);
            setIsModalVisible(false);
            form.resetFields();
            
            // Detailed logging to debug response
            console.log('Plan creation response in component:', response);
            
            // Extract UUID from the response
            if (response && response.uuid) {
              console.log(`Found UUID in response: ${response.uuid}`);
                
              // Always refresh the lists before navigating
              dispatch(readUserLists())
                .then(() => {
                  // Use a known mock UUID that exists in the PlanDetails component instead of the real one
                  // This is a temporary workaround until we have a proper API endpoint to fetch list details
                  console.log(`Navigating to plan details with mock UUID instead of ${response.uuid}`);
                  const mockUuid = '123e4567-e89b-12d3-a456-426614174000'; // Get Ready for Baby mock
                  history.push(`/plan/${mockUuid}`);
                })
                .catch((error) => {
                  console.error('Error refreshing lists:', error);
                  // If there's an error refreshing the data, still try to navigate
                  console.warn('Error refreshing lists, still attempting navigation');
                  const mockUuid = '123e4567-e89b-12d3-a456-426614174000'; // Get Ready for Baby mock
                  history.push(`/plan/${mockUuid}`);
                });
            } else {
              console.warn('No UUID in response, staying on plans page');
              // Refresh the lists anyway
              dispatch(readUserLists());
              history.push('/plan');
            }
          })
          .catch(error => {
            message.error('Failed to create list: ' + error.message);
          });
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };
  
  const handleViewPlan = (planId) => {
    // Find the plan to pass to the details view
    const plan = plans.find(p => p.uuid === planId);
    
    if (plan) {
      // Pass the plan data via location state with an onPlanUpdate callback
      history.push({
        pathname: `/plan/${planId}`,
        state: { 
          plan,
          onPlanUpdate: (updatedPlan) => {
            // Instead of directly updating the local state, 
            // in a real implementation we would call the API to update the plan
            // and then refresh the lists
            
            // Just update progress stats for the UI
            const updatedPlans = plans.map(p => 
              p.uuid === updatedPlan.uuid ? updatedPlan : p
            );
            updateProgressStats(updatedPlans);
            
            // Refresh the user's lists
            dispatch(readUserLists());
          }
        }
      });
    } else {
      history.push(`/plan/${planId}`);
    }
  };

  const handleAcceptSuggestedPlan = (planId) => {
    // Show loading indicator
    const loadingMessage = message.loading(`Adding to your plan...`, 0);
    
    // Accept the suggested plan via the API
    dispatch(acceptListSuggestion(planId))
      .then(response => {
        loadingMessage(); // Close loading message
        
        // Find the suggested plan in our local array
        const suggestedPlan = suggestedLists.find(plan => plan.uuid === planId);
        
        if (suggestedPlan) {
          message.success(`Added ${suggestedPlan.name} to your plan`, 2);
          
          // Provide a slight delay to show the success message before navigation
          setTimeout(() => {
            // Navigate to the plan details view - use a known valid mock UUID
            // We use '123e4567-e89b-12d3-a456-426614174000' since that ID exists in the mockPlans object
            history.push({
              pathname: `/plan/123e4567-e89b-12d3-a456-426614174000`,
            });
          }, 500);
        } else {
          // Navigate to the plan details view even without a plan name
          setTimeout(() => {
            // Use a known valid mock UUID
            history.push({
              pathname: `/plan/123e4567-e89b-12d3-a456-426614174000`,
            });
          }, 500);
        }
      })
      .catch(error => {
        loadingMessage(); // Close loading message
        message.error('Failed to add suggested plan: ' + error.message);
      });
  };

  const handleRejectSuggestedPlan = (planId) => {
    // Reject the suggested plan via the API
    dispatch(rejectListSuggestion(planId));
    
    message.info('Suggestion removed');
  };

  const handleDeletePlan = (e, planId) => {
    e.stopPropagation();
    
    // In a real implementation, we would call an API to delete the plan
    // For now, just show a success message
    message.success('List removed from plan');
    
    // Refresh the user's lists
    dispatch(readUserLists());
  };
  
  // Handle sharing a plan
  const handleSharePlan = (e, plan) => {
    e.stopPropagation();
    setSelectedPlanForShare(plan);
    setShareModalVisible(true);
  };

  // Load data when component mounts
  useEffect(() => {
    // Load user lists and suggested lists from API
    dispatch(readUserLists());
    dispatch(readSuggestedLists());
    
    // Generate suggestions if there are none
    if (!suggestedLists || suggestedLists.length === 0) {
      dispatch(generateListSuggestions());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // Update progress stats when plans change
  useEffect(() => {
    updateProgressStats(plans);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans.length]);
  
  // Function to update progress stats based on plans data
  const updateProgressStats = (plansList) => {
    let completed = 0;
    let inProgress = 0;
    let notStarted = 0;
    let totalItems = 0;
    let completedItems = 0;
    
    plansList.forEach(p => {
      totalItems += p.stats.total_items;
      completedItems += p.stats.completed_items;
      
      if (p.stats.completion_percentage === 100) {
        completed++;
      } else if (p.stats.completion_percentage > 0) {
        inProgress++;
      } else {
        notStarted++;
      }
    });
    
    const completionPercentage = totalItems > 0 
      ? Math.floor((completedItems / totalItems) * 100)
      : 0;
      
    setProgressStats({
      plans_completed: completed,
      plans_in_progress: inProgress,
      plans_not_started: notStarted,
      total_items: totalItems,
      completed_items: completedItems,
      completion_percentage: completionPercentage
    });
  };

  const renderPlanCard = (plan, showAddButton = false) => {
    // Check if this is a user plan (either Get Ready for Baby or Build Nursery)
    const isUserPlan = plan.uuid === '123e4567-e89b-12d3-a456-426614174000' || 
                       plan.uuid === '123e4567-e89b-12d3-a456-426614174001';
    
    // Log plan info for debugging
    console.log(`Rendering plan: ${plan.name}, UUID: ${plan.uuid}, isUserPlan: ${isUserPlan}, showAddButton: ${showAddButton}`);
    
    return (
    <Card 
      key={plan.uuid}
      hoverable={!showAddButton}
      onClick={() => !showAddButton && handleViewPlan(plan.uuid)}
      className="plan-card"
      bodyStyle={{ padding: 0 }}
    >      
      <div className="plan-content">
        <div className="plan-info">
          <Title level={4} style={{ margin: 0, marginBottom: '4px', fontSize: '16px' }}>{plan.name}</Title>
          
          {plan.description && (
            <Paragraph ellipsis={{ rows: 1 }} style={{ marginBottom: '6px', fontSize: '13px' }}>
              {plan.description}
            </Paragraph>
          )}
          
          <div className="plan-meta">
            <div className="plan-modules">
              {plan.modules.map((module, idx) => (
                <Tag key={idx} style={{ marginRight: 0, fontSize: '11px', padding: '0 6px' }}>
                  {module}
                </Tag>
              ))}
            </div>
            
            <div className="plan-stats-group">
              <div className="plan-progress-row">
                <Text type="secondary" className="plan-progress-text">
                  {plan.stats.completed_items}/{plan.stats.total_items} items
                </Text>
                <div className="plan-progress-mini">
                  <div 
                    className={`plan-progress-mini-bar ${
                      (plan.stats.completion_percentage || 0) > 66 ? 'plan-progress-high' : 
                      (plan.stats.completion_percentage || 0) > 33 ? 'plan-progress-medium' : 'plan-progress-low'
                    }`}
                    style={{
                      width: `${plan.stats.completion_percentage || 0}%`
                    }}
                  />
                </div>
              </div>
              
              {plan.stats.total_items > 0 && (
                <div className="plan-stats-icons">
                  {plan.stats.sampling_items > 0 && (
                    <div className="plan-stat-icon">
                      <div className="plan-stat-icon-bubble" style={{ backgroundColor: '#5c9dff' }}>
                        <ExperimentOutlined style={{ fontSize: '12px' }} />
                      </div>
                      {plan.stats.sampling_items}
                    </div>
                  )}
                  {plan.stats.training_items > 0 && (
                    <div className="plan-stat-icon">
                      <div className="plan-stat-icon-bubble" style={{ backgroundColor: '#70b5ff' }}>
                        <ToolOutlined style={{ fontSize: '12px' }} />
                      </div>
                      {plan.stats.training_items}
                    </div>
                  )}
                  {plan.stats.building_items > 0 && (
                    <div className="plan-stat-icon">
                      <div className="plan-stat-icon-bubble" style={{ backgroundColor: '#9ac2ff' }}>
                        <BuildOutlined style={{ fontSize: '12px' }} />
                      </div>
                      {plan.stats.building_items}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        
        <div className="plan-actions">
          {isUserPlan ? (
            <>
              <ShareAltOutlined 
                className="plan-share-button" 
                onClick={(e) => handleSharePlan(e, plan)} 
                style={{ fontSize: '16px', marginRight: '12px', color: '#5c9dff' }} 
              />
              <DeleteOutlined 
                className="plan-delete-button" 
                onClick={(e) => handleDeletePlan(e, plan.uuid)} 
                style={{ marginRight: '12px' }}  
              />
              <Button 
                type="primary" 
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleViewPlan(plan.uuid);
                }}
                style={{ 
                  whiteSpace: 'nowrap', 
                  padding: '0 12px', 
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '13px',
                  borderRadius: '15px',
                  background: '#f5b897', // Dark peach pastelle color
                  borderColor: '#e89e7b',
                  boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                  marginRight: '10px'
                }}
              >
                Continue
              </Button>
              <RightCircleOutlined style={{ fontSize: '18px', color: '#f5b897' }} />
            </>
          ) : showAddButton ? (
            <Button 
              type="primary" 
              icon={<PlusOutlined />} 
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                message.success(`Added ${plan.name} to your plan`);
              }}
              style={{ 
                whiteSpace: 'nowrap', 
                padding: '0 12px', 
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '13px',
                borderRadius: '15px',
                background: '#5c9dff',
                borderColor: '#4b87e8',
                boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)'
              }}
            >
              Add to Plan
            </Button>
          ) : (
            <>
              <ShareAltOutlined 
                className="plan-share-button" 
                onClick={(e) => handleSharePlan(e, plan)} 
                style={{ fontSize: '16px', marginRight: '12px', color: '#5c9dff' }} 
              />
              <DeleteOutlined 
                className="plan-delete-button" 
                onClick={(e) => handleDeletePlan(e, plan.uuid)} 
              />
              <RightCircleOutlined style={{ fontSize: '18px', color: '#5c9dff' }} />
            </>
          )}
        </div>
      </div>
    </Card>
    );
  };

  const renderSuggestedPlanCard = (plan) => (
    <Card 
      key={plan.uuid}
      className={`suggested-plan-card ${plan.accepted ? 'accepted' : ''}`}
      hoverable={plan.accepted}
      onClick={() => plan.accepted ? handleViewPlan(plan.uuid) : null}
      bodyStyle={{ padding: '12px 16px' }}
    >
      <div className="plan-content">
        <div className="plan-info">
          <Title level={4} style={{ margin: 0, marginBottom: '4px', fontSize: '16px' }}>{plan.name}</Title>
          
          {plan.description && (
            <Paragraph ellipsis={{ rows: 1 }} style={{ marginBottom: '4px', fontSize: '13px' }}>
              {plan.description}
            </Paragraph>
          )}
          
          <div style={{ marginBottom: '6px' }}>
            <Text type="secondary" style={{ fontSize: '12px', display: 'block' }}>
              Suggested: {plan.reason}
            </Text>
          </div>
          
          <div className="plan-modules">
            {plan.modules.map((module, idx) => (
              <Tag key={idx} style={{ marginRight: 0, fontSize: '11px', padding: '0 6px' }}>
                {module}
              </Tag>
            ))}
          </div>
        </div>
        
        {plan.accepted ? (
          <div className="plan-actions">
            <ShareAltOutlined 
              className="plan-share-button" 
              onClick={(e) => handleSharePlan(e, plan)} 
              style={{ fontSize: '16px', marginRight: '12px', color: '#5c9dff' }} 
            />
            <DeleteOutlined 
              className="plan-delete-button" 
              onClick={(e) => handleDeletePlan(e, plan.uuid)} 
            />
            <RightCircleOutlined style={{ fontSize: '18px', color: '#5c9dff' }} />
          </div>
        ) : (
          <div className="suggested-plan-actions">
            <Button 
              type="primary" 
              icon={<CheckOutlined />} 
              onClick={() => handleAcceptSuggestedPlan(plan.uuid)}
              className="accept-button"
              size="small"
              style={{ 
                whiteSpace: 'nowrap', 
                padding: '0 12px', 
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '13px',
                borderRadius: '15px',
                background: '#5c9dff',
                borderColor: '#4b87e8',
                boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)'
              }}
            >
              Add to Plan
            </Button>
            <Button 
              danger 
              icon={<CloseOutlined />} 
              onClick={() => handleRejectSuggestedPlan(plan.uuid)}
              className="reject-button"
              size="small"
              style={{
                height: '30px',
                padding: '0 10px',
                fontSize: '13px',
                borderRadius: '15px',
                borderColor: '#d9e0ff',
                background: '#fff',
                color: '#5a73b2'
              }}
            >
              No
            </Button>
          </div>
        )}
      </div>
    </Card>
  );

  // Mock data for baby info - in a real app this would come from user profile
  const babyInfo = {
    name: "Emma",
    dueDate: "June 15, 2025",
    weekCount: 24,
    photoUrl: "../../assets/images/Ultrasound2.png"
  };

  return (
    <div className="plans-container">
      <div className="logo-container" style={{ 
        display: 'flex', 
        justifyContent: 'flex-start', 
        alignItems: 'center', 
        padding: '16px 24px',
        margin: 0,
        marginBottom: '15px',
        backgroundColor: 'white',
        borderBottom: 'none',
        boxShadow: '0 1px 4px rgba(0, 0, 0, 0.05), 0 0 0 0 rgba(0,0,0,0)',
        position: 'sticky',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 10,
        width: '100vw',
        marginLeft: 'calc(-50vw + 50%)',
        paddingLeft: 'calc(50vw - 50% + 24px)',
        paddingRight: 'calc(50vw - 50% + 24px)'
      }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BibiLogo width={200} height={28} />
          <Button 
            type="primary" 
            size="small"
            onClick={() => setTestModalVisible(true)}
            style={{ 
              marginLeft: '10px', 
              background: '#5c9dff',
              borderColor: '#4b87e8',
              borderRadius: '4px',
              fontSize: '12px'
            }}
          >
            Test
          </Button>
        </div>
      </div>
      
      <div className="baby-profile-header" style={{ position: 'relative' }}>
        <div className="baby-profile-info" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="baby-photo-container">
              <img src={require("../../assets/images/Ultrasound2.png")} alt="Baby scan" className="baby-photo" />
            </div>
            <div className="baby-details">
              <Title level={2} style={{ fontSize: '24px', margin: 0 }}>Emma's Journey</Title>
              <div className="baby-stats">
                <span className="baby-stat-item">
                  <span className="baby-stat-label">Due: </span>
                  <span className="baby-stat-value">{babyInfo.dueDate}</span>
                </span>
                <span className="baby-stat-item">
                  <span className="baby-stat-label">Week: </span>
                  <span className="baby-stat-value">{babyInfo.weekCount}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="baby-journey-phase" style={{ position: 'absolute', right: '5px', top: '50%', transform: 'translateY(-50%)' }}>
          <div className="journey-phase-badge">Second Trimester</div>
        </div>
      </div>

      <div className="progress-overview">
        <Card className="progress-card" size="small" bordered={false}>
          <div className="journey-context">
            <div className="next-milestone">
              <div className="milestone-label">Next milestone:</div>
              <div className="milestone-value">Glucose Test <span className="milestone-when">(Week 26)</span></div>
            </div>
            <div className="upcoming-appointment">
              <div className="appointment-label">Upcoming:</div>
              <div className="appointment-value">OB-GYN Visit <span className="appointment-when">(May 15)</span></div>
            </div>
          </div>
          
          <Row gutter={[16, 16]} align="middle" style={{ marginTop: '12px' }}>
            <Col xs={24} sm={10} md={12} lg={14}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
                <Title level={5} style={{ margin: 0, marginRight: 10, fontSize: '16px' }}>Prep Progress: </Title>
                <Progress 
                  percent={progressStats.completion_percentage}
                  status="active"
                  strokeColor={{
                    '0%': '#5c9dff',
                    '100%': '#4b87e8',
                  }}
                  style={{ 
                    flex: 1, 
                    marginBottom: 0 
                  }}
                  trailColor="#edf2ff"
                />
              </div>
            </Col>
            <Col xs={8} sm={4} md={4} lg={3}>
              <div className="stat-compact">
                <CheckCircleOutlined style={{ color: '#5c9dff', marginRight: 5 }} />
                <span>{progressStats.plans_completed} Done</span>
              </div>
            </Col>
            <Col xs={8} sm={5} md={4} lg={3}>
              <div className="stat-compact">
                <ClockCircleOutlined style={{ color: '#70b5ff', marginRight: 5 }} />
                <span>{progressStats.plans_in_progress} In Progress</span>
              </div>
            </Col>
            <Col xs={8} sm={5} md={4} lg={4}>
              <div className="stat-compact">
                <StopOutlined style={{ color: '#9ac2ff', marginRight: 5 }} />
                <span>{progressStats.plans_not_started} Not Started</span>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
      
      <div className="plans-list">
        <div className="plan-title-section">
          <div className="section-title-with-icon">
            <i className="section-icon">📋</i>
            <Title level={4} style={{ fontSize: '16px', margin: 0 }}>Emma's Preparation Plan</Title>
          </div>
          <div>
            <Button 
              type="primary" 
              onClick={() => history.push('/plan/templates')}
              icon={<PlusOutlined />}
              size="middle"
              style={{ 
                whiteSpace: 'nowrap', 
                height: '36px', 
                display: 'flex', 
                alignItems: 'center',
                borderRadius: '18px',
                fontWeight: 500,
                boxShadow: '0 3px 0 rgba(76, 111, 255, 0.1)',
                background: '#5c9dff',
                borderColor: '#4b87e8',
                paddingLeft: '16px',
                paddingRight: '16px'
              }}
            >
              Add List to Plan
            </Button>
          </div>
        </div>
        {readUserListsInProcess ? (
          <div className="loading-container">
            <p>Loading plans...</p>
          </div>
        ) : plans && plans.length > 0 ? (
          <Space direction="vertical" size={12} style={{ width: '100%', display: 'flex' }}>
            {plans.map(renderPlanCard)}
          </Space>
        ) : (
          <div className="empty-plans">
            <Title level={4} className="empty-plans-title">No plans found</Title>
            <Paragraph className="empty-plans-description">
              Click the "Add List to Plan" button to add your first plan
            </Paragraph>
            <Button 
              type="primary" 
              onClick={() => history.push('/plan/templates')}
              icon={<PlusOutlined />}
              style={{ 
                marginTop: '16px',
                background: '#5c9dff',
                borderColor: '#4b87e8',
                borderRadius: '18px',
                height: '36px',
                boxShadow: '0 3px 0 rgba(76, 111, 255, 0.1)'
              }}
            >
              Add List to Plan
            </Button>
          </div>
        )}
        
      </div>
      
      
      <div className="suggested-plans-section">
        <div className="plan-title-section">
          <div className="section-title-with-icon">
            <i className="section-icon">💡</i>
            <Title level={4} style={{ fontSize: '16px', margin: 0 }}>Suggested for Emma</Title>
          </div>
        </div>
        {readSuggestedListsInProcess ? (
          <div className="loading-container">
            <p>Loading suggestions...</p>
          </div>
        ) : suggestedLists && suggestedLists.length > 0 ? (
          <Space direction="vertical" size={12} style={{ width: '100%', display: 'flex' }}>
            {suggestedLists.map(renderSuggestedPlanCard)}
          </Space>
        ) : (
          <div className="empty-plans">
            <Title level={4} className="empty-plans-title">No suggested plans</Title>
            <Paragraph className="empty-plans-description">
              Suggestions based on your follows and profile will appear here
            </Paragraph>
            <Button 
              type="primary" 
              onClick={() => {
                // Create a custom event to show chat drawer with context
                const event = new CustomEvent('showChatDrawer', { 
                  detail: { 
                    fromPlans: true,
                    forSuggestions: true,
                    babyName: "Emma", // Pass the baby name
                    activateProductExpert: true // Explicitly activate the product expert tab
                  }
                });
                
                // Dispatch the event to be caught by MainNavigation component
                window.dispatchEvent(event);
              }}
              loading={generateListSuggestionsInProcess}
              icon={<PlusOutlined />}
              style={{ 
                marginTop: '16px',
                background: '#5c9dff',
                borderColor: '#4b87e8',
                borderRadius: '18px',
                height: '36px',
                boxShadow: '0 3px 0 rgba(76, 111, 255, 0.1)'
              }}
            >
              Generate Suggestions
            </Button>
          </div>
        )}
      </div>
      
      
      {/* Add space at the bottom to prevent content being hidden by the navigation menu */}
      <div style={{ height: '120px' }}></div>
      
      {/* Share Modal */}
      {selectedPlanForShare && (
        <ShareModal
          visible={shareModalVisible}
          onCancel={() => setShareModalVisible(false)}
          itemType="plan"
          itemName={selectedPlanForShare.name}
          itemId={selectedPlanForShare.uuid}
        />
      )}
      
      {/* Database Test Modal */}
      <DatabaseTestView 
        visible={testModalVisible}
        onClose={() => setTestModalVisible(false)}
      />


      {/* Add list to Plan Modal - Custom */}
      <Modal
        title="Add List to Plan"
        open={isModalVisible}
        onCancel={handleCancel}
        onOk={handleSubmit}
        okText="Add List"
        okButtonProps={{ 
          style: { whiteSpace: 'normal', height: 'auto', padding: '6px 15px' },
          loading: createListInProcess
        }}
        width="100%"
        style={{ top: 0, padding: 0, height: '100vh' }}
        bodyStyle={{ height: 'calc(100vh - 110px)', overflow: 'auto' }}
      >
        <Form
          form={form}
          layout="vertical"
          name="plan_form"
        >
          <Form.Item
            name="name"
            label="Plan Name"
            rules={[{ required: true, message: 'Please enter a plan name' }]}
          >
            <Input placeholder="Enter plan name" />
          </Form.Item>
          
          <Form.Item
            name="description"
            label="Description"
          >
            <TextArea
              placeholder="Enter plan description"
              rows={4}
            />
          </Form.Item>
          
          <Form.Item
            name="modules"
            label="Modules (optional)"
            help="Enter module names separated by commas, e.g. 'Shopping, Research, Planning'"
          >
            <Input placeholder="e.g. Shopping, Research, Planning" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Plans;