import React from 'react';
import BibiLogo from '../../assets/images/BibiLogo';
import { useDispatch, useSelector } from 'react-redux';
import { message, Form, Input, Button, Row, Col } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { getEmail, register, login } from '../redux/authentication/actions';

const Register = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams();
  const [form] = Form.useForm();
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);
  
  const emailObject = useSelector(state => state.auth.email);
  const getEmailError = useSelector(state => state.auth.getEmailError);
  const registerError = useSelector(state => state.auth.registerError);
  const user = useSelector(state => state.auth.user);

  React.useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  React.useEffect(() => {
    dispatch(getEmail(token));
  }, [dispatch, token]);

  React.useEffect(() => {
    if (emailObject && emailObject.recipient) {
      form.setFieldsValue({ recipient: emailObject.recipient });
    }
  }, [emailObject, form]);

  React.useEffect(() => {
    if (getEmailError) {
      message.error(getEmailError, 10);
      history.push('/login');
    }
  }, [getEmailError, history]);

  React.useEffect(() => {
    if (registerError) {
      message.error(registerError, 10);
    }
  }, [registerError]);

  const handleSubmit = async (values) => {
    try {
      // Wait for registration to complete
      await dispatch(register(
        values.recipient,
        values.firstName,
        values.lastName,
        values.password,
        values.confirmPassword,
        token,
        'register'
      ));
      
      // Only attempt login if registration succeeded
      await dispatch(login(values.recipient, values.password));
      
      // Check if we have a user in Redux state before redirecting
      if (user) {
        history.push('/home');
      } else {
        message.error('Login failed after registration. Please try logging in.');
        history.push('/login');
      }
    } catch (error) {
      message.error(error.message || 'Registration failed. Please try again.');
    }
  };

  return (
    <Row
      justify="center"
      align="middle"
      style={{
        minHeight: isMobile ? 'calc(100vh - 72px)' : '70vh',
        background: '#FAFAFA'
      }}
    >
      <Col
        xs={22}
        sm={20}
        md={12}
        lg={8}
        style={{
          background: 'white',
          padding: '32px',
          borderRadius: '10px',
          boxShadow: '2px 2px 2px 2px #f2f2f2',
        }}
      >
        <div className="logo-container" style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          marginBottom: '24px', 
          marginTop: '16px'
        }}>
          <BibiLogo width={180} height={48} />
        </div>
        <div style={{
          fontSize: isMobile ? '24px' : '32px',
          fontWeight: '600',
          marginBottom: '24px',
          color: '#1F1F1F',
          textAlign: 'center'
        }}>
          Complete Your Registration
        </div>
        
        <p style={{
          fontSize: '16px',
          color: '#666666',
          margin: '0 0 24px 0',
          lineHeight: '1.4',
          textAlign: 'center'
        }}>
          Set up your account to get started
        </p>

        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item 
            name="recipient" 
            label="Email"
            style={{ marginBottom: '16px' }}
          >
            <Input 
              readOnly 
              style={{ height: '44px', borderRadius: '6px' }}
            />
          </Form.Item>

          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: 'Please enter your first name' }]}
            style={{ marginBottom: '16px' }}
          >
            <Input 
              prefix={<UserOutlined style={{ color: '#666666' }} />}
              placeholder="First Name"
              style={{ height: '44px', borderRadius: '6px' }}
            />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: 'Please enter your last name' }]}
            style={{ marginBottom: '16px' }}
          >
            <Input 
              prefix={<UserOutlined style={{ color: '#666666' }} />}
              placeholder="Last Name"
              style={{ height: '44px', borderRadius: '6px' }}
            />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              { required: true, message: 'Please choose a password' },
              { min: 8, message: 'Password must be at least 8 characters long' }
            ]}
            style={{ marginBottom: '16px' }}
          >
            <Input.Password 
              prefix={<LockOutlined style={{ color: '#666666' }} />}
              placeholder="Password"
              style={{ height: '44px', borderRadius: '6px' }}
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Please confirm your password' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The passwords do not match'));
                },
              }),
            ]}
            style={{ marginBottom: '24px' }}
          >
            <Input.Password 
              prefix={<LockOutlined style={{ color: '#666666' }} />}
              placeholder="Confirm Password"
              style={{ height: '44px', borderRadius: '6px' }}
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: '100%',
                height: '44px',
                background: '#6B4EFF',
                borderRadius: '6px',
                border: 'none',
                fontSize: '16px',
                fontWeight: '500',
              }}
            >
              Create Account
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default Register;