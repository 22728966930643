import React from "react";
import { List, Card, Image } from "antd";
import { useHistory } from "react-router-dom";
import FavoriteProductButton from "./FavoriteProductButton.js";

const ProductItem = ({ product, categoryId, isOpen }) => {
  const history = useHistory();
  
  // Handle click to navigate to BrandModelDetails
  const handleCardClick = () => {
    // In a real app, we would get the brandModelId from the product data
    // For this demo, we'll use a placeholder ID
    const brandModelId = product.brandModelId || "bm-123";
    
    // Find current topic and category from URL/state
    const topicId = document.querySelector(".ant-menu-vertical .ant-menu-item-selected")?.key;
    const topicName = document.querySelector(".ant-menu-vertical .ant-menu-item-selected")?.textContent?.split("(")[0]?.trim();
    const categoryId = document.querySelector(".ant-menu-horizontal .ant-menu-item-selected")?.key;
    const categoryName = document.querySelector(".ant-menu-horizontal .ant-menu-item-selected")?.textContent?.split("(")[0]?.trim();
    
    // Navigate to the brand model details page
    history.push(`/brandmodel/${brandModelId}`, {
      from: 'catalog',
      productData: product,
      topicId,
      topicName,
      categoryId,
      categoryName,
      brandModelData: {
        id: brandModelId,
        name: product.name,
        brand: product.brand || "Brand",
        model: product.model || "Model",
      }
    });
  };
  
  return (
    <List.Item>
      <Card
        key={product.uuid}
        hoverable
        className="productCards"
        title={product.name}
        cover={
          <div 
            onClick={handleCardClick}
            style={{ 
              cursor: 'pointer', 
              height: '160px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '12px'
            }}
          >
            {product.photo_url ? (
              <Image 
                src={product.photo_url} 
                alt={product.name}
                preview={false}
                style={{ maxHeight: '140px', maxWidth: '100%', objectFit: 'contain' }}
              />
            ) : (
              <div 
                style={{ 
                  height: '140px', 
                  width: '100%', 
                  background: '#f0f2f5',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#999'
                }}
              >
                {product.name}
              </div>
            )}
          </div>
        }
        onClick={handleCardClick}
        actions={[
          <FavoriteProductButton 
            categoryId={categoryId} 
            productId={product.uuid} 
            isFavorite={product.favorited} 
          />,
        ]}
      />
    </List.Item>
  );
};

export default ProductItem;
