import React, { useState, useEffect, useRef } from 'react';
import BibiLogo from '../../assets/images/BibiLogo';
import { Typography, Input, Button, Avatar, Spin, List, Card, Divider, Tag, Tabs, Modal } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { 
  SendOutlined, 
  RobotOutlined, 
  UserOutlined, 
  BulbOutlined, 
  ShoppingOutlined, 
  QuestionCircleOutlined,
  SmileOutlined,
  HeartOutlined,
  MedicineBoxOutlined,
  SafetyOutlined,
  ExperimentOutlined,
  AlertOutlined,
  DollarOutlined,
  BankOutlined,
  PieChartOutlined,
  CalculatorOutlined,
  UpOutlined,
  DownOutlined
} from '@ant-design/icons';
import useChatGPT from '../redux/chatgpt/hooks';
import '../../assets/css/components/chat.css';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;

// Function to create a transition overlay for smoother page transitions
const createTransitionOverlay = () => {
  const overlay = document.createElement('div');
  overlay.style.position = 'fixed';
  overlay.style.top = '0';
  overlay.style.left = '0';
  overlay.style.width = '100%';
  overlay.style.height = '100%';
  overlay.style.backgroundColor = 'rgba(92, 157, 255, 0.2)';
  overlay.style.zIndex = '9999';
  overlay.style.display = 'flex';
  overlay.style.justifyContent = 'center';
  overlay.style.alignItems = 'center';
  overlay.style.opacity = '0';
  overlay.style.transition = 'opacity 0.5s ease-in-out';
  
  // Add loading spinner
  const spinner = document.createElement('div');
  spinner.className = 'transition-spinner';
  spinner.style.border = '5px solid #f3f3f3';
  spinner.style.borderTop = '5px solid #5c9dff';
  spinner.style.borderRadius = '50%';
  spinner.style.width = '50px';
  spinner.style.height = '50px';
  spinner.style.animation = 'spin 1s linear infinite';
  overlay.appendChild(spinner);
  
  // Add keyframes for spinner animation
  const style = document.createElement('style');
  style.textContent = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    
    .typing-indicator {
      display: flex;
      align-items: center;
    }
    
    .typing-indicator .dot {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #5c9dff;
      margin-right: 4px;
      animation: typing-dot 1.4s infinite ease-in-out both;
    }
    
    .typing-indicator .dot:nth-child(2) {
      animation-delay: 0.2s;
    }
    
    .typing-indicator .dot:nth-child(3) {
      animation-delay: 0.4s;
    }
    
    @keyframes typing-dot {
      0%, 80%, 100% { transform: scale(0.8); opacity: 0.6; }
      40% { transform: scale(1.2); opacity: 1; }
    }
  `;
  document.head.appendChild(style);
  
  // Animate the overlay
  requestAnimationFrame(() => {
    overlay.style.opacity = '1';
  });
  
  // Remove overlay after transition
  setTimeout(() => {
    overlay.style.opacity = '0';
    setTimeout(() => {
      document.body.removeChild(overlay);
    }, 500);
  }, 1800);
  
  return overlay;
};

// Sample suggested questions for product expert
const productExpertQuestions = [
  "What items do I really need for a newborn?",
  "How do I choose the right stroller?",
  "Which bottles are best for breastfed babies?",
  "What should I look for in a car seat?",
  "When should I start buying baby items?",
  "Are expensive baby monitors worth it?",
];

// Sample suggested questions for DBT therapist
const therapistQuestions = [
  "How can I manage anxiety during pregnancy?",
  "What are some mindfulness techniques I can practice?",
  "How do I set healthy boundaries with family?",
  "I'm feeling overwhelmed, what DBT skills can help?",
  "Can you explain emotion regulation in DBT?",
  "How do I practice radical acceptance?",
];

// Sample suggested questions for pregnancy medical info
const medicalQuestions = [
  "What foods should I avoid during pregnancy?",
  "Is it normal to have cramping in early pregnancy?",
  "How much weight should I gain during pregnancy?",
  "What prenatal vitamins are most important?",
  "What symptoms indicate I should call my doctor?",
  "Are there safe medications for headaches during pregnancy?",
];

// Sample suggested questions for financial advisor
const financialQuestions = [
  "How should I budget for a new baby?",
  "What financial planning should I do before my baby arrives?",
  "How much does childcare typically cost?",
  "What tax benefits are available for new parents?",
  "Should I start a college fund right away?",
  "How do I balance saving for retirement and childcare costs?",
];

const Chat = ({ 
  inDrawer = false, 
  onClose = null,
  // Direct props for when used in drawer
  fromPlanDetails = false,
  forProductSuggestion = false,
  productName = "",
  babyName = "Emma",
  activateProductExpert = false
}) => {
  const history = useHistory();
  const location = useLocation();
  const [message, setMessage] = useState('');
  const [activeTab, setActiveTab] = useState('product-expert');
  const [productChatHistory, setProductChatHistory] = useState([]);
  const [therapistChatHistory, setTherapistChatHistory] = useState([]);
  const [medicalChatHistory, setMedicalChatHistory] = useState([]);
  const [financialChatHistory, setFinancialChatHistory] = useState([]);
  const [suggestionsPopupVisible, setSuggestionsPopupVisible] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questionVisible, setQuestionVisible] = useState(true);
  const [forSuggestions, setForSuggestions] = useState(false);
  
  // Check if we're coming from Plans for suggestions
  // Use props first, then fallback to location state if needed
  const fromPlans = fromPlanDetails || location.state?.fromPlans || false;
  const requestForSuggestions = forProductSuggestion || location.state?.forSuggestions || false;
  const fromPlanDetailsState = fromPlanDetails || location.state?.fromPlanDetails || false;
  const forProductSuggestionState = forProductSuggestion || location.state?.forProductSuggestion || false;
  const productNameState = productName || location.state?.productName || "";
  const activateProductExpertState = activateProductExpert || location.state?.activateProductExpert || false;
  const babyNameState = babyName || location.state?.babyName || "Emma";
  
  // Add a ref for the message input
  const messageInputRef = useRef(null);

  // Handle the auto-message animation sequence - simplified and more robust approach
  useEffect(() => {
    // Check if we have auto-message data from props or event
    const locationState = location.state || {};
    if (locationState.autoMessage && locationState.messageText) {
      console.log("Auto message detected in location state:", locationState.messageText);
      
      // Set the message text immediately
      setMessage(locationState.messageText);
      
      // Send the message after a short delay (500ms total)
      setTimeout(() => {
        console.log("Auto-sending message:", locationState.messageText);
        handleSendMessage();
        
        // Store the return function if provided
        if (typeof locationState.returnFunction === 'function') {
          window.chatReturnFunction = locationState.returnFunction;
        }
        
        // Set up return button after response
        setTimeout(() => {
          // Get current chat history
          const currentHistory = getChatHistory();
          if (currentHistory.length < 2) return; // Need at least a response
          
          // Find last non-typing assistant message
          const lastAssistantIndex = currentHistory.findIndex(
            item => item.role === 'assistant' && !item.isTyping
          );
          
          if (lastAssistantIndex !== -1) {
            // Update that message with the return button
            setChatHistory(prev => {
              const updated = [...prev];
              updated[lastAssistantIndex] = {
                ...updated[lastAssistantIndex],
                showReturnButton: true,
                actionLabel: locationState.actionLabel || "See Recommendations",
                returnFunction: locationState.returnFunction
              };
              return updated;
            });
          }
        }, 3000); // Give time for response to appear
      }, 500);
    }
  }, [location]); // Only run when location changes
  
  // Handle auto-message from custom event
  useEffect(() => {
    // Function to handle chat drawer events
    const handleDrawerEvent = (event) => {
      const data = event.detail || {};
      if (data.autoMessage && data.messageText) {
        console.log("Auto message detected in custom event:", data.messageText);
        
        // Set the message text immediately
        setMessage(data.messageText);
        
        // Send the message after a short delay
        setTimeout(() => {
          console.log("Auto-sending message from event:", data.messageText);
          handleSendMessage();
          
          // Store the return function if provided
          if (typeof data.returnFunction === 'function') {
            window.chatReturnFunction = data.returnFunction;
          }
          
          // Set up return button after response
          setTimeout(() => {
            // Get current chat history
            const currentHistory = getChatHistory();
            if (currentHistory.length < 2) return; // Need at least a response
            
            // Find last non-typing assistant message
            const lastAssistantIndex = currentHistory.findIndex(
              item => item.role === 'assistant' && !item.isTyping
            );
            
            if (lastAssistantIndex !== -1) {
              // Update that message with the return button
              setChatHistory(prev => {
                const updated = [...prev];
                updated[lastAssistantIndex] = {
                  ...updated[lastAssistantIndex],
                  showReturnButton: true,
                  actionLabel: data.actionLabel || "See Recommendations",
                  returnFunction: data.returnFunction
                };
                return updated;
              });
            }
          }, 3000); // Give time for response to appear
        }, 500);
      }
    };
    
    // Add event listener
    window.addEventListener('showChatDrawer', handleDrawerEvent);
    
    // Cleanup
    return () => {
      window.removeEventListener('showChatDrawer', handleDrawerEvent);
    };
  }, []); // Empty dependency array - only run once on mount

  // Handle coming from Plans tab for suggestions
  useEffect(() => {
    if (fromPlans && requestForSuggestions) {
      // Set active tab to product-expert
      setActiveTab('product-expert');
      setForSuggestions(true);
      
      // Add a specific message to the product chat history for list suggestions
      if (productChatHistory.length <= 1) { // Only add if we haven't had a conversation yet
        setProductChatHistory(prev => [
          ...prev,
          {
            role: 'user',
            content: `I'd like some suggestions for a baby checklist for ${babyName}.`,
            timestamp: new Date()
          }
        ]);
        
        // Simulate assistant response with slight delay
        setTimeout(() => {
          setProductChatHistory(prev => [
            ...prev,
            {
              role: 'assistant',
              content: `I'd be happy to help create a personalized list for ${babyName}! To make good recommendations, I'll need to know a bit about your situation. What's your living space like? (apartment, house, living with family, etc.)`,
              timestamp: new Date()
            }
          ]);
        }, 1000);
      }
    }
  }, [fromPlans, requestForSuggestions, productChatHistory.length, babyName]);
  
  // Use a ref to track if we've already initialized the product conversation
  const productConversationInitialized = useRef(false);
  
  // Handle coming from Plan Details for product suggestions - ONLY RUN ONCE
  useEffect(() => {
    // Skip if already initialized or if we're not coming from plan details
    if (productConversationInitialized.current || !fromPlanDetailsState || !forProductSuggestionState) {
      return;
    }
    
    console.log('ONE-TIME INITIALIZATION of product chat with clean history');
    
    // Mark as initialized to prevent any future runs
    productConversationInitialized.current = true;
    
    // Set active tab to product-expert
    setActiveTab('product-expert');
    
    // Reset ALL chat history states to avoid any potential conflicts
    setChatHistory([]);
    setTherapistChatHistory([]);
    setMedicalChatHistory([]);
    setFinancialChatHistory([]);
    
    // Step 1: Show initial greeting
    setProductChatHistory([
      {
        role: 'assistant',
        content: "Hi there! I'm your Bibi product expert assistant. I can help you with product recommendations and answer questions about baby gear.",
        timestamp: new Date(),
      }
    ]);
    
    // Step 2: After a slight delay, add user's question
    setTimeout(() => {
      setProductChatHistory(prev => [
        ...prev, 
        {
          role: 'user',
          content: `I need help choosing ${productNameState ? 'a ' + productNameState : 'baby products'} for ${babyNameState}.`,
          timestamp: new Date()
        }
      ]);
      
      // Step 3: Show typing indicator
      setTimeout(() => {
        setProductChatHistory(prev => [
          ...prev, 
          {
            role: 'assistant',
            content: '',
            timestamp: new Date(),
            isTyping: true
          }
        ]);
        
        // Step 4: Replace typing indicator with actual response after delay
        setTimeout(() => {
          setProductChatHistory(prev => {
            // Filter out the typing indicator
            const withoutTyping = prev.filter(item => !item.isTyping);
            return [
              ...withoutTyping, 
              {
                role: 'assistant',
                content: `I'd be happy to help you find the perfect ${productNameState || 'products'} for ${babyNameState}! To recommend the best options, I'll need to know a bit about your needs. What's your living space like? Do you have a dedicated nursery or will the baby items be in your bedroom?`,
                timestamp: new Date()
              }
            ];
          });
        }, 1500); // Typing time
      }, 400); // Time after user message before typing starts
    }, 1000); // Time after greeting before user message appears
  }, []);
  
  // Get questions for current tab
  const getQuestionsForTab = () => {
    switch(activeTab) {
      case 'product-expert': return productExpertQuestions;
      case 'therapist': return therapistQuestions;
      case 'medical': return medicalQuestions;
      case 'financial': return financialQuestions;
      default: return [];
    }
  };
  
  // State for quotation marks visibility
  const [quotesVisible, setQuotesVisible] = useState(true);
  // State to show "Click here for ideas!" message
  const [showClickHereMessage, setShowClickHereMessage] = useState(false);
  // Ref to track rotation state between renders
  const rotationStateRef = useRef({
    isClickHere: false,  // Start with showing a question
    questionIndex: 0     // Start with the first question
  });
  
  // Rotate through questions every 5 seconds with fade effect, alternating with "Click here for ideas!"
  useEffect(() => {
    const questions = getQuestionsForTab();
    if (questions.length === 0) return;
    
    // Reset states when tab changes
    setCurrentQuestion(0);
    setQuestionVisible(true);
    setQuotesVisible(true);
    setShowClickHereMessage(false);
    
    // Reset the rotation state ref
    rotationStateRef.current = {
      isClickHere: false,
      questionIndex: 0
    };
    
    // Reference to store the interval ID
    let fadeInterval = null;
    
    // Handle all state changes in one place to ensure synchronization
    const updateVisibility = (question, quotes, showClickHere) => {
      return new Promise(resolve => {
        // Use requestAnimationFrame to batch updates within the same frame
        requestAnimationFrame(() => {
          setQuestionVisible(question);
          setQuotesVisible(quotes);
          setShowClickHereMessage(showClickHere);
          resolve();
        });
      });
    };
    
    // Fade out the current content
    const fadeOut = async () => {
      // Single update to fade everything out together
      await updateVisibility(false, false, rotationStateRef.current.isClickHere);
    };
    
    // Show the next content after fade out
    const showNext = async () => {
      if (rotationStateRef.current.isClickHere) {
        // If we were showing "Click here", show the next question
        const nextIndex = (rotationStateRef.current.questionIndex + 1) % questions.length;
        
        // Update the index reference
        setCurrentQuestion(nextIndex);
        rotationStateRef.current.questionIndex = nextIndex;
        
        // Update visibility in a single batch - question with quotes, no click here message
        await updateVisibility(true, true, false);
        
        // Update state flag
        rotationStateRef.current.isClickHere = false;
      } else {
        // If we were showing a question, we need to decide whether to show another question
        // or the "Click here" message based on the current count
        
        // Only show "Click here" message after every third question
        // (using modulo 3 on the index to determine when to show it)
        if ((rotationStateRef.current.questionIndex + 1) % 3 === 0) {
          // Time to show "Click here"
          rotationStateRef.current.isClickHere = true;
          
          // Update visibility in a single batch - question with no quotes, show click here message
          await updateVisibility(true, false, true);
        } else {
          // Show the next question
          const nextIndex = (rotationStateRef.current.questionIndex + 1) % questions.length;
          
          // Update the index reference
          setCurrentQuestion(nextIndex);
          rotationStateRef.current.questionIndex = nextIndex;
          
          // Show the next question with quotes
          await updateVisibility(true, true, false);
        }
      }
    };
    
    // Set up the rotation interval with async handling
    fadeInterval = setInterval(() => {
      // Since we're using async functions, manage the sequence properly
      fadeOut().then(() => {
        // After fade out animation completes, show next content
        setTimeout(() => {
          showNext();
        }, 500); // Duration of fade out animation
      });
    }, 5000); // Show each item for 5 seconds
    
    // Clean up the interval on unmount or tab change
    return () => {
      if (fadeInterval) clearInterval(fadeInterval);
    };
  }, [activeTab]);
  const { sendMessage, loading, error } = useChatGPT();
  const messagesEndRef = useRef(null);

  // Current chat history based on active tab
  const getChatHistory = () => {
    switch(activeTab) {
      case 'product-expert':
        return productChatHistory;
      case 'therapist':
        return therapistChatHistory;
      case 'medical':
        return medicalChatHistory;
      case 'financial':
        return financialChatHistory;
      default:
        return productChatHistory;
    }
  };
  
  const setChatHistory = (updater) => {
    switch(activeTab) {
      case 'product-expert':
        return setProductChatHistory(updater);
      case 'therapist':
        return setTherapistChatHistory(updater);
      case 'medical':
        return setMedicalChatHistory(updater);
      case 'financial':
        return setFinancialChatHistory(updater);
      default:
        return setProductChatHistory(updater);
    }
  };
  
  const chatHistory = getChatHistory();

  // Reference to track if standard chatbots have been initialized
  const standardChatbotsInitialized = useRef(false);
  
  // Sample first messages for each chatbot - ONLY RUN ONCE for regular chatbots
  useEffect(() => {
    // Skip if already initialized
    if (standardChatbotsInitialized.current) {
      return;
    }
    
    // Skip initialization if we're coming from PlanDetails 
    // (that has its own initialization handled by the dedicated effect)
    if (fromPlanDetailsState && forProductSuggestionState) {
      return;
    }
    
    // Mark as initialized 
    standardChatbotsInitialized.current = true;
    
    console.log('ONE-TIME INITIALIZATION of standard chatbots');
    
    // Therapist initial message
    if (therapistChatHistory.length === 0) {
      setTherapistChatHistory([
        {
          role: 'assistant',
          content: "Hello! I'm your Bibi DBT therapist assistant. I'm here to help you apply dialectical behavior therapy (DBT) skills to manage emotions, improve relationships, and navigate the challenges of pregnancy and parenthood. What would you like to explore today?",
          timestamp: new Date(),
        }
      ]);
    }
    
    // Medical assistant initial message
    if (medicalChatHistory.length === 0) {
      setMedicalChatHistory([
        {
          role: 'assistant',
          content: "Hello! I'm your Bibi pregnancy information assistant. I can provide general information about pregnancy, prenatal health, and common concerns. **Please note that I'm not a substitute for professional medical advice. Always consult with your healthcare provider for medical concerns.** How can I help you today?",
          timestamp: new Date(),
        }
      ]);
    }
    
    // Financial advisor initial message
    if (financialChatHistory.length === 0) {
      setFinancialChatHistory([
        {
          role: 'assistant',
          content: "Welcome! I'm your Bibi financial advisor assistant. I can help you plan for the financial aspects of parenthood, including budgeting for baby expenses, understanding childcare costs, exploring tax benefits, and planning for your child's future education. **Note that I provide general financial information, not personalized financial advice.** How can I assist with your financial planning today?",
          timestamp: new Date(),
        }
      ]);
    }
    
    // ONLY initialize product chat if we're NOT coming from product details
    if (productChatHistory.length === 0 && !productConversationInitialized.current) {
      setProductChatHistory([
        {
          role: 'assistant',
          content: "Hi there! I'm your Bibi product expert assistant. I can help you with product recommendations, answer questions about baby gear, or provide information about different stages of pregnancy and baby development. How can I help you today?",
          timestamp: new Date(),
        }
      ]);
    }
  }, []);

  // Auto-scroll to the bottom of the chat
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  // Handle adding suggestion to plans and returning
  const handleAddSuggestionToPlan = () => {
    // Navigate back to Plans
    history.push('/plan');
  };
  
  // Handle saving product recommendation to plan
  const handleSaveProductRecommendation = () => {
    // Navigate back to plans and open the list
    history.push('/plan/123e4567-e89b-12d3-a456-426614174000');
  };
  
  const handleSendMessage = async () => {
    if (!message.trim()) return;

    // Capture message text before clearing input
    const messageText = message;
    
    // Add user message to chat history
    const userMessage = {
      role: 'user',
      content: messageText,
      timestamp: new Date(),
    };
    
    setChatHistory(prev => [...prev, userMessage]);
    setMessage('');
    
    // Immediately show typing indicator after user message
    setTimeout(() => {
      setChatHistory(prev => [
        ...prev,
        {
          role: 'assistant',
          content: '',
          timestamp: new Date(),
          isTyping: true
        }
      ]);
    }, 300);
    
    // If this is a suggestion request and the message is about apartments or houses
    // show a "complete" response with a button to go back to Plans
    if (forSuggestions && 
        (message.toLowerCase().includes('apartment') || 
         message.toLowerCase().includes('house') || 
         message.toLowerCase().includes('condo') || 
         message.toLowerCase().includes('live'))) {
      
      setTimeout(() => {
        setChatHistory(prev => [
          ...prev,
          {
            role: 'assistant',
            content: `Thanks for the information about your living situation! Based on what you've shared, I've created a customized list for ${babyName} that includes space-efficient essentials and items tailored to your needs. Would you like to add this list to your plan?`,
            timestamp: new Date(),
            showActionButton: true
          }
        ]);
      }, 1500);
      
      return; // Don't send to API since we're using a predetermined response
    }
    
    // Skip message handling in the following cases to prevent duplicate responses:
    // 1. Empty messages
    // 2. Initial setup with no real user input (only router state)
    // 3. Messages that exactly match our initial seeded message to avoid duplicates
    if (!message.trim() || 
        (fromPlanDetailsState && forProductSuggestionState && 
         message.trim() === `I need help choosing ${productNameState ? 'a ' + productNameState : 'baby products'} for ${babyNameState}.`)) {
      return;
    }
    
    // Handle product suggestions more dynamically - only for ACTUAL user input
    if (fromPlanDetailsState && forProductSuggestionState) {
      // This now only processes user responses AFTER the initial greeting flow
      
      // First show a thinking/analyzing state with typing indicator
      setChatHistory(prev => [...prev, {
        role: 'assistant',
        content: '',
        timestamp: new Date(),
        isTyping: true
      }]);
      
      // Get the initial message based on the product type
      const getInitialMessage = () => {
        // Extract key details from the message to personalize response
        const hasSpaceInfo = 
          message.toLowerCase().includes('apartment') || 
          message.toLowerCase().includes('house') ||
          message.toLowerCase().includes('room') || 
          message.toLowerCase().includes('small') ||
          message.toLowerCase().includes('large');
        
        const hasBudgetInfo = 
          message.toLowerCase().includes('budget') || 
          message.toLowerCase().includes('cheap') ||
          message.toLowerCase().includes('expensive') ||
          message.toLowerCase().includes('afford') ||
          message.toLowerCase().includes('cost') || 
          message.toLowerCase().includes('price');
        
        // Create a personalized response message
        let responseMessage = `I've analyzed your needs for ${productNameState}`;
        
        if (hasSpaceInfo) {
          responseMessage += ` and space requirements`;
        }
        
        if (hasBudgetInfo) {
          responseMessage += ` within your budget constraints`;
        }
        
        responseMessage += `. Based on what you've shared, I've found three options that would work well:`;
        
        return responseMessage;
      };
      
      // Get product recommendations based on the product type
      const getProductRecommendations = () => {
        // Default recommendations (generic)
        let recommendations = {
          items: [
            {
              name: "Premium Option",
              feature: "High-quality materials with advanced features"
            },
            {
              name: "Mid-range Option",
              feature: "Good balance of quality and affordability"
            },
            {
              name: "Budget-friendly Option",
              feature: "Reliable basic functionality at lower cost"
            }
          ]
        };
        
        // Product-specific recommendations
        if (productNameState.toLowerCase().includes('crib')) {
          recommendations.items = [
            {
              name: "Babyletto Hudson 3-in-1 Convertible Crib",
              feature: "Space-saving design, converts as your child grows"
            },
            {
              name: "IKEA Sniglar Crib",
              feature: "Budget-friendly, minimalist design for smaller spaces"
            },
            {
              name: "Delta Children Heartland 4-in-1 Convertible Crib",
              feature: "Versatile option with multiple configurations"
            }
          ];
        } else if (productNameState.toLowerCase().includes('car seat')) {
          recommendations.items = [
            {
              name: "Britax B-Safe Ultra Infant Car Seat",
              feature: "Top safety ratings with side impact protection"
            },
            {
              name: "Graco SnugRide SnugLock 35",
              feature: "Easy installation with good safety features"
            },
            {
              name: "Safety 1st Grow and Go All-in-One",
              feature: "Convertible design that grows with your child"
            }
          ];
        } else if (productNameState.toLowerCase().includes('stroller')) {
          recommendations.items = [
            {
              name: "UPPAbaby VISTA V2 Stroller",
              feature: "Premium quality with expandability for multiple children"
            },
            {
              name: "Baby Jogger City Mini GT2",
              feature: "All-terrain wheels with compact fold design"
            },
            {
              name: "Graco Modes Pramette Stroller",
              feature: "Budget-friendly with multiple configuration options"
            }
          ];
        }
        
        // Format recommendations as markdown list
        let formattedRecommendations = '';
        recommendations.items.forEach((item, index) => {
          formattedRecommendations += `${index + 1}. **${item.name}** - ${item.feature}\n`;
        });
        
        return formattedRecommendations;
      };
      
      // Make sure we have a typing indicator
      if (!chatHistory.some(item => item.isTyping)) {
        setChatHistory(prev => [
          ...prev,
          {
            role: 'assistant',
            content: '',
            timestamp: new Date(),
            isTyping: true
          }
        ]);
      }
      
      // After a realistic delay for typing, show the first part of the response
      setTimeout(() => {
        // Show the first part of the response
        const initialMessage = getInitialMessage();
        
        setChatHistory(prev => {
          // Find and remove the typing indicator
          const withoutTyping = prev.filter(item => !item.isTyping);
          return [
            ...withoutTyping,
            {
              role: 'assistant',
              content: initialMessage,
              timestamp: new Date()
            }
          ];
        });
        
        // After showing first message, wait a bit then show typing for second message
        setTimeout(() => {
          // Add typing indicator for second message
          setChatHistory(prev => {
            // Make sure there's no existing typing indicator
            const withoutTyping = prev.filter(item => !item.isTyping);
            return [
              ...withoutTyping,
              {
                role: 'assistant',
                content: '',
                timestamp: new Date(),
                isTyping: true
              }
            ];
          });
          
          // Show product recommendations after typing delay
          setTimeout(() => {
            // Show recommendations
            const recommendations = getProductRecommendations();
            
            setChatHistory(prev => {
              // Remove typing indicator
              const withoutTyping = prev.filter(item => !item.isTyping);
              return [
                ...withoutTyping,
                {
                  role: 'assistant',
                  content: recommendations,
                  timestamp: new Date()
                }
              ];
            });
            
            // After showing recommendations, wait a bit then show typing for final message
            setTimeout(() => {
              // Add typing indicator for final message
              setChatHistory(prev => {
                // Make sure there's no existing typing indicator
                const withoutTyping = prev.filter(item => !item.isTyping);
                return [
                  ...withoutTyping,
                  {
                    role: 'assistant',
                    content: '',
                    timestamp: new Date(),
                    isTyping: true
                  }
                ];
              });
              
              // Show final message after typing delay
              setTimeout(() => {
                setChatHistory(prev => {
                  // Remove typing indicator
                  const withoutTyping = prev.filter(item => !item.isTyping);
                  return [
                    ...withoutTyping,
                    {
                      role: 'assistant',
                      content: `I've added these recommendations to your list for easy reference. You can view full details, compare features, and see photos there.`,
                      timestamp: new Date(),
                      showReturnToListOption: true,
                      returnProductDetails: {
                        productName: productNameState || 'product',
                        listId: '123e4567-e89b-12d3-a456-426614174000',
                        itemId: 'item-uuid-1'
                      }
                    }
                  ];
                });
              }, 1200); // Typing time for final message
            }, 800); // Pause before starting to type final message
          }, 1200); // Typing time for recommendations
        }, 800); // Pause after first response before typing again
      }, 1500); // Initial typing time
      
      return; // Don't send to API since we're handling it ourselves
    }

    try {
      // Format system prompt based on active tab
      let systemPrompt;
      let temperature = 0.7;  // Default temperature
      
      if (activeTab === 'product-expert') {
        systemPrompt = `
          You are a helpful baby product expert assistant for an app called Bibi. 
          Your name is Bibi Product Assistant.
          Be conversational, casual, and friendly - as if chatting with a friend.
          Keep responses SHORT (2-3 sentences max for each point).
          ASK FOLLOW-UP QUESTIONS to understand the user's specific situation before giving extensive advice.
          For example, if they ask about strollers, ask about their lifestyle, space constraints, or specific needs.
          Be consultative rather than just informative - help users make decisions based on their situation.
          If asked about specific products, briefly mention pros and cons, then ASK what features matter most to them.
          Use a warm, supportive tone that feels like a real conversation, not a lecture.
          Avoid overwhelming the user with information - focus on what's most relevant.
          End your responses with a specific question to keep the conversation going.
        `;
      } else if (activeTab === 'therapist') {
        systemPrompt = `
          You are a compassionate DBT therapist assistant for an app called Bibi.
          Your name is Bibi Therapy Assistant.
          Be conversational, warm, and empathetic - like talking to a supportive friend.
          Keep responses SHORT (3-4 sentences maximum per point).
          Use a friendly, casual tone that feels like a real conversation.
          ASK FOLLOW-UP QUESTIONS to understand what the user is feeling and experiencing before offering solutions.
          Be consultative - help users identify specific strategies based on their unique situation.
          When suggesting DBT techniques, briefly explain ONE simple technique they could try immediately.
          Include a brief disclaimer about not being a replacement for professional therapy, but keep it conversational.
          Focus on quick, practical advice the user can implement right away.
          End messages with a thoughtful question that encourages them to reflect or share more.
          Make the user feel heard and understood rather than lectured.
        `;
        temperature = 0.6;  // Slightly lower temperature for more focused responses
      } else if (activeTab === 'medical') {
        systemPrompt = `
          You are a medical information assistant for an app called Bibi.
          Your name is Bibi Medical Assistant.
          Be conversational, friendly, and reassuring - like a knowledgeable friend.
          Keep responses SHORT (2-3 sentences maximum per point).
          ASK FOLLOW-UP QUESTIONS to understand the user's specific situation before providing detailed information.
          Include a very brief disclaimer about consulting healthcare providers, but make it sound natural and conversational.
          Focus on the most relevant information rather than being comprehensive.
          If asked about symptoms or concerns, ask clarifying questions about duration, severity, and context.
          Be consultative - help users determine what information is most relevant to their situation.
          End your messages with a specific question that encourages the user to share more details or clarify their needs.
          Use a warm, reassuring tone while still being factual.
          Prioritize clarity and brevity over exhaustive information.
        `;
        temperature = 0.5;  // Lower temperature for more factual, less creative responses
      } else if (activeTab === 'financial') {
        systemPrompt = `
          You are a financial advisor assistant for an app called Bibi.
          Your name is Bibi Financial Assistant.
          Be conversational, friendly, and non-judgmental - like a financially savvy friend.
          Keep responses SHORT (2-3 sentences maximum per point).
          ASK FOLLOW-UP QUESTIONS to understand the user's specific financial situation before providing detailed guidance.
          For example, if they ask about saving for college, ask about their timeframe, other financial priorities, or current savings approach.
          Be consultative - help users think through financial decisions based on their unique circumstances.
          Briefly mention that you're providing general information rather than personalized financial advice, but keep it conversational.
          Focus on the most practical next steps rather than comprehensive planning.
          Provide 1-2 actionable suggestions that feel doable, not overwhelming.
          Use a supportive tone that acknowledges the financial challenges of parenthood.
          End your responses with a specific question to understand more about their situation or priorities.
        `;
        temperature = 0.6;  // Balanced between creative and factual
      }

      // Format previous messages for context
      const previousMessages = chatHistory.map(item => ({
        role: item.role,
        content: item.content
      }));

      // Send message to API with conversation history
      const response = await sendMessage(message, {
        systemPrompt: systemPrompt,
        temperature: temperature,
        maxTokens: 1000,
        previousMessages: previousMessages // Pass conversation history
      });

      // Remove typing indicator and add assistant's response to chat history
      setChatHistory(prev => {
        // Filter out typing indicator
        const withoutTyping = prev.filter(item => !item.isTyping);
        
        return [
          ...withoutTyping,
          {
            role: 'assistant',
            content: response,
            timestamp: new Date(),
          }
        ];
      });
    } catch (err) {
      console.error('Failed to get response:', err);
      
      // Remove typing indicator and add error message
      setChatHistory(prev => {
        // Filter out typing indicator
        const withoutTyping = prev.filter(item => !item.isTyping);
        
        return [
          ...withoutTyping,
          {
            role: 'assistant',
            content: "I'm sorry, I'm having trouble connecting right now. Please try again in a moment.",
            timestamp: new Date(),
            error: true,
          }
        ];
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleSuggestedQuestion = (question) => {
    setMessage(question);
    setSuggestionsPopupVisible(false);
  };
  
  const showSuggestionsPopup = () => {
    setSuggestionsPopupVisible(true);
  };
  
  const hideSuggestionsPopup = () => {
    setSuggestionsPopupVisible(false);
  };
  
  // Get pastel colors for suggestion items
  const getPastelColor = (index) => {
    const colors = [
      '#f5f0ff', // lavender
      '#fff0f5', // light pink
      '#f0f8ff', // light blue
      '#f0fff4', // light mint
      '#fff9f0', // light peach
      '#f8f4e3', // light yellow
    ];
    return colors[index % colors.length];
  };
  
  // Safely get the current suggested questions
  const currentSuggestedQuestions = getQuestionsForTab();
  
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  
  // Get the appropriate suggested questions based on active tab
  const getSuggestedQuestions = () => {
    switch(activeTab) {
      case 'product-expert':
        return productExpertQuestions;
      case 'therapist':
        return therapistQuestions;
      case 'medical':
        return medicalQuestions;
      case 'financial':
        return financialQuestions;
      default:
        return productExpertQuestions;
    }
  };
  
  const suggestedQuestions = getSuggestedQuestions();

  return (
    <div className={`chat-container ${
      activeTab === 'product-expert' 
        ? 'product-expert-chat' 
        : activeTab === 'therapist' 
          ? 'therapist-chat' 
          : activeTab === 'medical'
            ? 'medical-chat'
            : 'financial-chat'
    } ${inDrawer ? 'in-drawer' : ''}`}>
      {!inDrawer && (
        <div className="chat-header">
          <div className="logo-container" style={{ 
            display: 'flex', 
            justifyContent: 'flex-start', 
            alignItems: 'center', 
            padding: '16px 24px',
            margin: 0,
            backgroundColor: 'white',
            borderBottom: 'none',
            boxShadow: '0 1px 4px rgba(0, 0, 0, 0.05), 0 0 0 0 rgba(0,0,0,0)',
            position: 'sticky',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 10,
            width: '100vw',
            marginLeft: 'calc(-50vw + 50%)',
            paddingLeft: 'calc(50vw - 50% + 24px)',
            paddingRight: 'calc(50vw - 50% + 24px)'
          }}>
            <BibiLogo width={200} height={28} />
          </div>
        </div>
      )}
      
      <div className="chat-type-description">
        {activeTab === 'product-expert' ? (
          <div className="chat-type-info product-expert">
            
            {/* Suggested Questions for Product Expert */}
            {currentSuggestedQuestions.length > 0 && (
              <div className="suggested-questions-horizontal">
                <div className="suggested-questions-header" onClick={showSuggestionsPopup}>
                  <span>Try asking me a question like:</span>
                </div>
                <div className="horizontal-questions">
                  <div 
                    className={`rotating-questions ${!questionVisible ? 'content-fade-out' : 'content-fade-in'} ${!quotesVisible ? 'quotes-hidden' : ''}`} 
                    onClick={showSuggestionsPopup}
                  >
                    <div className={`question-display ${!questionVisible ? 'fade-out' : ''}`}>
                      {currentSuggestedQuestions.length > 0 && !showClickHereMessage && (
                        <div className="single-question">
                          {currentSuggestedQuestions[currentQuestion % currentSuggestedQuestions.length]}
                        </div>
                      )}
                      {showClickHereMessage && (
                        <div className="single-question click-here-message">
                          Click here for ideas!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : activeTab === 'therapist' ? (
          <div className="chat-type-info therapist">
            
            {/* Suggested Questions for Therapist */}
            {currentSuggestedQuestions.length > 0 && (
              <div className="suggested-questions-horizontal">
                <div className="suggested-questions-header" onClick={showSuggestionsPopup}>
                  <span>Try asking me a question like:</span>
                </div>
                <div className="horizontal-questions">
                  <div 
                    className={`rotating-questions ${!questionVisible ? 'content-fade-out' : 'content-fade-in'} ${!quotesVisible ? 'quotes-hidden' : ''}`} 
                    onClick={showSuggestionsPopup}
                  >
                    <div className={`question-display ${!questionVisible ? 'fade-out' : ''}`}>
                      {currentSuggestedQuestions.length > 0 && !showClickHereMessage && (
                        <div className="single-question">
                          {currentSuggestedQuestions[currentQuestion % currentSuggestedQuestions.length]}
                        </div>
                      )}
                      {showClickHereMessage && (
                        <div className="single-question click-here-message">
                          Click here for ideas!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : activeTab === 'medical' ? (
          <div className="chat-type-info medical">
            
            {/* Suggested Questions for Medical */}
            {currentSuggestedQuestions.length > 0 && (
              <div className="suggested-questions-horizontal">
                <div className="suggested-questions-header" onClick={showSuggestionsPopup}>
                  <span>Try asking me a question like:</span>
                </div>
                <div className="horizontal-questions">
                  <div 
                    className={`rotating-questions ${!questionVisible ? 'content-fade-out' : 'content-fade-in'} ${!quotesVisible ? 'quotes-hidden' : ''}`} 
                    onClick={showSuggestionsPopup}
                  >
                    <div className={`question-display ${!questionVisible ? 'fade-out' : ''}`}>
                      {currentSuggestedQuestions.length > 0 && !showClickHereMessage && (
                        <div className="single-question">
                          {currentSuggestedQuestions[currentQuestion % currentSuggestedQuestions.length]}
                        </div>
                      )}
                      {showClickHereMessage && (
                        <div className="single-question click-here-message">
                          Click here for ideas!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="chat-type-info financial">
            
            {/* Suggested Questions for Financial */}
            {currentSuggestedQuestions.length > 0 && (
              <div className="suggested-questions-horizontal">
                <div className="suggested-questions-header" onClick={showSuggestionsPopup}>
                  <span>Try asking me a question like:</span>
                </div>
                <div className="horizontal-questions">
                  <div 
                    className={`rotating-questions ${!questionVisible ? 'content-fade-out' : 'content-fade-in'} ${!quotesVisible ? 'quotes-hidden' : ''}`} 
                    onClick={showSuggestionsPopup}
                  >
                    <div className={`question-display ${!questionVisible ? 'fade-out' : ''}`}>
                      {currentSuggestedQuestions.length > 0 && !showClickHereMessage && (
                        <div className="single-question">
                          {currentSuggestedQuestions[currentQuestion % currentSuggestedQuestions.length]}
                        </div>
                      )}
                      {showClickHereMessage && (
                        <div className="single-question click-here-message">
                          Click here for ideas!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="chat-messages">
        <List
          itemLayout="horizontal"
          dataSource={chatHistory}
          renderItem={(item) => (
            <List.Item className={`message-item ${item.role}`}>
              <div className="message-content">
                <div className="message-avatar">
                  {item.role === 'assistant' ? (
                    <Avatar 
                      icon={
                        activeTab === 'product-expert' 
                          ? <ShoppingOutlined /> 
                          : activeTab === 'therapist' 
                            ? <HeartOutlined /> 
                            : activeTab === 'medical'
                              ? <MedicineBoxOutlined />
                              : <DollarOutlined />
                      } 
                      style={{ 
                        backgroundColor: 
                          activeTab === 'product-expert' 
                            ? '#6B4EFF' 
                            : activeTab === 'therapist'
                              ? '#ff69b4' 
                              : activeTab === 'medical'
                                ? '#ff7f50'
                                : '#52c41a'
                      }} 
                    />
                  ) : (
                    <Avatar icon={<UserOutlined />} style={{ backgroundColor: '#1890ff' }} />
                  )}
                </div>
                <div className="message-bubble">
                  <div className="message-text">
                    {item.error ? (
                      <Text type="danger">{item.content}</Text>
                    ) : item.isTyping ? (
                      <div className="typing-indicator">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    ) : (
                      <div className="markdown-content">
                        <ReactMarkdown>{item.content}</ReactMarkdown>
                      </div>
                    )}
                    
                    {/* Action buttons for suggestions */}
                    {item.showActionButton && (
                      <div style={{ marginTop: '15px', display: 'flex', gap: '10px' }}>
                        <Button 
                          type="primary" 
                          onClick={handleAddSuggestionToPlan}
                          style={{ 
                            background: '#5c9dff',
                            borderColor: '#4b87e8'
                          }}
                        >
                          Add to {babyName}'s Plan
                        </Button>
                        <Button>No Thanks</Button>
                      </div>
                    )}
                    
                    {/* Return to list option */}
                    {item.showReturnToListOption && (
                      <div style={{ marginTop: '15px' }}>
                        <div style={{ 
                          display: 'flex', 
                          justifyContent: 'flex-start', 
                          marginTop: '10px',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          background: '#f8fbff',
                          padding: '15px',
                          borderRadius: '12px',
                          border: '1px solid #e6ecff'
                        }}>
                          <div style={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            marginBottom: '10px',
                            width: '100%'
                          }}>
                            <ShoppingOutlined style={{ color: '#5c9dff', marginRight: '8px', fontSize: '16px' }} />
                            <Text strong style={{ fontSize: '15px' }}>
                              Product Recommendations Ready
                            </Text>
                          </div>
                          
                          <Text style={{ marginBottom: '15px', fontSize: '14px' }}>
                            Your recommendations have been added to your list. Would you like to view them now?
                          </Text>
                          
                          <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
                            <Button 
                              type="primary" 
                              onClick={() => {
                                // Show transition overlay
                                document.body.appendChild(createTransitionOverlay());
                                
                                // Navigate after a short delay for transition effect
                                setTimeout(() => {
                                  history.push({
                                    pathname: `/plan/${item.returnProductDetails.listId}`,
                                    state: { 
                                      openProductDetailsModal: true,
                                      selectedItemId: item.returnProductDetails.itemId
                                    }
                                  });
                                }, 600);
                              }}
                              style={{ 
                                background: '#5c9dff',
                                borderColor: '#4b87e8',
                                flex: 1
                              }}
                              icon={<ShoppingOutlined />}
                            >
                              View Recommendations
                            </Button>
                            <Button 
                              onClick={() => {
                                // When user says "no", add a final message
                                setChatHistory(prev => [
                                  ...prev,
                                  {
                                    role: 'user',
                                    content: 'I\'ll check them later',
                                    timestamp: new Date()
                                  }
                                ]);
                                
                                setTimeout(() => {
                                  setChatHistory(prev => [
                                    ...prev,
                                    {
                                      role: 'assistant',
                                      content: `No problem! Your recommendations are saved in your list. You can view them anytime by going to your list and checking the ${item.returnProductDetails.productName} details.`,
                                      timestamp: new Date()
                                    }
                                  ]);
                                }, 800);
                              }}
                              style={{ flex: 0.7 }}
                            >
                              Not Now
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                    
                    {/* Return button for auto-message flow */}
                    {item.showReturnButton && (
                      <div style={{ marginTop: '15px' }}>
                        <div style={{ 
                          display: 'flex', 
                          justifyContent: 'flex-start', 
                          marginTop: '10px',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          background: '#f8fbff',
                          padding: '15px',
                          borderRadius: '12px',
                          border: '1px solid #e6ecff'
                        }}>
                          <div style={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            marginBottom: '10px',
                            width: '100%'
                          }}>
                            <ShoppingOutlined style={{ color: '#5c9dff', marginRight: '8px', fontSize: '16px' }} />
                            <Text strong style={{ fontSize: '15px' }}>
                              Recommendations Ready
                            </Text>
                          </div>
                          
                          <Text style={{ marginBottom: '15px', fontSize: '14px' }}>
                            Based on your preferences, I've prepared personalized recommendations. Would you like to see them?
                          </Text>
                          
                          <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
                            <Button 
                              type="primary" 
                              onClick={() => {
                                // Show transition overlay
                                document.body.appendChild(createTransitionOverlay());
                                
                                // Close the drawer if we're in one
                                if (inDrawer && onClose) {
                                  onClose();
                                }
                                
                                // Execute the return function after a short delay
                                setTimeout(() => {
                                  if (typeof item.returnFunction === 'function') {
                                    item.returnFunction();
                                  } else if (window.chatReturnFunction) {
                                    window.chatReturnFunction();
                                  }
                                }, 600);
                              }}
                              style={{ 
                                background: '#5c9dff',
                                borderColor: '#4b87e8',
                                flex: 1
                              }}
                              icon={<ShoppingOutlined />}
                            >
                              {item.actionLabel || "See Recommendations"}
                            </Button>
                            <Button 
                              onClick={() => {
                                // When user says "no", add a final message
                                setChatHistory(prev => [
                                  ...prev,
                                  {
                                    role: 'user',
                                    content: 'I\'ll check them later',
                                    timestamp: new Date()
                                  }
                                ]);
                                
                                setTimeout(() => {
                                  setChatHistory(prev => [
                                    ...prev,
                                    {
                                      role: 'assistant',
                                      content: `No problem! Let me know if you'd like to see the recommendations later or if you have any other questions.`,
                                      timestamp: new Date()
                                    }
                                  ]);
                                }, 800);
                              }}
                              style={{ flex: 0.7 }}
                            >
                              Not Now
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                    
                    {/* Product recommendation display (legacy, keeping code for reference) */}
                    {item.showProductRecommendation && (
                      <div style={{ marginTop: '20px' }}>
                        <div style={{ 
                          border: '1px solid #e6ecff', 
                          borderRadius: '10px', 
                          padding: '15px',
                          backgroundColor: '#f8faff',
                          marginBottom: '15px'
                        }}>
                          {/* Product image */}
                          <div style={{ 
                            display: 'flex', 
                            justifyContent: 'center', 
                            marginBottom: '15px' 
                          }}>
                            <img 
                              src={item.productImageUrl} 
                              alt="Babyletto Hudson 3-in-1 Convertible Crib" 
                              style={{ 
                                maxHeight: '200px', 
                                maxWidth: '100%', 
                                borderRadius: '8px',
                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
                              }} 
                            />
                          </div>
                          
                          {/* Product details */}
                          <div style={{ textAlign: 'center' }}>
                            <Text strong style={{ fontSize: '16px' }}>
                              Babyletto Hudson 3-in-1 Convertible Crib
                            </Text>
                            <br />
                            <Text type="secondary" style={{ fontSize: '14px' }}>
                              $399.99
                            </Text>
                          </div>
                        </div>
                        
                        {/* Action buttons */}
                        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                          <Button 
                            type="primary" 
                            onClick={handleSaveProductRecommendation}
                            style={{ 
                              background: '#5c9dff',
                              borderColor: '#4b87e8'
                            }}
                          >
                            Save Recommendation
                          </Button>
                          <Button>See Other Options</Button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="message-time">
                    {item.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  </div>
                </div>
              </div>
            </List.Item>
          )}
        />
        <div ref={messagesEndRef} />

        {loading && (
          <div className="loading-indicator">
            <Spin size="small" />
            <Text type="secondary">Bibi is thinking...</Text>
          </div>
        )}
      </div>

      {/* Fixed bottom container for input and tabs */}
      <div className="chat-bottom-container">
        <div className="chat-input">
          <TextArea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder={
              activeTab === 'product-expert' 
                ? 'Ask about baby products...' 
                : activeTab === 'therapist'
                  ? 'Ask about emotional support...'
                  : activeTab === 'medical'
                    ? 'Ask health questions...'
                    : 'Ask about budgeting...'
            }
            autoSize={{ minRows: 1, maxRows: 4 }}
            disabled={loading}
            ref={messageInputRef}
            // No special styling needed - we're no longer doing the bold animation this way
          />
          <Button
            type="primary"
            icon={<SendOutlined style={{ color: '#ffffff' }} />}
            onClick={handleSendMessage}
            disabled={loading || !message.trim()}
            style={{ 
              backgroundColor: 
                activeTab === 'product-expert' 
                  ? '#5236DB' /* Darkened for better contrast with white icon */
                  : activeTab === 'therapist' 
                    ? '#e0529f' /* Darkened for better contrast with white icon */
                    : activeTab === 'medical'
                      ? '#e06a3c' /* Darkened for better contrast with white icon */
                      : '#41a314', /* Darkened for better contrast with white icon */
              borderColor: 
                activeTab === 'product-expert' 
                  ? '#5236DB' 
                  : activeTab === 'therapist' 
                    ? '#e0529f' 
                    : activeTab === 'medical'
                      ? '#e06a3c'
                      : '#41a314',
              color: '#ffffff' /* Ensure text is white too */
            }}
          />
        </div>
      </div>
      
      {/* Suggestions Popup Modal */}
      <Modal
        title={<div style={{ fontSize: '16px' }}>Questions you can ask</div>}
        open={suggestionsPopupVisible}
        onCancel={hideSuggestionsPopup}
        footer={null}
        width={450}
        centered
        bodyStyle={{ maxHeight: '500px', overflow: 'auto', padding: '16px' }}
        style={{ borderRadius: '12px', overflow: 'hidden' }}
      >
        <List
          itemLayout="vertical"
          dataSource={getQuestionsForTab()}
          renderItem={(question, index) => (
            <List.Item
              key={index}
              onClick={() => handleSuggestedQuestion(question)}
              style={{ 
                cursor: 'pointer', 
                padding: '12px 16px', 
                borderRadius: '8px', 
                marginBottom: '10px',
                border: '1px solid #f0f0f0',
                backgroundColor: getPastelColor(index)
              }}
              className="suggestion-popup-item"
            >
              <div style={{ fontSize: '14px' }}>{question}</div>
            </List.Item>
          )}
        />
      </Modal>

      {/* Close button when in drawer on mobile */}
      {inDrawer && (
        <div 
          className="chat-drawer-close-button" 
          onClick={onClose}
          style={{
            display: 'none', /* Hidden by default, shown only on mobile via CSS media query */
            position: 'fixed',
            bottom: 16,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            backgroundColor: '#f5f5f5',
            border: '1px solid #e8e8e8',
            borderRadius: 20,
            padding: '8px 16px',
            fontSize: 14,
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            cursor: 'pointer'
          }}
        >
          Close Chat
        </div>
      )}
      
      <div className="chat-tabs-container">
        <Tabs 
          activeKey={activeTab} 
          onChange={handleTabChange}
          className="chat-tabs bottom-tabs"
          tabBarGutter={0}
          size="large"
          centered={true}
          animated={false} /* Disable animations which can cause rendering issues */
        >
          <TabPane 
            tab={
              <div className="tab-content">
                <ShoppingOutlined /> 
                <div>Products</div>
              </div>
            } 
            key="product-expert"
            style={{ opacity: 1 }}
          />
          <TabPane 
            tab={
              <div className="tab-content">
                <HeartOutlined /> 
                <div>Support</div>
              </div>
            } 
            key="therapist"
            style={{ opacity: 1 }}
          />
          <TabPane 
            tab={
              <div className="tab-content">
                <MedicineBoxOutlined /> 
                <div>Health</div>
              </div>
            } 
            key="medical"
            style={{ opacity: 1 }}
          />
          <TabPane 
            tab={
              <div className="tab-content">
                <DollarOutlined /> 
                <div>Budget</div>
              </div>
            } 
            key="financial"
            style={{ opacity: 1 }}
          />
        </Tabs>
      </div>
    </div>
  );
};

export default Chat;