import React, { useState, useEffect, useCallback, useMemo } from "react";
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { shallowEqual } from 'react-redux';
import { Card, Button, Checkbox, ConfigProvider, Empty } from "antd";
import { UpOutlined, DownOutlined, CloseOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import ExpandedCategoryItem from './ExpandedCategoryItem';
import ProductList from '../lists/ProductList';

import { readAllCategoriesInAllTopics } from '../../redux/topics/actions';
import {
  readUserSelectedCategories,
  toggleCategoryCheck,
  removeCategoryFromUserList,
  updateUserLinkCategory
} from '../../redux/categories/actions';
import { readProductsInCategory } from '../../redux/products/actions';
import { createNote, readNotesInCategory } from '../../redux/notes/actions';

import "../../../assets/css/components/categories.css";

const getAllCategoriesInTopics = state => state.topics.allCategoriesInTopics || [];
const getUserCategories = state => state.categories.userSelectedCategories || [];
const getProducts = state => state.products.products || {};
const getReadProductsInCategoryInProgress = state => state.products.readAllProductsInCategoryInProgress || {};
const getSharedNotes = state => state.notes.sharedNotes || {};
const getIsLoadingSharedNotes = state => state.notes.isLoadingSharedNotes || {};

const getVisibleTopics = createSelector(
  [getAllCategoriesInTopics, getUserCategories, (state, props) => props.showAddedCategories, (state, props) => props.currentFriendUuid],
  (allCategoriesInTopics, userCategories, showAddedCategories, currentFriendUuid) => {
    console.log('getVisibleTopics input:', { allCategoriesInTopics, userCategories, showAddedCategories, currentFriendUuid });
    const result = allCategoriesInTopics.map(topic => {
      // Only include categories that belong to this topic and match filter criteria
      const topicCategories = (topic.categories || []).filter(cat => 
        cat.topics_uuid === topic.uuid && 
        (currentFriendUuid ? true : (showAddedCategories === undefined ? true : userCategories.includes(cat.uuid)))
      );
      
      return {
        ...topic,
        visibleCategories: topicCategories
      };
    }).filter(topic => topic.visibleCategories.length > 0);
    
    console.log('getVisibleTopics output:', result);
    return result;
  }
);

const CategoryItem = React.memo(({
  category,
  expanded,
  isUserCategory,
  onExpand,
  onComplete,
  onRemove,
  sharedNotes,
  isLoadingSharedNotes,
  products,
  readProductsInCategoryInProgress,
  canEdit,
  currentFriendUuid,
  onNotesChange,
  onPrivacyChange
}) => {
  console.log('CategoryItem render', category.uuid);

  return (
    <div className="category-item-container">
      <Card
        className={`category-item ${expanded ? 'expanded' : ''}`}
      >
        <div className="category-header" onClick={() => onExpand(category.uuid)}>
          <Checkbox
            checked={isUserCategory}
            onChange={() => onComplete(category)}
            onClick={(e) => e.stopPropagation()}
          />
          <span className="category-name">{category.name}</span>
          {isUserCategory && (
            <Button
              icon={<CloseOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                onRemove(category.uuid);
              }}
              className="remove-category-button"
            />
          )}
          {expanded ? <UpOutlined /> : <DownOutlined />}
        </div>
        {expanded && (
          <ExpandedCategoryItem
            category={category}
            onCollapse={() => onExpand(category.uuid)}
            onNotesChange={(newNote) => onNotesChange(newNote, category.uuid)}
            onPrivacyChange={(value) => onPrivacyChange(category.uuid, value)}
            isLoadingSharedNotes={isLoadingSharedNotes[category.uuid]}
            sharedNotes={sharedNotes[category.uuid]}
            canEdit={canEdit}
            currentFriendUuid={currentFriendUuid}
            products={products[category.uuid] || []}
            loading={readProductsInCategoryInProgress[category.uuid]}
          />
        )}
      </Card>
      {expanded && (
        <div className="product-list-container">
          <ProductList
            products={products[category.uuid] || []}
            loading={readProductsInCategoryInProgress[category.uuid]}
            categoryUuid={category.uuid}
            selectedFriends={currentFriendUuid ? [currentFriendUuid] : []}
          />
        </div>
      )}
    </div>
  );
}, (prevProps, nextProps) => {
  return (
    prevProps.expanded === nextProps.expanded &&
    prevProps.isUserCategory === nextProps.isUserCategory &&
    prevProps.canEdit === nextProps.canEdit &&
    prevProps.currentFriendUuid === nextProps.currentFriendUuid &&
    prevProps.isLoadingSharedNotes[prevProps.category.uuid] === nextProps.isLoadingSharedNotes[nextProps.category.uuid] &&
    prevProps.sharedNotes[prevProps.category.uuid] === nextProps.sharedNotes[nextProps.category.uuid] &&
    prevProps.products[prevProps.category.uuid] === nextProps.products[nextProps.category.uuid] &&
    prevProps.readProductsInCategoryInProgress[prevProps.category.uuid] === nextProps.readProductsInCategoryInProgress[nextProps.category.uuid]
  );
});

const NewCategories = React.memo(({
  visibleTopics,
  userCategories,
  products,
  readProductsInCategoryInProgress,
  sharedNotes,
  isLoadingSharedNotes,
  user,
  canEdit,
  currentFriendUuid,
  dispatch,
  showAddedCategories
}) => {
  console.log('NewCategories render start');
  console.log('NewCategories render', { visibleTopics, currentFriendUuid, showAddedCategories });

  const [expandedCategories, setExpandedCategories] = useState({});

  useEffect(() => {
    console.log('Initial useEffect running in NewCategories');
    dispatch(readAllCategoriesInAllTopics(null, currentFriendUuid));
    dispatch(readUserSelectedCategories(currentFriendUuid));
  }, [dispatch, currentFriendUuid]);

  const handleCategoryExpand = useCallback((categoryUuid) => {
    console.log('handleCategoryExpand called', { categoryUuid });
    setExpandedCategories(prev => {
      const newState = { ...prev, [categoryUuid]: !prev[categoryUuid] };
      if (!prev[categoryUuid]) {
        dispatch(readProductsInCategory(categoryUuid, currentFriendUuid));
        dispatch(readNotesInCategory(categoryUuid));
      }
      return newState;
    });
  }, [dispatch, currentFriendUuid]);

  const handleCategoryComplete = useCallback((category) => {
    console.log('handleCategoryComplete called', { category });
    if (category && category.uuid) {
      dispatch(toggleCategoryCheck(category.uuid));
    }
  }, [dispatch]);

  const handleNotesChange = useCallback((newNote, categoryId) => {
    console.log('handleNotesChange called', { newNote, categoryId });
    if (categoryId) {
      dispatch(createNote(user, newNote, 'category', { uuid: categoryId }, newNote.privacy));
    }
  }, [dispatch, user]);

  const handlePrivacyChange = useCallback((categoryId, privacy) => {
    console.log('handlePrivacyChange called', { categoryId, privacy });
    if (categoryId) {
      dispatch(updateUserLinkCategory(categoryId, null, null, privacy));
    }
  }, [dispatch]);

  const handleRemoveCategory = useCallback((categoryUuid) => {
    console.log('handleRemoveCategory called', { categoryUuid });
    dispatch(removeCategoryFromUserList(categoryUuid));
  }, [dispatch]);

  const userCategoriesSet = useMemo(() => new Set(userCategories), [userCategories]);

  console.log('Before render', { visibleTopics });

  if (visibleTopics.length === 0) {
    console.log('Rendering: No categories found');
    return <div className="categories-container">No categories found</div>;
  }

  return (
    <div className="categories-container">
      {visibleTopics.map((topic) => (
        <div key={topic.name} className="topic-container">
          <h2 className="topic-header">{topic.name}</h2>
          <ConfigProvider
            renderEmpty={() => {
              console.log('Rendering empty state for topic', topic.name);
              return (
                <Empty
                  imageStyle={{ height: 200 }}
                  description={
                    <span>
                      {topic.name} categories are waiting for you.{' '}
                      <Link to="/categories?favorited=false" className="product-category-text">
                        Take a look!
                      </Link>
                    </span>
                  }
                />
              );
            }}
          >
            {topic.visibleCategories.map(category => (
              <CategoryItem
                key={category.uuid}
                category={category}
                expanded={expandedCategories[category.uuid]}
                isUserCategory={userCategoriesSet.has(category.uuid)}
                onExpand={handleCategoryExpand}
                onComplete={handleCategoryComplete}
                onRemove={handleRemoveCategory}
                sharedNotes={sharedNotes}
                isLoadingSharedNotes={isLoadingSharedNotes}
                products={products}
                readProductsInCategoryInProgress={readProductsInCategoryInProgress}
                canEdit={canEdit}
                currentFriendUuid={currentFriendUuid}
                onNotesChange={handleNotesChange}
                onPrivacyChange={handlePrivacyChange}
              />
            ))}
          </ConfigProvider>
        </div>
      ))}
    </div>
  );
}, (prevProps, nextProps) => {
  return (
    shallowEqual(prevProps.visibleTopics, nextProps.visibleTopics) &&
    shallowEqual(prevProps.userCategories, nextProps.userCategories) &&
    prevProps.products === nextProps.products &&
    prevProps.readProductsInCategoryInProgress === nextProps.readProductsInCategoryInProgress &&
    prevProps.sharedNotes === nextProps.sharedNotes &&
    prevProps.isLoadingSharedNotes === nextProps.isLoadingSharedNotes &&
    prevProps.user === nextProps.user &&
    prevProps.canEdit === nextProps.canEdit &&
    prevProps.currentFriendUuid === nextProps.currentFriendUuid &&
    prevProps.showAddedCategories === nextProps.showAddedCategories
  );
});

const mapStateToProps = (state, ownProps) => {
  console.log('mapStateToProps in NewCategories:', {
    allCategoriesInTopics: state.topics.allCategoriesInTopics,
    userCategories: state.categories.userSelectedCategories,
    showAddedCategories: ownProps.showAddedCategories,
    currentFriendUuid: ownProps.currentFriendUuid
  });
  return {
    visibleTopics: getVisibleTopics(state, ownProps),
    userCategories: getUserCategories(state),
    products: getProducts(state),
    readProductsInCategoryInProgress: getReadProductsInCategoryInProgress(state),
    sharedNotes: getSharedNotes(state),
    isLoadingSharedNotes: getIsLoadingSharedNotes(state),
  };
};

const areStatesEqual = (next, prev) => {
  return (
    next.topics.allCategoriesInTopics === prev.topics.allCategoriesInTopics &&
    next.categories.userSelectedCategories === prev.categories.userSelectedCategories &&
    next.products.products === prev.products.products &&
    next.products.readAllProductsInCategoryInProgress === prev.products.readAllProductsInCategoryInProgress &&
    next.notes.sharedNotes === prev.notes.sharedNotes &&
    next.notes.isLoadingSharedNotes === prev.notes.isLoadingSharedNotes
  );
};

export default connect(mapStateToProps, null, null, {
  areStatesEqual,
  pure: true,
  forwardRef: true
})(NewCategories);
