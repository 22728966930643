import axios from 'axios';
import * as types from './actionTypes';
import { LOAD_SAVED_CATEGORY_STATES } from './actionTypes';
import { isEmpty } from '../../../app/helperFunctions';
import { readProductsInCategory } from '../products/actions';
import { readAllCategoriesInAllTopics } from '../topics/actions';
import _ from 'lodash';

export function addCategoryError(error) {
  return {
    type: types.ADD_CATEGORY_ERROR,
    addCategoryError: error
  }
}

export function addCategoryInProgress(bool) {
  return {
    type: types.ADD_CATEGORY_IN_PROGRESS,
    addCategoryInProgress: bool
  }
}

function readAllCategoriesInProgress(bool) {
  return {
    type: types.READ_ALL_CATEGORIES_IN_PROGRESS,
    readAllCategoriesInProgress: bool
  }
}

function readAllCategoriesError(errMessage) {
  return {
    type: types.READ_ALL_CATEGORIES_ERROR,
    readAllCategoriesError: errMessage
  };
}

function readAllCategoriesSuccess(bool, categories) {
  return {
    type: types.READ_ALL_CATEGORIES_SUCCESS,
    readAllCategoriesSuccess: bool,
    allCategories: categories
  };
}

function readUserCategoriesInProgress(bool) {
  return {
    type: types.READ_USER_CATEGORIES_IN_PROGRESS,
    readUserCategoriesInProgress: bool
  }
}

function readUserCategoriesError(errMessage) {
  return {
    type: types.READ_USER_CATEGORIES_ERROR,
    readUserCategoriesError: errMessage
  };
}

function readUserCategoriesSuccess(bool, categories) {
  return {
    type: types.READ_USER_CATEGORIES_SUCCESS,
    readUserCategoriesSuccess: bool,
    userSelectedCategories: categories
  };
}

function updateUserSelectedCategoriesInProgress(bool) {
  return {
    type: types.SELECT_USER_CATEGORY_IN_PROGRESS,
    updateUserSelectedCategoriesInProgress: bool
  }
}

function updateUserSelectedCategoriesSuccess(bool) {
  return {
    type: types.SELECT_USER_CATEGORY_SUCCESS,
    updateUserSelectedCategoriesSuccess: bool
  }
}

function updateUserSelectedCategoriesError(message) {
  return {
    type: types.SELECT_USER_CATEGORY_ERROR,
    updateUserSelectedCategoriesError: message
  }
}

export function updateCategoryData(allCategories) {
  return {
    type: types.UPDATE_CATEGORY_DATA,
    allCategories: allCategories
  }
}

function createUserLinkCategoryError(error) {
  return {
    type: types.CREATE_USER_LINK_CATEGORY_ERROR,
    createUserLinkCategoryError: error
  }
}

function createUserLinkCategoryInProgress(bool) {
  return {
    type: types.CREATE_USER_LINK_CATEGORY_IN_PROGRESS,
    createUserLinkCategoryInProgress: bool
  }
}

function createUserLinkCategorySuccess(bool) {
  return {
    type: types.CREATE_USER_LINK_CATEGORY_SUCCESS,
    createUserLinkCategorySuccess: bool
  }
}

function updateUserLinkCategoryInProgress(bool) {
  return {
    type: types.UPDATE_USER_LINK_CATEGORY_IN_PROGRESS,
    updateUserLinkCategoryInProgress: bool
  }
}

function updateUserLinkCategorySuccess(bool) {
  return {
    type: types.UPDATE_USER_LINK_CATEGORY_SUCCESS,
    updateUserLinkCategorySuccess: bool
  }
}

function updateUserLinkCategoryError(error) {
  return {
    type: types.UPDATE_USER_LINK_CATEGORY_ERROR,
    updateUserLinkCategoryError: error
  }
}

function deleteUserLinkCategoryInProgress(bool) {
  return {
    type: types.DELETE_USER_LINK_CATEGORY_IN_PROGRESS,
    deleteUserLinkCategoryInProgress: bool
  }
}

function deleteUserLinkCategorySuccess(bool) {
  return {
    type: types.DELETE_USER_LINK_CATEGORY_SUCCESS,
    deleteUserLinkCategorySuccess: bool
  }
}

function deleteUserLinkCategoryError(error) {
  return {
    type: types.DELETE_USER_LINK_CATEGORY_ERROR,
    deleteUserLinkCategoryError: error
  }
}

export const addCategoryToUserList = (categoryId) => {
  return (dispatch) => {
    dispatch({ type: types.ADD_CATEGORY_TO_USER_LIST_REQUEST });
    axios.post(`${process.env.REACT_APP_API_URL}/users/categories`, { category_uuid: categoryId })
      .then(response => {
        dispatch({
          type: types.ADD_CATEGORY_TO_USER_LIST_SUCCESS,
          payload: categoryId
        });
      })
      .catch(error => {
        dispatch({
          type: types.ADD_CATEGORY_TO_USER_LIST_FAILURE,
          payload: error.message
        });
      });
  };
};

export const removeCategoryFromUserList = (categoryId) => {
  return (dispatch) => {
    dispatch({ type: types.REMOVE_CATEGORY_FROM_USER_LIST_REQUEST });
    axios.delete(`${process.env.REACT_APP_API_URL}/users/categories/${categoryId}`)
      .then(response => {
        dispatch({
          type: types.REMOVE_CATEGORY_FROM_USER_LIST_SUCCESS,
          payload: categoryId
        });
      })
      .catch(error => {
        dispatch({
          type: types.REMOVE_CATEGORY_FROM_USER_LIST_FAILURE,
          payload: error.message
        });
      });
  };
};

export function readAllCategories() {
  return(dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/categories`;
    let config = {headers: {Authorization: `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}`}};
    dispatch(readAllCategoriesInProgress(true));
    axios.get(url, config)
      .then((response) => {
        if (!isEmpty(response)) {
          dispatch(readAllCategoriesSuccess(true, response.data));
        } else {
          dispatch(readAllCategoriesSuccess(false, null));
        }
      })
      .catch(error => {
        let errMessage = 'Error fetching category data. Please try again later.';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(readAllCategoriesError(errMessage));
      })
      .finally(() => {
        dispatch(readAllCategoriesInProgress(false));
        dispatch(readAllCategoriesError(''));
      });
  }
}

export function readUserSelectedCategories(currentFriendUuid) {
  return(dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/users/categories`;
    let config = {
      headers: {Authorization: `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}`},
      params: { friendUuid: currentFriendUuid }
    };
    dispatch(readUserCategoriesInProgress(true));
    axios.get(url, config)
      .then((response) => {
        if (!isEmpty(response)) {
          dispatch(readUserCategoriesSuccess(true, response.data));
        } else {
          dispatch(readUserCategoriesSuccess(true, []));
        }
      })
      .catch(error => {
        let errMessage = 'Error fetching category data. Please try again later.';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(readUserCategoriesError(errMessage));
      })
      .finally(() => {
        dispatch(readUserCategoriesInProgress(false));
        dispatch(readUserCategoriesError(''));
      });
  }
}

export function updateUserSelectedCategories(categoryUuids) {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/users/categories`;
    let config = {headers: {Authorization: `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}`}};
    dispatch(updateUserSelectedCategoriesInProgress(true));
    let data = {
      uuids: categoryUuids
    }
    axios.patch(url, data, config)
    .then((response) => {
      dispatch(updateUserSelectedCategoriesSuccess(true));
      dispatch(readUserSelectedCategories());
    })
    .catch(error => {
      let errMessage = 'Error selecting category. Please try again later.';
      if (error.response !== undefined) {
        errMessage = error.response;
        if (typeof error === 'object') {
          if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
            if (typeof error.response.data === 'object') {
              if ('Error' in error.response.data) {
                errMessage = error.response.data['Error'];
              }
            } else {
              errMessage = error.response.data;
            }
          }
        }
      }
      dispatch(updateUserSelectedCategoriesError(errMessage));
    })
    .finally(() => {
      dispatch(updateUserSelectedCategoriesError(''));
      dispatch(updateUserSelectedCategoriesSuccess(false));
      dispatch(updateUserSelectedCategoriesInProgress(false));
    })
  }
}

export function createUserLinkCategory(categories_uuid, researched, favorited, extension) {
  return (dispatch) => {
    let config = {headers: {Authorization: `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}`}};
    let url = `${process.env.REACT_APP_API_URL}/users/categories`;
    const data = {
      categories_uuid: categories_uuid,
      researched: researched,
      favorited: favorited
    };
    dispatch(createUserLinkCategoryInProgress(true));
    axios.post(url, data, config)
      .then((response) => {
        dispatch(createUserLinkCategorySuccess(true));
        dispatch(createUserLinkCategoryInProgress(false));
      })
      .then(() => {
        let url_favorited = extension.split("favorited").pop().split("=").pop();
        let url_friend_uuid = extension.split("friendUuid").pop().split("=").pop();
        if (url_friend_uuid !== "true" && url_friend_uuid !== "false") {
          dispatch(readAllCategoriesInAllTopics(true, url_friend_uuid));
        } else if (url_favorited !== "true" && url_favorited !== "false") {
          dispatch(readAllCategoriesInAllTopics());
        } else {
          dispatch(readAllCategoriesInAllTopics(url_favorited));
        }
      })
      .catch(error => {
        let errMessage = 'Error updating category';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(createUserLinkCategoryError(errMessage));
        dispatch(createUserLinkCategoryInProgress(false));
      })
      .finally(() => {
        dispatch(createUserLinkCategoryError(''));
        dispatch(createUserLinkCategorySuccess(false));
        dispatch(createUserLinkCategoryInProgress(false));
      });
  }
}

export function updateUserLinkCategory(category_uuid, researched, favorited, extension) {
  return (dispatch) => {
    let config = {headers: {Authorization: `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}`}};
    let url = `${process.env.REACT_APP_API_URL}/users/categories/${category_uuid}`;
    const data = {
      researched: researched,
      favorited: favorited,
    };
    dispatch(updateUserLinkCategoryInProgress(true));
    axios.patch(url, data, config)
      .then((response) => {
        let url_favorited = extension.split("favorited").pop().split("=").pop();
        let url_friend_uuid = extension.split("friendUuid").pop().split("=").pop();
        if (url_friend_uuid !== "true" && url_friend_uuid !== "false") {
          dispatch(readAllCategoriesInAllTopics(true, url_friend_uuid));
        } else if (url_favorited !== "true" && url_favorited !== "false") {
          dispatch(readAllCategoriesInAllTopics());
        } else {
          dispatch(readAllCategoriesInAllTopics(url_favorited));
        }
        dispatch(updateUserLinkCategorySuccess(true));
        dispatch(updateUserLinkCategoryInProgress(false));
      })
      .catch(error => {
        let errMessage = 'Error updating category';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                    errMessage = error.response.data['Error'];
                  }
                } else {
                  errMessage = error.response.data;
                }
              }
            }
          }
          dispatch(updateUserLinkCategoryError(errMessage));
          dispatch(updateUserLinkCategoryInProgress(false));
        })
        .finally(() => {
          dispatch(updateUserLinkCategoryError(''));
          dispatch(updateUserLinkCategorySuccess(false));
          dispatch(updateUserLinkCategoryInProgress(false));
        });
    }
  }

  export function deleteUserLinkCategory(category_uuid, product_uuid, extension) {
    return (dispatch) => {
      let config = {headers: {Authorization: `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}`}};
      let url = `${process.env.REACT_APP_API_URL}/users/categories/${category_uuid}`;
      dispatch(deleteUserLinkCategoryInProgress(true));
      axios.delete(url, config)
      .then((response) => {
        dispatch(deleteUserLinkCategorySuccess(true));
        dispatch(deleteUserLinkCategoryInProgress(false));
      })
      .then(() => {
        if (!isEmpty(product_uuid)) {
          dispatch(readProductsInCategory(category_uuid));
        } else {
          let url_favorited = extension.split("favorited").pop().split("=").pop();
          let url_friend_uuid = extension.split("friendUuid").pop().split("=").pop();
          if (url_friend_uuid !== "true" && url_friend_uuid !== "false") {
            dispatch(readAllCategoriesInAllTopics(true, url_friend_uuid));
          } else if (url_favorited !== "true" && url_favorited !== "false") {
            dispatch(readAllCategoriesInAllTopics());
          } else {
            dispatch(readAllCategoriesInAllTopics(url_favorited));
          }
        }
      })
      .catch(error => {
        let errMessage = 'Error updating category';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(deleteUserLinkCategoryError(errMessage));
        dispatch(deleteUserLinkCategoryInProgress(false));
      })
      .finally(() => {
        dispatch(deleteUserLinkCategoryError(''));
        dispatch(deleteUserLinkCategorySuccess(false));
        dispatch(deleteUserLinkCategoryInProgress(false));
      });
    }
  }

  export const toggleCategoryCheck = (categoryId) => ({
    type: types.TOGGLE_CATEGORY_CHECK,
    payload: categoryId,
  });
  
  // Add processServerResponse function
const processServerResponse = (responseData) => {
  try {
    return {
      checkedCategories: responseData.reduce((acc, item) => ({
        ...acc,
        [item.uuid]: item.researched || false
      }), {}),
      userSelectedCategories: responseData
        .filter(item => item.favorited)
        .map(item => item.uuid),
      selectedProducts: responseData.reduce((acc, item) => ({
        ...acc,
        [item.uuid]: item.product_uuid || null
      }), {})
    };
  } catch (error) {
    console.error('Error processing server response:', error);
    return null;
  }
};

// Fix extension parsing with URLSearchParams
const parseExtension = (extension) => {
  try {
    const params = new URLSearchParams(extension);
    return {
      favorited: params.get('favorited'),
      friendUuid: params.get('friendUuid')
    };
  } catch (error) {
    console.error('Error parsing extension:', error);
    return {
      favorited: null,
      friendUuid: null
    };
  }
};


  export const saveCategoryState = (categoryId, isChecked, isFavorited = false) => {
    return async (dispatch, getState) => {
      console.group('💾 saveCategoryState');
      console.log('Saving state for:', { categoryId, isChecked, isFavorited });
      
      const userUuid = sessionStorage.getItem('userUuid');
      const localStorageKey = `categoryStates_${userUuid}`;
      
      console.log('Storage info:', { userUuid, localStorageKey });
  
      try {
        // Log initial state
        const currentState = getState().categories;
        logState('Before Save', currentState);
  
        // Load existing states
        const existingStorage = localStorage.getItem(localStorageKey);
        console.log('Existing localStorage:', existingStorage);
  
        const savedStates = JSON.parse(existingStorage || '{}');
        console.log('Parsed savedStates:', savedStates);
  
        // Update states
        const updatedStates = {
          checkedCategories: {
            ...(savedStates.checkedCategories || {}),
            [categoryId]: isChecked
          },
          userSelectedCategories: isFavorited 
            ? [...new Set([...(savedStates.userSelectedCategories || []), categoryId])]
            : (savedStates.userSelectedCategories || []).filter(id => id !== categoryId),
          selectedProducts: {
            ...(savedStates.selectedProducts || {}),
            ...(currentState.selectedProducts || {})
          }
        };
  
        console.log('Updated states to save:', updatedStates);
  
        // Save to localStorage
        localStorage.setItem(localStorageKey, JSON.stringify(updatedStates));
        console.log('Saved to localStorage. Verification:', localStorage.getItem(localStorageKey));
  
        // Update Redux state
        dispatch({
          type: types.LOAD_SAVED_CATEGORY_STATES,
          payload: updatedStates
        });
  
        // Make API call
        const config = {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          }
        };
  
        const data = {
          categories_uuid: categoryId,
          researched: isChecked,
          favorited: isFavorited
        };
  
        console.log('Making API request with:', { data, config });
  
        try {
          console.log('Attempting PATCH request');
          const patchResponse = await axios.patch(
            `${process.env.REACT_APP_API_URL}/users/categories/${categoryId}`,
            data, 
            config
          );
          console.log('PATCH response:', patchResponse);
        } catch (patchError) {
          console.log('PATCH failed, error:', patchError);
          
          if (patchError.response?.status === 404) {
            console.log('404 received, attempting POST');
            const postResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/users/categories`,
              data,
              config
            );
            console.log('POST response:', postResponse);
          } else {
            throw patchError;
          }
        }
  
        // Log final state
        logState('After Save', getState().categories);
  
      } catch (error) {
        console.error('❌ Error in saveCategoryState:', error);
        console.error('Error response:', error.response);
        throw error;
      } finally {
        console.groupEnd();
      }
    };
  };
  
  export const loadSavedCategoryStates = () => {
    return async (dispatch, getState) => {
      console.group('📥 loadSavedCategoryStates');
      
      try {
        const userUuid = sessionStorage.getItem('userUuid');
        const localStorageKey = `categoryStates_${userUuid}`;
        
        console.log('Storage info:', { 
          userUuid, 
          localStorageKey,
          sessionStorageKeys: Object.keys(sessionStorage)
        });
  
        // Load from localStorage first
        const savedData = localStorage.getItem(localStorageKey);
        console.log('Raw localStorage data:', savedData);
  
        const localStates = savedData ? JSON.parse(savedData) : null;
        console.log('Parsed localStorage states:', localStates);
        
        // Load from server
        console.log('Fetching server states...');
        const serverStates = await loadServerStates(userUuid);
        console.log('Server states received:', serverStates);
  
        console.group('State Merging');
        console.log('Initial states:', {
          local: localStates,
          server: serverStates
        });
  
        // Merge states, preferring non-empty states
        const mergedStates = {
          checkedCategories: {
            ...(localStates?.checkedCategories || {}),
            ...(serverStates?.checkedCategories || {})
          },
          userSelectedCategories: 
            (serverStates?.userSelectedCategories?.length ? 
              serverStates.userSelectedCategories : 
              localStates?.userSelectedCategories) || [],
          selectedProducts: {
            ...(localStates?.selectedProducts || {}),
            ...(serverStates?.selectedProducts || {})
          }
        };
  
        console.log('Merged states:', mergedStates);
        console.log('Merge details:', {
          checkedCategoriesCount: Object.keys(mergedStates.checkedCategories).length,
          userSelectedCategoriesCount: mergedStates.userSelectedCategories.length,
          selectedProductsCount: Object.keys(mergedStates.selectedProducts).length
        });
        console.groupEnd(); // State Merging
  
        // Only save to localStorage if we have actual state
        if (Object.keys(mergedStates.checkedCategories).length > 0 || 
            mergedStates.userSelectedCategories.length > 0 ||
            Object.keys(mergedStates.selectedProducts).length > 0) {
          console.log('Saving merged states to localStorage...');
          localStorage.setItem(localStorageKey, JSON.stringify(mergedStates));
          console.log('Verification - saved data:', localStorage.getItem(localStorageKey));
        } else {
          console.log('No meaningful state to save to localStorage');
        }
  
        console.log('Dispatching LOAD_SAVED_CATEGORY_STATES with payload:', mergedStates);
        dispatch({
          type: LOAD_SAVED_CATEGORY_STATES,
          payload: mergedStates
        });
  
        // Log final Redux state
        console.group('Final State');
        const finalState = getState().categories;
        console.log('Redux state after dispatch:', finalState);
        console.log('State comparison:', {
          merged: mergedStates,
          final: finalState,
          areEqual: JSON.stringify(mergedStates) === JSON.stringify({
            checkedCategories: finalState.checkedCategories,
            userSelectedCategories: finalState.userSelectedCategories,
            selectedProducts: finalState.selectedProducts
          })
        });
        console.groupEnd(); // Final State
  
      } catch (error) {
        console.error('❌ Error in loadSavedCategoryStates:', error);
        console.error('Error details:', {
          message: error.message,
          stack: error.stack,
          state: getState().categories
        });
      } finally {
        console.groupEnd(); // loadSavedCategoryStates
      }
    };
  };
  
  // Helper function to load server states
  const loadServerStates = async (userUuid) => {
    console.group('🌐 loadServerStates');
    try {
      console.log('Making API request for user:', userUuid);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/categories`,
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('jwt')
          }
        }
      );
      console.log('API response:', response.data);
  
      // Process server response into expected format
      const serverStates = processServerResponse(response.data);
      console.log('Processed server states:', serverStates);
      
      return serverStates;
    } catch (error) {
      console.error('Error loading server states:', error);
      console.error('Error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      return null;
    } finally {
      console.groupEnd(); // loadServerStates
    }
  };
  
  // Debounced version
  const debouncedLoadStates = _.debounce((dispatch) => {
    console.log('🕒 Debounced loadSavedCategoryStates called');
    dispatch(loadSavedCategoryStates());
  }, 1000);
  
  // Helper function to log state
  const logState = (label, state) => {
    console.group(`🔍 ${label}`);
    console.log('State:', state);
    console.log('localStorage:', {
      raw: localStorage.getItem(`categoryStates_${sessionStorage.getItem('userUuid')}`),
      parsed: JSON.parse(localStorage.getItem(`categoryStates_${sessionStorage.getItem('userUuid')}`) || '{}')
    });
    console.log('sessionStorage:', {
      jwt: sessionStorage.getItem('jwt'),
      userUuid: sessionStorage.getItem('userUuid')
    });
    console.groupEnd();
  };  

  export const saveRemovedCategories = (removedCategories) => {
    return (dispatch) => {
      localStorage.setItem('removedCategories', JSON.stringify(Array.from(removedCategories)));
      dispatch({
        type: types.SAVE_REMOVED_CATEGORIES,
        payload: removedCategories
      });
    };
  };

  export const loadRemovedCategories = () => {
    return (dispatch) => {
      const savedRemovedCategories = JSON.parse(localStorage.getItem('removedCategories') || '[]');
      dispatch({
        type: types.LOAD_REMOVED_CATEGORIES,
        payload: new Set(savedRemovedCategories)
      });
    };
  };

  export const saveProductState = (categoryId, productId) => async (dispatch) => {
    dispatch({ type: types.UPDATE_USER_LINK_CATEGORY_IN_PROGRESS, payload: true });
    
    try {
      // Update Redux optimistically
      dispatch({
        type: types.UPDATE_USER_LINK_CATEGORY_SUCCESS,
        payload: { categoryId, productId }
      });
  
      // Update localStorage
      const userUuid = sessionStorage.getItem('userUuid');
      const localStorageKey = `categoryStates_${userUuid}`;
      const savedStates = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
      savedStates.selectedProducts = {
        ...savedStates.selectedProducts,
        [categoryId]: productId
      };
      localStorage.setItem(localStorageKey, JSON.stringify(savedStates));
  
      const config = {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        }
      };
  
      const data = {
        categories_uuid: categoryId,
        researched: true,
        favorited: true  // This indicates a product has been selected
      };
  
      try {
        // Try to update first
        await axios.patch(
          `${process.env.REACT_APP_API_URL}/users/categories/${categoryId}`,
          data,
          config
        );
        dispatch({ type: types.UPDATE_USER_LINK_CATEGORY_SUCCESS, payload: true });
      } catch (patchError) {
        // If patch fails (404), try creating new
        if (patchError.response?.status === 404) {
          await axios.post(
            `${process.env.REACT_APP_API_URL}/users/categories`,
            data,
            config
          );
          dispatch({ type: types.CREATE_USER_LINK_CATEGORY_SUCCESS, payload: true });
        } else {
          throw patchError;
        }
      }
      
    } catch (error) {
      console.error('Error saving product state:', error);
      
      // Revert Redux state
      dispatch({
        type: types.UPDATE_USER_LINK_CATEGORY_ERROR,
        payload: error.response?.data?.Error || 'Failed to save product state'
      });
      
      // Revert localStorage
      const userUuid = sessionStorage.getItem('userUuid');
      const localStorageKey = `categoryStates_${userUuid}`;
      const savedStates = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
      if (savedStates.selectedProducts) {
        delete savedStates.selectedProducts[categoryId];
        localStorage.setItem(localStorageKey, JSON.stringify(savedStates));
      }
      
      throw error;
    } finally {
      dispatch({ type: types.UPDATE_USER_LINK_CATEGORY_IN_PROGRESS, payload: false });
    }
  };
  
  
  // Clear category states on logout
  export const clearCategoryStates = () => {
    return (dispatch) => {
      const userUuid = sessionStorage.getItem('userUuid');
      if (userUuid) {
        localStorage.removeItem(`categoryStates_${userUuid}`);
      }
      dispatch({
        type: types.LOAD_SAVED_CATEGORY_STATES,
        payload: {
          checkedCategories: {},
          userSelectedCategories: [],
          selectedProducts: {}
        }
      });
    };
  };

  export const loadSavedProductStates = () => {
    return (dispatch) => {
      const savedStates = JSON.parse(localStorage.getItem('productStates') || '{}');
      dispatch({
        type: types.LOAD_SAVED_PRODUCT_STATES,
        payload: savedStates
      });
    };
  };

  export const setExpandedCategories = (expandedCategories) => ({
    type: types.SET_EXPANDED_CATEGORIES,
    payload: expandedCategories,
  });

  export const setShowSearch = (showSearch) => ({
    type: types.SET_SHOW_SEARCH,
    payload: showSearch,
  });

  export const setShowAddedCategories = (showAddedCategories) => ({
    type: types.SET_SHOW_ADDED_CATEGORIES,
    payload: showAddedCategories,
  });

  export const setSearchLoading = (isLoading) => ({
    type: types.SET_SEARCH_LOADING,
    payload: isLoading,
  });

  export const searchCategories = (searchTerm) => {
    return async (dispatch) => {
      dispatch(setSearchLoading(true));
      try {
        console.log('Searching categories:', searchTerm);
      } catch (error) {
        console.error('Error searching categories:', error);
      } finally {
        dispatch(setSearchLoading(false));
      }
    };
  };

  export function updateCategories(categoriesToAdd, categoriesToRemove) {
    return (dispatch, getState) => {
      const { userSelectedCategories } = getState().categories;

      const updatedCategories = [
        ...userSelectedCategories.filter(cat => !categoriesToRemove.includes(cat)),
        ...categoriesToAdd.filter(cat => !userSelectedCategories.includes(cat))
      ];

      dispatch({
        type: types.UPDATE_USER_CATEGORIES,
        categories: updatedCategories
      });

      axios.post(`${process.env.REACT_APP_API_URL}/user/categories`, { categories: updatedCategories })
        .then(() => {
          dispatch({
            type: types.SAVE_USER_CATEGORIES_SUCCESS
          });
        })
        .catch(error => {
          dispatch({
            type: types.SAVE_USER_CATEGORIES_ERROR,
            error: error.message
          });
        });
    };
  }

  export function updateUserCategories(categoriesToAdd, categoriesToRemove) {
    return (dispatch, getState) => {
      const { userSelectedCategories } = getState().categories;

      const updatedCategories = [
        ...userSelectedCategories.filter(cat => !categoriesToRemove.includes(cat)),
        ...categoriesToAdd.filter(cat => !userSelectedCategories.includes(cat))
      ];

      dispatch({
        type: types.UPDATE_USER_CATEGORIES,
        categories: updatedCategories
      });
    };
  }
