import * as types from './actionTypes';
import initialState from './initialState';

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PHOTO_UPLOAD_MODAL_VISIBLE:
      return { ...state, photoUploadModalVisible: action.payload };
    case types.SET_TUTORIAL_STEP:
      return { ...state, tutorialStep: action.payload };
    case types.SET_EDITABLE_INPUT_ID:
      return { ...state, editableInputId: action.payload };
    case types.SET_SHOW_REMOVED_TASKS:
      return { ...state, showRemovedTasks: action.payload };
    case types.SET_IS_LOADING_SHARED_NOTES:
      return { ...state, isLoadingSharedNotes: action.payload };
    case types.SET_CURRENT_TAB:
      return { ...state, currentTab: action.payload };
    
    // Database testing reducers
    case types.FETCH_TABLE_DATA_REQUEST:
      return {
        ...state,
        databaseTestState: {
          ...state.databaseTestState,
          loading: true,
          error: null
        }
      };

    case types.FETCH_TABLE_DATA_SUCCESS:
      const updatedTables = [...state.databaseTestState.tables];
      const tableIndex = updatedTables.findIndex(table => table.name === action.payload.tableName);
      
      if (tableIndex !== -1) {
        updatedTables[tableIndex] = {
          ...updatedTables[tableIndex],
          records: action.payload.records,
          fetchTime: action.payload.fetchTime
        };
      } else {
        // Find the table definition
        const tableDefinition = state.databaseTestState.tableDefinitions.find(table => table.name === action.payload.tableName);
        if (tableDefinition) {
          updatedTables.push({
            ...tableDefinition,
            records: action.payload.records,
            fetchTime: action.payload.fetchTime
          });
        }
      }

      return {
        ...state,
        databaseTestState: {
          ...state.databaseTestState,
          loading: false,
          tables: updatedTables
        }
      };

    case types.FETCH_TABLE_DATA_FAILURE:
      const failedTablesUpdate = [...state.databaseTestState.tables];
      const failedTableIndex = failedTablesUpdate.findIndex(table => table.name === action.payload.tableName);
      
      if (failedTableIndex !== -1) {
        failedTablesUpdate[failedTableIndex] = {
          ...failedTablesUpdate[failedTableIndex],
          error: action.payload.error,
          fetchTime: action.payload.fetchTime
        };
      } else {
        // Find the table definition
        const tableDefinition = state.databaseTestState.tableDefinitions.find(table => table.name === action.payload.tableName);
        if (tableDefinition) {
          failedTablesUpdate.push({
            ...tableDefinition,
            records: [],
            error: action.payload.error,
            fetchTime: action.payload.fetchTime
          });
        }
      }

      return {
        ...state,
        databaseTestState: {
          ...state.databaseTestState,
          loading: false,
          error: action.payload.error,
          tables: failedTablesUpdate
        }
      };

    case types.CREATE_TEST_RECORD_REQUEST:
      return {
        ...state,
        databaseTestState: {
          ...state.databaseTestState,
          processingTable: action.payload.tableName
        }
      };

    case types.CREATE_TEST_RECORD_SUCCESS:
      const tablesAfterCreate = [...state.databaseTestState.tables];
      const createTableIndex = tablesAfterCreate.findIndex(table => table.name === action.payload.tableName);
      
      if (createTableIndex !== -1) {
        tablesAfterCreate[createTableIndex] = {
          ...tablesAfterCreate[createTableIndex],
          records: [...tablesAfterCreate[createTableIndex].records, action.payload.record]
        };
      }

      return {
        ...state,
        databaseTestState: {
          ...state.databaseTestState,
          processingTable: null,
          tables: tablesAfterCreate
        }
      };

    case types.CREATE_TEST_RECORD_FAILURE:
      return {
        ...state,
        databaseTestState: {
          ...state.databaseTestState,
          processingTable: null,
          error: action.payload.error
        }
      };

    case types.ADD_DATABASE_LOG:
      return {
        ...state,
        databaseTestState: {
          ...state.databaseTestState,
          apiLogs: [action.payload, ...state.databaseTestState.apiLogs].slice(0, 100) // Keep only the latest 100 logs
        }
      };

    case types.CLEAR_DATABASE_LOGS:
      return {
        ...state,
        databaseTestState: {
          ...state.databaseTestState,
          apiLogs: []
        }
      };

    case types.SET_PROCESSING_TABLE:
      return {
        ...state,
        databaseTestState: {
          ...state.databaseTestState,
          processingTable: action.payload
        }
      };

    case types.SET_SHOW_LOGS:
      return {
        ...state,
        databaseTestState: {
          ...state.databaseTestState,
          showLogs: action.payload
        }
      };

    case 'SET_IS_ADD_MOMENT_EXPANDED':
      return { ...state, isAddMomentExpanded: action.payload };
    
    case 'SET_SHOW_TUTORIAL':
      return { ...state, showTutorial: action.payload };

    default:
      return state;
  }
};

export default uiReducer;
