import React, { useState, useEffect } from 'react';
import { Modal, Table, Tabs, Typography, Button, Collapse, message, Spin, Tag } from 'antd';
import { DatabaseOutlined, TableOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { readAllCategories } from '../redux/categories/actions';
import { readProductsInCategory, readCategoriesWithProducts } from '../redux/products/actions';
import { readAllTopics, readAllCategoriesInAllTopics } from '../redux/topics/actions';
import { readUserLists, createList } from '../redux/lists/actions';
import * as types from '../redux/lists/actionTypes';
import axios from 'axios';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const DatabaseTestView = ({ 
  visible, 
  onClose, 
  // Redux state
  categories,
  products,
  topics,
  lists,
  categoriesWithProducts,
  productsInCategory,
  allCategories,
  allTopics,
  userLists,
  allCategoriesInTopics,
  // Loading states
  allCategoriesLoading,
  productsLoading,
  categoriesWithProductsLoading,
  topicsLoading,
  listsLoading,
  categoriesInTopicsLoading,
  // List action states
  createListInProcess,
  createListError,
  // Redux actions
  readAllCategories,
  readProductsInCategory,
  readCategoriesWithProducts,
  readAllTopics,
  readAllCategoriesInAllTopics,
  readUserLists,
  createList
}) => {
  const [processingTable, setProcessingTable] = useState(null);
  const [apiLogs, setApiLogs] = useState([]);
  const [showLogs, setShowLogs] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tables, setTables] = useState([]);
  const [error, setError] = useState(null);
  const [categoryProducts, setCategoryProducts] = useState({});
  const [loadingCategoryProducts, setLoadingCategoryProducts] = useState(false);
  const [groupLogs, setGroupLogs] = useState(false); // State for log grouping option
  
  // Helper function to add a log entry - defined early to ensure it's available
  const addLogEntry = (type, message, details = null) => {
    const timestamp = new Date().toISOString();
    const logEntry = {
      id: Date.now() + Math.random(),
      timestamp,
      type, // 'info', 'success', 'error', 'warning'
      message,
      details
    };
    
    setApiLogs(prev => [logEntry, ...prev].slice(0, 500)); // Increase limit to 500 logs to keep more history
    console.log(`[${type.toUpperCase()}] ${message}`, details || '');
  };
  
  // Debug function to check for permission records in the database
  const debugPermissions = async () => {
    addLogEntry('info', 'DEBUG: Checking permissions in database');
    
    try {
      const jwt = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');
      if (!jwt) {
        addLogEntry('error', 'No JWT token found - cannot check permissions');
        return;
      }
      
      // Check permissions table - this will likely fail because permissions endpoint doesn't exist
      addLogEntry('info', 'Checking permissions endpoint (expected to 404)');
      try {
        const permissionsUrl = `${process.env.REACT_APP_API_URL}/permissions`;
        const permissionsResponse = await fetch(permissionsUrl, {
          headers: { Authorization: `Bearer ${jwt}` }
        });
        
        if (permissionsResponse.ok) {
          const permissions = await permissionsResponse.json();
          addLogEntry('success', `Found ${permissions.length} permissions in database`, permissions);
          
          if (permissions.length > 0) {
            const availableKeys = permissions.map(p => p.key).sort();
            addLogEntry('info', 'Available permission keys in database', availableKeys);
          }
        } else if (permissionsResponse.status === 404) {
          addLogEntry('info', `Permissions endpoint not found (404) - this is expected`, 
            'The backend does not implement a GET /permissions endpoint');
          
          // Since we can't get permissions directly, provide guidance based on what we know
          addLogEntry('info', 'Using permission=2 is recommended based on backend code review',
            'The lists.py implementation suggests keys 0, 1, 2, 3 exist or will be created as needed');
        } else {
          const errorText = await permissionsResponse.text();
          addLogEntry('error', `Failed to fetch permissions: ${permissionsResponse.status}`, errorText);
        }
      } catch (error) {
        addLogEntry('error', `Exception checking permissions: ${error.message}`);
      }
      
      // Now check user permissions linkage - this will likely fail because endpoint doesn't exist
      addLogEntry('info', 'Checking users_link_permissions table (expected to 404)');
      try {
        const linkPermissionsUrl = `${process.env.REACT_APP_API_URL}/users_link_permissions`;
        const linkPermissionsResponse = await fetch(linkPermissionsUrl, {
          headers: { Authorization: `Bearer ${jwt}` }
        });
        
        if (linkPermissionsResponse.ok) {
          const linkPermissions = await linkPermissionsResponse.json();
          addLogEntry('success', `Found ${linkPermissions.length} user-permission links in database`, linkPermissions);
        } else if (linkPermissionsResponse.status === 404) {
          addLogEntry('info', `User permissions endpoint not found (404) - this is expected`,
            'The backend does not implement a GET /users_link_permissions endpoint');
        } else {
          const errorText = await linkPermissionsResponse.text();
          addLogEntry('error', `Failed to fetch user permission links: ${linkPermissionsResponse.status}`, errorText);
        }
      } catch (error) {
        addLogEntry('error', `Exception checking user permission links: ${error.message}`);
      }
      
      // Check if any lists exist already to understand what permissions they use
      addLogEntry('info', 'Checking existing lists to understand their permission settings');
      try {
        // Important: Make sure we're using the correct endpoint
        // In the routes.py, we have 'read_user_lists', 'lists/user' vs 'lists'
        const listsUrl = `${process.env.REACT_APP_API_URL}/lists/user`;
        addLogEntry('info', `Making API call to ${listsUrl} to fetch user lists`);
        
        const listsResponse = await fetch(listsUrl, {
          headers: { Authorization: `Bearer ${jwt}` }
        });
        
        if (listsResponse.ok) {
          const lists = await listsResponse.json();
          if (!Array.isArray(lists)) {
            addLogEntry('warning', `Lists response is not an array`, lists);
          } else {
            addLogEntry('success', `Found ${lists.length} lists in database`);
            
            if (lists.length > 0) {
              // Extract permission values from existing lists
              const permissionValues = lists.map(l => l.permissions);
              const permissionCounts = {};
              
              // Count occurrences of each permission value
              permissionValues.forEach(perm => {
                permissionCounts[perm] = (permissionCounts[perm] || 0) + 1;
              });
              
              addLogEntry('info', 'Existing lists use these permission values', permissionCounts);
              
              // Recommend using the most common permission value
              if (Object.keys(permissionCounts).length > 0) {
                const mostCommonPerm = Object.entries(permissionCounts)
                  .sort((a, b) => b[1] - a[1])[0][0];
                  
                addLogEntry('info', `RECOMMENDED: Use permissions=${mostCommonPerm} for list creation (most common in existing lists)`);
              }
            }
          }
        } else if (listsResponse.status === 404) {
          addLogEntry('info', `Lists endpoint returned 404 - trying alternate endpoint`);
          
          // Try an alternate endpoint
          const altListsUrl = `${process.env.REACT_APP_API_URL}/lists`;
          const altResponse = await fetch(altListsUrl, {
            headers: { Authorization: `Bearer ${jwt}` }
          });
          
          if (altResponse.ok) {
            const altLists = await altResponse.json();
            if (Array.isArray(altLists)) {
              addLogEntry('success', `Found ${altLists.length} lists using alternate endpoint`);
            } else {
              addLogEntry('warning', `Alternate lists response is not an array`, altLists);
            }
          } else {
            addLogEntry('warning', `Both lists endpoints failed - using permission=2 by default`);
          }
        } else {
          const errorText = await listsResponse.text();
          addLogEntry('error', `Failed to fetch lists: ${listsResponse.status}`, errorText);
        }
      } catch (error) {
        addLogEntry('error', `Exception checking lists: ${error.message}`);
      }
      
      // Direct endpoint call to check users
      addLogEntry('info', 'Making direct API call to /users to check user records');
      try {
        const usersUrl = `${process.env.REACT_APP_API_URL}/users`;
        const usersResponse = await fetch(usersUrl, {
          headers: { Authorization: `Bearer ${jwt}` }
        });
        
        if (usersResponse.ok) {
          // Handle response - can be either a single user object or an array
          const responseText = await usersResponse.text();
          
          try {
            // Try to parse the response
            const userData = responseText ? JSON.parse(responseText) : null;
            
            // Check if response is array
            if (Array.isArray(userData)) {
              addLogEntry('success', `Found ${userData.length} users in database`);
              
              // Extract a clean version of user data for display
              const cleanUserData = userData.map(u => ({ 
                uuid: u.uuid, 
                email: u.email, 
                name: `${u.first_name} ${u.last_name}` 
              }));
              
              addLogEntry('info', 'Users found', cleanUserData);
              
              // Check if current user is in the list
              if (userData.length > 0) {
                // Try to get user ID from JWT
                try {
                  const tokenParts = jwt.split('.');
                  if (tokenParts.length === 3) {
                    const payload = JSON.parse(atob(tokenParts[1]));
                    const userId = payload.sub;
                    
                    if (userId) {
                      const currentUser = userData.find(u => u.uuid === userId);
                      if (currentUser) {
                        addLogEntry('success', 'Found authenticated user in database', {
                          uuid: currentUser.uuid,
                          email: currentUser.email,
                          name: `${currentUser.first_name} ${currentUser.last_name}`
                        });
                      } else {
                        addLogEntry('warning', 'Authenticated user not found in database!', {
                          jwtUserId: userId,
                          availableUserIds: userData.map(u => u.uuid)
                        });
                      }
                    }
                  }
                } catch (e) {
                  addLogEntry('warning', `Failed to verify current user: ${e.message}`);
                }
              }
            } else if (userData && userData.uuid) {
              // Single user object
              addLogEntry('success', 'Found single user in response', {
                uuid: userData.uuid,
                email: userData.email,
                name: `${userData.first_name} ${userData.last_name}` 
              });
            } else {
              addLogEntry('warning', 'Unexpected user data format', userData);
            }
          } catch (parseError) {
            addLogEntry('error', `Failed to parse user response: ${parseError.message}`, responseText.substring(0, 200));
          }
        } else {
          const errorText = await usersResponse.text();
          addLogEntry('error', `Failed to fetch users: ${usersResponse.status}`, errorText);
        }
      } catch (error) {
        addLogEntry('error', `Exception checking users: ${error.message}`);
      }
    } catch (error) {
      addLogEntry('error', `Error checking permissions: ${error.message}`, error);
    }
  };


  // Track if we've already initialized the component to prevent multiple data loads
  const initializedRef = React.useRef(false);

// First useEffect to initialize table structure when the component becomes visible
useEffect(() => {
  if (visible && !initializedRef.current) {
    // Mark as initialized to prevent multiple initializations
    initializedRef.current = true;
    
    // Show logs by default
    setShowLogs(true);
    
    // Initialize table structures
    initializeTablesFromReduxState();
    
    // Add debug logs to analyze the database state
    addLogEntry('info', 'Running initial diagnostics for database connection...');
    
    // Function to sync user data from database to local state
    const syncUserData = async () => {
      addLogEntry('info', 'Attempting to sync user data from database to local state');
      
      try {
        // Get JWT token
        const jwt = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');
        
        if (!jwt) {
          addLogEntry('error', 'No JWT token found - cannot sync user data');
          return;
        }
        
        // Directly fetch user data
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
          headers: { Authorization: `Bearer ${jwt}` }
        });
        
        if (!response.ok) {
          addLogEntry('error', `Failed to fetch user data: ${response.status} ${response.statusText}`);
          return;
        }
        
        const responseText = await response.text();
        if (!responseText) {
          addLogEntry('error', 'Empty response from users endpoint');
          return;
        }
        
        const data = JSON.parse(responseText);
        
        // If we got back a single user object, wrap it in an array
        const userData = !Array.isArray(data) ? [data] : data;
        
        // Manually update the 'users' table in our local state
        setTables(prevTables => {
          const updatedTables = [...prevTables];
          const usersTableIndex = updatedTables.findIndex(t => t.name === 'users');
          
          if (usersTableIndex !== -1) {
            updatedTables[usersTableIndex] = {
              ...updatedTables[usersTableIndex],
              records: userData,
              fetchTime: new Date().toISOString()
            };
          }
          
          return updatedTables;
        });
        
        addLogEntry('success', `User data synced successfully: ${userData.length} users found`, userData);
      } catch (error) {
        addLogEntry('error', `Error syncing user data: ${error.message}`);
      }
    };
    
    // Run token diagnostics first
    debugTokens().then(() => {
      // Then try to sync user data
      syncUserData();
    });
    
    // Use a flag to prevent duplicate data fetching
    const dataAlreadyLoaded = 
      (categories?.allCategories?.length > 0) || 
      (topics?.allTopics?.length > 0) || 
      (categoriesWithProducts?.length > 0);
    
    if (!dataAlreadyLoaded) {
      // Fetch data using Redux actions for all required tables - only if not already loaded
      console.log('Fetching Redux data for the first time');
      addLogEntry('info', 'DatabaseTestView initialized and fetching initial data...');
      
      // Add a small delay to prevent multiple simultaneous fetches
      setTimeout(() => {
        readAllCategories();
        readCategoriesWithProducts(null, null, null, 1, 100);
        readAllTopics();
        readUserLists();
        readAllCategoriesInAllTopics();
      }, 100);
    } else {
      console.log('Data already loaded, skipping initial fetch');
      addLogEntry('info', 'Using existing Redux data - skipping initial fetch');
    }
  }
}, [visible]);
  
  // Direct API function to get products for a category without using Redux
  const fetchProductsDirectly = async (categoryId, categoryName) => {
    setLoadingCategoryProducts(true);
    addLogEntry('info', `Directly fetching products for category '${categoryName}' (${categoryId})`);
    
    try {
      const url = `${process.env.REACT_APP_API_URL}/categories/${categoryId}/products`;
      addLogEntry('info', `Making API request to: ${url}`);
      
      const jwt = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${jwt}` }
      });
      
      if (response.data && Array.isArray(response.data)) {
        addLogEntry('success', `Successfully fetched ${response.data.length} products for category '${categoryName}'`);
        
        // Update the category products state
        setCategoryProducts(prev => ({
          ...prev,
          [categoryId]: {
            categoryName,
            products: response.data
          }
        }));
        
        return response.data;
      } else {
        addLogEntry('warning', `No products found for category '${categoryName}'`);
        return [];
      }
    } catch (error) {
      addLogEntry('error', `Error fetching products for category '${categoryName}': ${error.message}`);
      return [];
    }
  };
  
  // Using a ref to prevent duplicate category product loading
  const categoryProductsLoadedRef = React.useRef(false);
  
  // When categories are loaded, fetch products for the first two categories - ONCE only
  useEffect(() => {
    const loadCategoryProducts = async () => {
      // Skip if already loaded or no categories available
      if (!categories || !categories.allCategories || categories.allCategories.length === 0) {
        return;
      }
      
      // Check if we've already loaded products
      if (categoryProductsLoadedRef.current || Object.keys(categoryProducts).length > 0) {
        console.log('Category products already loaded, skipping fetch');
        return;
      }
      
      // Mark as loaded to prevent repeated fetches
      categoryProductsLoadedRef.current = true;
      
      setLoadingCategoryProducts(true);
      addLogEntry('info', 'Starting to fetch products for first two categories');
      
      try {
        // Get the first two categories
        const firstTwoCategories = categories.allCategories.slice(0, 2);
        
        // Log which categories we're fetching
        addLogEntry('info', `Selected categories: ${firstTwoCategories.map(c => c.name).join(', ')}`);
        
        // Fetch products for each category directly
        const results = await Promise.all(
          firstTwoCategories.map(category => 
            fetchProductsDirectly(category.uuid, category.name)
          )
        );
        
        addLogEntry('success', `Completed fetching products for ${results.filter(r => r.length > 0).length} categories`);
      } catch (error) {
        addLogEntry('error', `Error in loadCategoryProducts: ${error.message}`);
      } finally {
        setLoadingCategoryProducts(false);
      }
    };
    
    if (categories && categories.allCategories && categories.allCategories.length > 0) {
      loadCategoryProducts();
    }
  }, [categories, categoryProducts]);
  
  // Function to initialize tables from Redux state
  // Function to clean up mock data from tables
  const cleanupMockData = () => {
    message.loading('Cleaning up mock data...', 0.5);
    console.log('Cleaning up mock data');
    
    // Flag to track if we found any mock data
    let foundMockData = false;
    
    setTables(prevTables => {
      const updatedTables = [...prevTables];
      
      // Check each table for mock entries and remove them
      updatedTables.forEach((table, index) => {
        if (table.records && Array.isArray(table.records)) {
          // Find mock records (either tagged with _is_mock or with local- prefix)
          const mockRecords = table.records.filter(r => 
            r._is_mock === true || 
            r.uuid?.toString()?.startsWith('local-') || 
            r.lists_uuid?.toString()?.startsWith('local-')
          );
          
          if (mockRecords.length > 0) {
            foundMockData = true;
            console.log(`Found ${mockRecords.length} mock records in ${table.name}, removing them`);
            addLogEntry('info', `Removing ${mockRecords.length} mock records from ${table.name}`);
            
            // Filter out mock records from this table
            updatedTables[index] = {
              ...table,
              records: table.records.filter(r => 
                !(r._is_mock === true || 
                  r.uuid?.toString()?.startsWith('local-') || 
                  r.lists_uuid?.toString()?.startsWith('local-'))
              ),
              fetchTime: new Date().toISOString()
            };
          }
        }
      });
      
      return updatedTables;
    });
    
    // Show message after slight delay to allow state update
    setTimeout(() => {
      if (foundMockData) {
        message.success('Mock data has been cleaned up');
        addLogEntry('success', 'Successfully removed all mock data');
      } else {
        message.info('No mock data found to clean up');
        addLogEntry('info', 'No mock data found to clean up');
      }
    }, 500);
  };

  const initializeTablesFromReduxState = () => {
    console.log('Initializing table definitions');
    addLogEntry('info', 'Initializing database table definitions');
    
    // Clean up any existing mock data
    setTimeout(() => {
      cleanupMockData();
    }, 1000);
    
    // Define the tables we want to fetch using Redux
    // Note: We're normalizing endpoints to a consistent format without /api/v1 prefix
    const tableDefinitions = [
      {
        name: 'lists',
        description: 'Stores the main list information',
        endpoint: '/lists',
        columns: [
          { name: 'uuid', type: 'uuid', primary: true },
          { name: 'name', type: 'character varying(255)' },
          { name: 'type', type: 'character varying(255)' },
          { name: 'permissions', type: 'integer' },
          { name: 'inserted_at', type: 'timestamp with time zone' },
          { name: 'updated_at', type: 'timestamp with time zone' }
        ]
      },
      {
        name: 'list_items',
        description: 'Contains items within each list',
        endpoint: '/list_items',
        columns: [
          { name: 'uuid', type: 'uuid', primary: true },
          { name: 'lists_uuid', type: 'uuid', foreign: 'lists.uuid' },
          { name: 'entity_uuid', type: 'uuid' },
          { name: 'inserted_at', type: 'timestamp with time zone' },
          { name: 'updated_at', type: 'timestamp with time zone' }
        ]
      },
      {
        name: 'users',
        description: 'Stores user information',
        endpoint: '/users',
        columns: [
          { name: 'uuid', type: 'uuid', primary: true },
          { name: 'email', type: 'character varying(255)' },
          { name: 'first_name', type: 'character varying(255)' },
          { name: 'last_name', type: 'character varying(255)' },
          { name: 'password_hash', type: 'character varying(1024)' },
          { name: 'inserted_at', type: 'timestamp with time zone' },
          { name: 'updated_at', type: 'timestamp with time zone' }
        ]
      },
      {
        name: 'users_link_lists',
        description: 'Associates users with the lists they own',
        endpoint: '/users_link_lists',
        columns: [
          { name: 'uuid', type: 'uuid', primary: true },
          { name: 'users_uuid', type: 'uuid', foreign: 'users.uuid' },
          { name: 'lists_uuid', type: 'uuid', foreign: 'lists.uuid' },
          { name: 'inserted_at', type: 'timestamp with time zone' },
          { name: 'updated_at', type: 'timestamp with time zone' }
        ]
      },
      {
        name: 'users_link_suggested_lists',
        description: 'Suggested lists for users',
        endpoint: '/users_link_suggested_lists',
        columns: [
          { name: 'uuid', type: 'uuid', primary: true },
          { name: 'users_uuid', type: 'uuid', foreign: 'users.uuid' },
          { name: 'lists_uuid', type: 'uuid', foreign: 'lists.uuid' },
          { name: 'source', type: 'character varying(255)' },
          { name: 'status', type: 'character varying(255)' },
          { name: 'priority', type: 'integer' },
          { name: 'context_data', type: 'jsonb' },
          { name: 'inserted_at', type: 'timestamp with time zone' },
          { name: 'updated_at', type: 'timestamp with time zone' }
        ]
      },
      {
        name: 'categories',
        description: 'Stores product categories',
        endpoint: '/categories',
        columns: [
          { name: 'uuid', type: 'uuid', primary: true },
          { name: 'name', type: 'character varying(255)' },
          { name: 'description', type: 'text' },
          { name: 'inserted_at', type: 'timestamp with time zone' },
          { name: 'updated_at', type: 'timestamp with time zone' }
        ]
      },
      {
        name: 'products',
        description: 'Stores product information',
        endpoint: '/products',
        columns: [
          { name: 'uuid', type: 'uuid', primary: true },
          { name: 'name', type: 'character varying(255)' },
          { name: 'description', type: 'text' },
          { name: 'category_uuid', type: 'uuid', foreign: 'categories.uuid' },
          { name: 'inserted_at', type: 'timestamp with time zone' },
          { name: 'updated_at', type: 'timestamp with time zone' }
        ]
      },
      {
        name: 'topics',
        description: 'Stores topic information',
        endpoint: '/topics',
        columns: [
          { name: 'uuid', type: 'uuid', primary: true },
          { name: 'name', type: 'character varying(255)' },
          { name: 'description', type: 'text' },
          { name: 'inserted_at', type: 'timestamp with time zone' },
          { name: 'updated_at', type: 'timestamp with time zone' }
        ]
      }
    ];
    
    // Initialize tables
    setTables(tableDefinitions.map(table => ({
      ...table,
      records: []
    })));
    
    // Clear logs
    setApiLogs([]);
    addLogEntry('info', 'Starting to fetch database information using Redux...');
    
    // Set loading state
    setLoading(true);
  };
  
  // Use a ref to track the last update time to prevent excessive updates
  const lastUpdateRef = React.useRef(Date.now());
  const updateCountRef = React.useRef(0);
  
  // Update tables when Redux data changes - with reduced frequency
  useEffect(() => {
    // Skip updates if modal is not visible
    if (!visible || !tables || tables.length === 0) return;
    
    // Check if it's been at least 3 seconds since the last update
    const now = Date.now();
    const timeSinceLastUpdate = now - lastUpdateRef.current;
    const updateNeeded = timeSinceLastUpdate > 3000;
    
    // Only update if enough time has passed or on first load
    if (updateNeeded || updateCountRef.current < 1) {
      // Create a debounced update function to prevent excessive updates
      const debounceTimer = setTimeout(() => {
        // Track update count
        updateCountRef.current++;
        lastUpdateRef.current = Date.now();
        
        console.log(`Redux update #${updateCountRef.current} (${timeSinceLastUpdate}ms since last update)`);
        
        // Only update tables if we already have table definitions
        updateTablesWithReduxData();
        
        // Log Redux state for debugging (less detailed to reduce noise)
        console.log('Redux data loaded:', { 
          categories: categories?.allCategories?.length || 0,
          topics: topics?.allTopics?.length || 0, 
          lists: lists?.userLists?.length || 0
        });
      }, 500); // 500ms debounce time
      
      // Clean up the timeout when dependencies change
      return () => clearTimeout(debounceTimer);
    }
  }, [visible, tables, categories, products, topics, lists, categoriesWithProducts, productsInCategory]);
  
  // Function to update tables with Redux data
  const updateTablesWithReduxData = () => {
    if (!tables || tables.length === 0) {
      addLogEntry('warning', 'Cannot update tables - table definitions not yet initialized');
      return;
    }
    
    // Check if data has changed before updating
    const hasChanges = 
      categories?.allCategories?.length !== tables.find(t => t.name === 'categories')?.records?.length ||
      categoriesWithProducts?.length !== tables.find(t => t.name === 'products')?.records?.length ||
      topics?.allTopics?.length !== tables.find(t => t.name === 'topics')?.records?.length ||
      lists?.userLists?.length !== tables.find(t => t.name === 'lists')?.records?.length;
      
    if (!hasChanges) {
      // Skip update if no meaningful changes detected
      console.log('No meaningful changes detected in Redux data, skipping update');
      return;
    }
    
    // Only add a log entry the first few times or occasionally
    const shouldLog = updateCountRef.current <= 3 || updateCountRef.current % 5 === 0;
    
    // Create a copy of the current tables
    const updatedTables = [...tables];
    
    // Update categories table if data is available
    if (categories && categories.allCategories) {
      const categoryTableIndex = updatedTables.findIndex(t => t.name === 'categories');
      if (categoryTableIndex !== -1) {
        updatedTables[categoryTableIndex] = {
          ...updatedTables[categoryTableIndex],
          records: categories.allCategories,
          fetchTime: new Date().toISOString()
        };
        
        if (shouldLog) {
          addLogEntry('success', `Updated categories table from Redux store: ${categories.allCategories.length} records`);
        }
      }
    }
    
    // Update products table if data is available
    if (categoriesWithProducts && categoriesWithProducts.length > 0) {
      // Extract all products from categoriesWithProducts
      const allProducts = [];
      categoriesWithProducts.forEach(category => {
        if (category.products && Array.isArray(category.products)) {
          allProducts.push(...category.products);
        }
      });
      
      const productTableIndex = updatedTables.findIndex(t => t.name === 'products');
      if (productTableIndex !== -1 && allProducts.length > 0) {
        updatedTables[productTableIndex] = {
          ...updatedTables[productTableIndex],
          records: allProducts,
          fetchTime: new Date().toISOString()
        };
        
        if (shouldLog) {
          addLogEntry('success', `Updated products table from Redux store: ${allProducts.length} records`);
        }
      }
    }
    
    // Update topics table if data is available
    if (topics && topics.allTopics) {
      const topicTableIndex = updatedTables.findIndex(t => t.name === 'topics');
      if (topicTableIndex !== -1) {
        updatedTables[topicTableIndex] = {
          ...updatedTables[topicTableIndex],
          records: topics.allTopics,
          fetchTime: new Date().toISOString()
        };
        
        if (shouldLog) {
          addLogEntry('success', `Updated topics table from Redux store: ${topics.allTopics.length} records`);
        }
      }
    }
    
    // Update lists table if data is available
    if (lists && lists.userLists) {
      const listsTableIndex = updatedTables.findIndex(t => t.name === 'lists');
      if (listsTableIndex !== -1) {
        updatedTables[listsTableIndex] = {
          ...updatedTables[listsTableIndex],
          records: lists.userLists,
          fetchTime: new Date().toISOString()
        };
        
        if (shouldLog) {
          addLogEntry('success', `Updated lists table from Redux store: ${lists.userLists.length} records`);
        }
      }
    }
    
    // Update the tables state only if we have tables
    if (updatedTables.length > 0) {
      setTables(updatedTables);
      console.log('Updated tables with data:', updatedTables.map(t => ({ name: t.name, recordCount: t.records?.length || 0 })));
    }
    
    // Count how many tables have data
    let dataLoadedCount = 0;
    if (categories && categories.allCategories) dataLoadedCount++;
    if (categoriesWithProducts && categoriesWithProducts.length > 0) dataLoadedCount++;
    if (topics && topics.allTopics) dataLoadedCount++;
    if (lists && lists.userLists) dataLoadedCount++;
    
    // Turn off loading state once data starts coming in
    if (dataLoadedCount > 0) {
      setLoading(false);
      if (shouldLog && dataLoadedCount >= 3) {
        addLogEntry('success', `${dataLoadedCount}/4 data sources loaded from Redux store successfully`);
      }
    }
  };
  
  // Clear logs helper
  const clearLogs = () => {
    setApiLogs([]);
    addLogEntry('info', 'Logs cleared');
  };
  
  // Function to copy logs to clipboard for sharing
  const copyLogsToClipboard = () => {
    try {
      // Prepare the detailed logs data
      const detailedData = {
        timestamp: new Date().toISOString(),
        tables: tables.map(table => ({
          name: table.name,
          endpoint: table.endpoint,
          recordCount: table.records?.length || 0,
          error: table.error,
          fetchTime: table.fetchTime
        })),
        logs: apiLogs.map(log => ({
          type: log.type,
          timestamp: log.timestamp,
          message: log.message,
          details: log.details
        }))
      };
      
      // Create a formatted clipboard text
      const clipboardText = `
DATABASE TEST RESULTS - ${new Date().toLocaleString()}
=============================================

API ENDPOINTS SUMMARY:
=====================
${tables.map(t => `- ${t.name}: ${t.endpoint} - ${t.records?.length || 0} records${t.error ? ` (ERROR: ${t.error})` : ''}`).join('\n')}

DETAILED LOGS:
=============
${apiLogs.map(log => `[${log.type.toUpperCase()}] ${new Date(log.timestamp).toLocaleTimeString()} - ${log.message}${log.details ? '\n    Details: ' + (typeof log.details === 'string' ? log.details : JSON.stringify(log.details)) : ''}`).join('\n\n')}

RECORDS FOUND:
=============
${tables.filter(t => t.records && t.records.length > 0)
  .map(t => `${t.name}: ${t.records.length} records (First record: ${JSON.stringify(t.records[0], null, 2)})`)
  .join('\n\n')
}

EMPTY TABLES:
============
${tables.filter(t => !t.records || t.records.length === 0)
  .map(t => `${t.name}: No records found (${t.endpoint})`)
  .join('\n')}

ERROR COUNT: ${apiLogs.filter(log => log.type === 'error').length}
WARNING COUNT: ${apiLogs.filter(log => log.type === 'warning').length}
SUCCESS COUNT: ${apiLogs.filter(log => log.type === 'success').length}
      `;
      
      // Copy to clipboard
      navigator.clipboard.writeText(clipboardText)
        .then(() => {
          message.success('Logs copied to clipboard!');
          addLogEntry('success', 'Logs copied to clipboard');
        })
        .catch(err => {
          console.error('Failed to copy to clipboard:', err);
          message.error('Failed to copy logs to clipboard: ' + err.message);
          
          // As a fallback, create a textarea to manually copy
          const textarea = document.createElement('textarea');
          textarea.value = clipboardText;
          textarea.style.position = 'fixed';
          textarea.style.top = 0;
          textarea.style.left = 0;
          textarea.style.opacity = 0;
          document.body.appendChild(textarea);
          textarea.focus();
          textarea.select();
          
          try {
            document.execCommand('copy');
            message.success('Logs copied to clipboard (fallback method)');
          } catch (e) {
            message.error('Could not copy logs. Please try again.');
          }
          
          document.body.removeChild(textarea);
        });
      
    } catch (error) {
      console.error('Error copying logs to clipboard:', error);
      message.error('Failed to copy logs to clipboard');
    }
  };

  // Function to debug token state
  const debugTokens = async () => {
    addLogEntry('info', 'DEBUG: Checking token state');
    
    // Check tokens in both storage locations
    const localJwt = localStorage.getItem('jwt');
    const sessionJwt = sessionStorage.getItem('jwt');
    
    addLogEntry('info', `Token in localStorage: ${localJwt ? 'EXISTS' : 'MISSING'}`);
    addLogEntry('info', `Token in sessionStorage: ${sessionJwt ? 'EXISTS' : 'MISSING'}`);
    
    if (localJwt) {
      // Try to decode JWT
      try {
        const parts = localJwt.split('.');
        if (parts.length === 3) {
          const payload = JSON.parse(atob(parts[1]));
          addLogEntry('info', 'JWT payload from localStorage:', payload);
          
          // Check token expiration
          const expiryDate = new Date(payload.exp * 1000);
          const now = new Date();
          addLogEntry('info', `JWT expiry: ${expiryDate.toLocaleString()}`);
          
          if (expiryDate < now) {
            addLogEntry('error', 'JWT token has expired - this is the cause of authentication issues');
          }
        }
      } catch (e) {
        addLogEntry('error', 'Failed to decode localStorage JWT:', e.message);
      }
    }
    
    // Test multiple user endpoints to pinpoint the problem
    try {
      const jwt = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');
      if (jwt) {
        addLogEntry('info', 'Testing token with multiple user endpoints');
        
        // Also test if the API endpoint is available without authentication
        try {
          addLogEntry('info', 'Testing API endpoint availability: GET /categories');
          const testResponse = await fetch(`${process.env.REACT_APP_API_URL}/categories`);
          addLogEntry('info', `API test status: ${testResponse.status} ${testResponse.statusText}`);
          
          if (testResponse.ok) {
            addLogEntry('success', 'API endpoint is available without authentication');
          }
        } catch (testError) {
          addLogEntry('error', 'API test failed:', testError.message);
        }
        
        // Try /users/me endpoint
        try {
          const meResponse = await fetch(`${process.env.REACT_APP_API_URL}/users/me`, {
            headers: { Authorization: `Bearer ${jwt}` }
          });
          
          addLogEntry('info', `/users/me status: ${meResponse.status} ${meResponse.statusText}`);
          
          if (meResponse.ok) {
            const data = await meResponse.json();
            addLogEntry('success', 'Current user data from /users/me:', data);
          } else {
            const text = await meResponse.text();
            addLogEntry('error', 'Failed to get current user from /users/me:', text);
          }
        } catch (meError) {
          addLogEntry('error', 'Error with /users/me endpoint:', meError.message);
        }
        
        // Try /users/current as an alternative
        try {
          const currentResponse = await fetch(`${process.env.REACT_APP_API_URL}/users/current`, {
            headers: { Authorization: `Bearer ${jwt}` }
          });
          
          addLogEntry('info', `/users/current status: ${currentResponse.status} ${currentResponse.statusText}`);
          
          if (currentResponse.ok) {
            const data = await currentResponse.json();
            addLogEntry('success', 'Current user data from /users/current:', data);
          } else {
            const text = await currentResponse.text();
            addLogEntry('error', 'Failed to get current user from /users/current:', text);
          }
        } catch (currentError) {
          addLogEntry('error', 'Error with /users/current endpoint:', currentError.message);
        }
        
        // Try directly accessing /users endpoint
        try {
          const usersResponse = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
            headers: { Authorization: `Bearer ${jwt}` }
          });
          
          addLogEntry('info', `/users status: ${usersResponse.status} ${usersResponse.statusText}`);
          
          if (usersResponse.ok) {
            const data = await usersResponse.json();
            addLogEntry('success', 'Users data from /users endpoint:', data);
            
            // Update the users table directly in our component state
            setTables(prevTables => {
              const updatedTables = [...prevTables];
              const usersTableIndex = updatedTables.findIndex(t => t.name === 'users');
              
              if (usersTableIndex !== -1) {
                // Ensure data is always an array
                const userData = Array.isArray(data) ? data : [data];
                
                updatedTables[usersTableIndex] = {
                  ...updatedTables[usersTableIndex],
                  records: userData,
                  fetchTime: new Date().toISOString()
                };
                
                addLogEntry('success', 'Updated users table with direct data', userData);
              }
              
              return updatedTables;
            });
          } else {
            const text = await usersResponse.text();
            addLogEntry('error', 'Failed to get users from /users endpoint:', text);
          }
          
          // Check for cached user data in localStorage
          const cachedUserData = localStorage.getItem('userData');
          if (cachedUserData) {
            try {
              const userData = JSON.parse(cachedUserData);
              addLogEntry('info', 'Found cached user data in localStorage:', userData);
            } catch (parseError) {
              addLogEntry('error', 'Failed to parse cached user data:', parseError.message);
            }
          } else {
            addLogEntry('warning', 'No cached user data found in localStorage');
          }
        } catch (usersError) {
          addLogEntry('error', 'Error with /users endpoint:', usersError.message);
        }
        
        // Check permissions since we failed to get user data
        await debugPermissions();
      }
    } catch (error) {
      addLogEntry('error', 'Error testing token:', error.message);
    }
    
    // Sync tokens if needed
    if ((localJwt && !sessionJwt) || (!localJwt && sessionJwt)) {
      const token = localJwt || sessionJwt;
      addLogEntry('info', 'Synchronizing tokens between storage locations');
      localStorage.setItem('jwt', token);
      sessionStorage.setItem('jwt', token);
      addLogEntry('success', 'Tokens synchronized');
    }
  };

  // Fetch real data from the backend API
  const fetchDatabaseInfo = async () => {
    setLoading(true);
    setError(null);
    clearLogs();
    addLogEntry('info', 'Starting to fetch database information');
    
    // Debug tokens first and try to sync user data
    await debugTokens();
    
    try {
      // Define the tables we want to fetch
      const tableDefinitions = [
        {
          name: 'lists',
          description: 'Stores the main list information',
          endpoint: '/lists',
          columns: [
            { name: 'uuid', type: 'uuid', primary: true },
            { name: 'name', type: 'character varying(255)' },
            { name: 'type', type: 'character varying(255)' },
            { name: 'permissions', type: 'integer' },
            { name: 'inserted_at', type: 'timestamp with time zone' },
            { name: 'updated_at', type: 'timestamp with time zone' }
          ]
        },
        {
          name: 'list_items',
          description: 'Contains items within each list',
          endpoint: '/list_items',
          columns: [
            { name: 'uuid', type: 'uuid', primary: true },
            { name: 'lists_uuid', type: 'uuid', foreign: 'lists.uuid' },
            { name: 'entity_uuid', type: 'uuid' },
            { name: 'inserted_at', type: 'timestamp with time zone' },
            { name: 'updated_at', type: 'timestamp with time zone' }
          ]
        },
        {
          name: 'users',
          description: 'Stores user information',
          endpoint: '/users',
          columns: [
            { name: 'uuid', type: 'uuid', primary: true },
            { name: 'email', type: 'character varying(255)' },
            { name: 'first_name', type: 'character varying(255)' },
            { name: 'last_name', type: 'character varying(255)' },
            { name: 'password_hash', type: 'character varying(1024)' },
            { name: 'inserted_at', type: 'timestamp with time zone' },
            { name: 'updated_at', type: 'timestamp with time zone' }
          ]
        },
        {
          name: 'users_link_lists',
          description: 'Associates users with the lists they own',
          endpoint: '/users_link_lists',
          columns: [
            { name: 'uuid', type: 'uuid', primary: true },
            { name: 'users_uuid', type: 'uuid', foreign: 'users.uuid' },
            { name: 'lists_uuid', type: 'uuid', foreign: 'lists.uuid' },
            { name: 'inserted_at', type: 'timestamp with time zone' },
            { name: 'updated_at', type: 'timestamp with time zone' }
          ]
        },
        {
          name: 'users_link_suggested_lists',
          description: 'Suggested lists for users',
          endpoint: '/users_link_suggested_lists',
          columns: [
            { name: 'uuid', type: 'uuid', primary: true },
            { name: 'users_uuid', type: 'uuid', foreign: 'users.uuid' },
            { name: 'lists_uuid', type: 'uuid', foreign: 'lists.uuid' },
            { name: 'source', type: 'character varying(255)' },
            { name: 'status', type: 'character varying(255)' },
            { name: 'priority', type: 'integer' },
            { name: 'context_data', type: 'jsonb' },
            { name: 'inserted_at', type: 'timestamp with time zone' },
            { name: 'updated_at', type: 'timestamp with time zone' }
          ]
        },
        {
          name: 'categories',
          description: 'Stores product categories',
          endpoint: '/categories',
          columns: [
            { name: 'uuid', type: 'uuid', primary: true },
            { name: 'name', type: 'character varying(255)' },
            { name: 'description', type: 'text' },
            { name: 'inserted_at', type: 'timestamp with time zone' },
            { name: 'updated_at', type: 'timestamp with time zone' }
          ]
        },
        {
          name: 'products',
          description: 'Stores product information',
          endpoint: '/products',
          columns: [
            { name: 'uuid', type: 'uuid', primary: true },
            { name: 'name', type: 'character varying(255)' },
            { name: 'description', type: 'text' },
            { name: 'category_uuid', type: 'uuid', foreign: 'categories.uuid' },
            { name: 'inserted_at', type: 'timestamp with time zone' },
            { name: 'updated_at', type: 'timestamp with time zone' }
          ]
        },
        {
          name: 'topics',
          description: 'Stores topic information',
          endpoint: '/topics',
          columns: [
            { name: 'uuid', type: 'uuid', primary: true },
            { name: 'name', type: 'character varying(255)' },
            { name: 'description', type: 'text' },
            { name: 'inserted_at', type: 'timestamp with time zone' },
            { name: 'updated_at', type: 'timestamp with time zone' }
          ]
        }
      ];
      
      // Initialize tables with skeleton structure
      setTables(tableDefinitions.map(table => ({
        ...table,
        records: []
      })));
      
      // Fetch data for each table in parallel
      addLogEntry('info', 'Starting parallel API requests for all tables');
      
      const tablesWithRecords = await Promise.all(
        tableDefinitions.map(async (table) => {
          try {
            // Make API call to fetch table records
            addLogEntry('info', `Fetching ${table.name} data from ${table.endpoint}...`);
            
            // Log detailed request information
            addLogEntry('info', `Request: GET ${table.endpoint}`, {
              method: 'GET',
              headers: { 'Content-Type': 'application/json' },
              url: table.endpoint
            });
            
            // Make the actual API call
            const startTime = Date.now();
            let response;
            
            try {
              response = await fetch(table.endpoint, {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}`
                }
              });
              const endTime = Date.now();
              const duration = endTime - startTime;
              
              // Log the raw response for debugging
              addLogEntry('info', `Response received from ${table.endpoint} in ${duration}ms`, {
                status: response.status,
                statusText: response.statusText,
                headers: Object.fromEntries([...response.headers.entries()])
              });
              
              // Add a banner message for non-200 responses to make errors more visible
              if (response.status !== 200) {
                message.error(`API Error: ${table.endpoint} returned status ${response.status} ${response.statusText}`, 5);
              }
            } catch (fetchError) {
              addLogEntry('error', `Network error fetching from ${table.endpoint}`, fetchError);
              // Show a big error message when network errors occur
              message.error(`Network Error: Failed to connect to ${table.endpoint} - ${fetchError.message}`, 5);
              throw fetchError;
            }
            
            if (!response.ok) {
              const responseText = await response.text();
              addLogEntry('error', `API call to ${table.endpoint} failed with status: ${response.status}`, {
                responseText,
                status: response.status,
                statusText: response.statusText
              });
              throw new Error(`API call to ${table.endpoint} failed with status: ${response.status}`);
            }
            
            let data;
            try {
              const responseText = await response.text();
              addLogEntry('info', `Raw response from ${table.endpoint}`, { 
                responseText: responseText.length > 500 ? 
                  responseText.substring(0, 500) + '... [truncated]' : 
                  responseText 
              });
              
              // Try to parse as JSON
              data = responseText ? JSON.parse(responseText) : [];
              
              // Special handling for the users table - we accept both array and single object responses
              if (table.name === 'users' && data && !Array.isArray(data)) {
                // If it's a single user record, wrap it in an array
                data = [data];
                addLogEntry('info', 'Converted single user object to array for consistency', data);
                
                // Force update the tables immediately to ensure the user shows up
                setTables(prevTables => {
                  const updatedTables = [...prevTables];
                  const usersTableIndex = updatedTables.findIndex(t => t.name === 'users');
                  
                  if (usersTableIndex !== -1) {
                    updatedTables[usersTableIndex] = {
                      ...updatedTables[usersTableIndex],
                      records: data,
                      fetchTime: new Date().toISOString()
                    };
                  }
                  
                  return updatedTables;
                });
              }
              
              // Log the parsed data structure
              const recordsCount = Array.isArray(data) ? 
                data.length : 
                (data.items ? data.items.length : 'unknown structure');
              
              addLogEntry('success', `Successfully parsed ${recordsCount} records from ${table.endpoint}`, {
                dataStructure: Array.isArray(data) ? 'array' : typeof data,
                sampleRecord: data && Array.isArray(data) && data.length > 0 ? data[0] : 'no records'
              });
              
              // If this is the users table and we successfully got data, update Redux store
              if (table.name === 'users' && Array.isArray(data) && data.length > 0) {
                addLogEntry('info', 'User data fetched directly - updating Redux store');
                
                // Update the Redux store with this user data
                // This would be handled by a direct Redux store update if we were in a connected component
                // For now, we'll just make a note of it
                addLogEntry('success', 'User data would be updated in Redux store', data);
              }
            } catch (parseError) {
              addLogEntry('error', `Failed to parse response from ${table.endpoint} as JSON`, parseError);
              throw parseError;
            }
            
            // Process the records
            let records;
            
            // Special handling for users table to ensure single records show properly
            if (table.name === 'users' && data && !Array.isArray(data)) {
              records = [data]; // Force single user object into an array
              addLogEntry('info', 'Wrapped single user object into array format');
            } else {
              records = Array.isArray(data) ? data : (data.items || []);
            }
            
            addLogEntry('success', `Final record count for ${table.name}: ${records.length}`);
            
            return {
              ...table,
              records,
              fetchTime: new Date().toISOString()
            };
          } catch (error) {
            const errorMessage = error.message || String(error);
            addLogEntry('error', `Error processing ${table.name} data:`, errorMessage);
            
            // Return table with empty records on error
            return {
              ...table,
              records: [],
              error: errorMessage,
              fetchTime: new Date().toISOString()
            };
          }
        })
      );
      
      setTables(tablesWithRecords);
    } catch (err) {
      setError('Failed to fetch database information');
      console.error('Error fetching database info:', err);
    } finally {
      setLoading(false);
    }
  };

  const renderColumns = (columns) => {
    return (
      <Table 
        dataSource={columns} 
        rowKey="name"
        pagination={false}
        size="small"
        columns={[
          {
            title: 'Column',
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            render: (text, record) => (
              <div>
                <Text strong={record.primary}>{text}</Text>
                {record.primary && <Text type="secondary"> (Primary Key)</Text>}
                {record.foreign && <Text type="secondary"> (→ {record.foreign})</Text>}
              </div>
            )
          },
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: '70%'
          }
        ]} 
      />
    );
  };

  const renderRecords = (records, columns) => {
    if (!records || records.length === 0) {
      return <Text type="secondary">No records found in this table.</Text>;
    }

    // Ensure records is always an array
    const recordsArray = Array.isArray(records) ? records : [records];
    
    // Create table columns based on the table schema
    const tableColumns = columns.map(col => ({
      title: col.name,
      dataIndex: col.name,
      key: col.name,
      ellipsis: true,
      render: (text) => {
        // Handle rendering of different data types
        if (text === null || text === undefined) return <Text type="secondary">NULL</Text>;
        if (typeof text === 'object') return <Text code>{JSON.stringify(text)}</Text>;
        return <Text>{String(text)}</Text>;
      }
    }));

    return (
      <Table 
        dataSource={recordsArray} 
        columns={tableColumns} 
        rowKey="uuid"
        scroll={{ x: 'max-content' }}
        pagination={{ pageSize: 5 }}
        size="small"
      />
    );
  };
  
  // Function to render the category products tab
  const renderCategoryProductsTab = () => {
    return (
      <div style={{ padding: '10px' }}>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          marginBottom: '16px'
        }}>
          <div>
            <Title level={4}>
              Products by Category 
              <Tag color="blue" style={{ marginLeft: '10px' }}>
                {Object.values(categoryProducts).reduce((sum, category) => sum + (category.products?.length || 0), 0)} Products
              </Tag>
            </Title>
            <Text type="secondary">Showing products for the first two categories</Text>
          </div>
          
          <Button 
            onClick={() => {
              // Get the first two categories and reload their products
              if (categories && categories.allCategories && categories.allCategories.length > 0) {
                const firstTwoCategories = categories.allCategories.slice(0, 2);
                Promise.all(
                  firstTwoCategories.map(category => 
                    fetchProductsDirectly(category.uuid, category.name)
                  )
                );
              }
            }}
            icon={<ReloadOutlined />}
            loading={loadingCategoryProducts}
          >
            Refresh Category Products
          </Button>
        </div>
        
        {loadingCategoryProducts && (
          <div style={{ padding: '20px', textAlign: 'center', marginBottom: '16px' }}>
            <Spin size="large" />
            <div style={{ marginTop: '10px' }}>
              <Text>Loading products for categories...</Text>
            </div>
          </div>
        )}
        
        {!loadingCategoryProducts && Object.keys(categoryProducts).length === 0 && (
          <div style={{ 
            padding: '30px', 
            textAlign: 'center', 
            background: '#f9f9f9', 
            borderRadius: '4px',
            marginBottom: '16px' 
          }}>
            <Title level={4}>
              <Tag color="blue">0 Products</Tag> in Categories
            </Title>
            <Text type="secondary" style={{ fontSize: '16px' }}>
              No category products loaded yet. 
              <br /><br />
              This could be because:
              <ul style={{ textAlign: 'left', display: 'inline-block' }}>
                <li>The product data is still loading</li>
                <li>The API request failed to return product data</li>
                <li>The selected categories don't have any products</li>
              </ul>
              <br />
              Click "Refresh Category Products" to try again.
            </Text>
          </div>
        )}
        
        {Object.keys(categoryProducts).length > 0 && (
          <Collapse defaultActiveKey={Object.keys(categoryProducts)}>
            {Object.entries(categoryProducts).map(([categoryId, category]) => (
              <Panel 
                header={
                  <span>
                    <strong>{category.categoryName}</strong> - {category.products.length} products
                  </span>
                } 
                key={categoryId}
              >
                <Table
                  dataSource={category.products}
                  rowKey="uuid"
                  pagination={{ pageSize: 10 }}
                  columns={[
                    {
                      title: 'Product',
                      dataIndex: 'name',
                      key: 'name',
                      render: (text, record) => (
                        <div>
                          <div><strong>{text}</strong></div>
                          <div style={{ color: '#666' }}>{record.description || 'No description'}</div>
                        </div>
                      ),
                      width: '40%'
                    },
                    {
                      title: 'UUID',
                      dataIndex: 'uuid',
                      key: 'uuid',
                      width: '30%',
                      ellipsis: true
                    },
                    {
                      title: 'Status',
                      key: 'status',
                      width: '30%',
                      render: (_, record) => (
                        <div>
                          {record.favorited && <Tag color="green">Favorited</Tag>}
                          {record.researched && <Tag color="blue">Researched</Tag>}
                          {record.purchased && <Tag color="purple">Purchased</Tag>}
                          {(!record.favorited && !record.researched && !record.purchased) && 
                            <Tag color="default">No Status</Tag>}
                        </div>
                      )
                    }
                  ]}
                />
              </Panel>
            ))}
          </Collapse>
        )}
      </div>
    );
  };
  
  // Function to render the topics with categories and products tab
  const renderTopicsCategoriesProductsTab = () => {
    // Instead of relying on the complex hierarchical data structure,
    // let's use the flat data we know works
    
    return (
      <div style={{ padding: '10px' }}>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          marginBottom: '16px'
        }}>
          <div>
            <Title level={4}>
              Topics → Categories → Products
              <Tag color="blue" style={{ marginLeft: '10px' }}>
                {allTopics?.length || 0} Topics
              </Tag>
              <Tag color="green" style={{ marginLeft: '10px' }}>
                {allCategories?.length || 0} Categories
              </Tag>
              <Tag color="purple" style={{ marginLeft: '10px' }}>
                {categoriesWithProducts?.reduce((sum, category) => sum + (category.products?.length || 0), 0) || 0} Products
              </Tag>
            </Title>
            <Text type="secondary">Showing nested hierarchy of topics, categories and products (Limit 100)</Text>
          </div>
          
          <Button 
            onClick={() => {
              readAllTopics();
              readAllCategories();
              readCategoriesWithProducts(null, null, null, 1, 100);
            }}
            icon={<ReloadOutlined />}
            loading={topicsLoading || allCategoriesLoading || categoriesWithProductsLoading}
          >
            Refresh Hierarchy
          </Button>
        </div>
        
        {(topicsLoading || allCategoriesLoading || categoriesWithProductsLoading) && (
          <div style={{ padding: '20px', textAlign: 'center', marginBottom: '16px' }}>
            <Spin size="large" />
            <div style={{ marginTop: '10px' }}>
              <Text>Loading topics, categories and products...</Text>
            </div>
          </div>
        )}
        
        {!topicsLoading && (!allTopics || allTopics.length === 0) && (
          <div style={{ 
            padding: '30px', 
            textAlign: 'center', 
            background: '#f9f9f9', 
            borderRadius: '4px',
            marginBottom: '16px' 
          }}>
            <Title level={4}>
              <Tag color="blue">0 Topics</Tag> Available
            </Title>
            <Text type="secondary" style={{ fontSize: '16px' }}>
              No topics loaded yet. 
              <br /><br />
              Click "Refresh Hierarchy" to try again.
            </Text>
          </div>
        )}
        
        {allTopics && allTopics.length > 0 && (
          <Collapse>
            {allTopics.map(topic => {
              // For each topic, manually find all categories (fallback strategy)
              // We'll just show all categories under each topic for now, without trying to filter
              // This will show each category under each topic
              const availableCategories = allCategories || [];
              
              return (
                <Panel 
                  header={
                    <span>
                      <strong>{topic.name}</strong> - {availableCategories.length} categories
                    </span>
                  } 
                  key={topic.uuid}
                >
                  {availableCategories.length === 0 ? (
                    <Text type="secondary">No categories available</Text>
                  ) : (
                    <Collapse>
                      {availableCategories.map(category => {
                        // Find the corresponding category in categoriesWithProducts to get its products
                        const categoryWithProducts = categoriesWithProducts?.find(cat => 
                          cat.uuid === category.uuid
                        );
                        
                        const products = categoryWithProducts?.products || [];
                        
                        return (
                          <Panel 
                            header={
                              <span>
                                <strong>{category.name}</strong> - {products.length} products
                              </span>
                            } 
                            key={category.uuid}
                          >
                            {products.length === 0 ? (
                              <Text type="secondary">No products in this category</Text>
                            ) : (
                              <Table
                                dataSource={products}
                                rowKey="uuid"
                                pagination={{ pageSize: 10 }}
                                columns={[
                                  {
                                    title: 'Product',
                                    dataIndex: 'name',
                                    key: 'name',
                                    render: (text, record) => (
                                      <div>
                                        <div><strong>{text}</strong></div>
                                        <div style={{ color: '#666' }}>{record.description || 'No description'}</div>
                                      </div>
                                    ),
                                    width: '40%'
                                  },
                                  {
                                    title: 'UUID',
                                    dataIndex: 'uuid',
                                    key: 'uuid',
                                    width: '30%',
                                    ellipsis: true
                                  },
                                  {
                                    title: 'Status',
                                    key: 'status',
                                    width: '30%',
                                    render: (_, record) => (
                                      <div>
                                        {record.favorited && <Tag color="green">Favorited</Tag>}
                                        {record.researched && <Tag color="blue">Researched</Tag>}
                                        {record.purchased && <Tag color="purple">Purchased</Tag>}
                                        {(!record.favorited && !record.researched && !record.purchased) && 
                                          <Tag color="default">No Status</Tag>}
                                      </div>
                                    )
                                  }
                                ]}
                              />
                            )}
                          </Panel>
                        );
                      })}
                    </Collapse>
                  )}
                </Panel>
              );
            })}
          </Collapse>
        )}
      </div>
    );
  };

  // Function to create a new record for a specific table with real API call
  const createRecord = async (tableName) => {
    setProcessingTable(tableName);
    
    try {
      message.loading(`Creating a test record in ${tableName}...`, 0.5);
      addLogEntry('info', `Starting to create a new record in table '${tableName}'`);
      
      // For user-related tables, make sure we debug authentication first
      if (tableName === 'users' || tableName === 'users_link_lists' || tableName === 'users_link_suggested_lists') {
        addLogEntry('info', 'Running authentication check before creating user record...');
        await debugTokens();
      }
      
      // Find the table definition
      const tableIndex = tables.findIndex(t => t.name === tableName);
      if (tableIndex === -1) {
        const errorMsg = `Table ${tableName} not found`;
        addLogEntry('error', errorMsg);
        throw new Error(errorMsg);
      }
      
      const table = tables[tableIndex];
      // Fix the API endpoint path - remove /api/v1 prefix since it's likely included in REACT_APP_API_URL
      const endpoint = `${process.env.REACT_APP_API_URL}${table.endpoint.replace('/api/v1', '')}`;
      
      addLogEntry('info', `Using corrected endpoint: ${endpoint}`);
      
      // Handle special cases for Redux-managed tables
      if (tableName === 'lists') {
        // Simplify to just use a direct API call since Redux isn't designed for this usage
        addLogEntry('info', 'Creating a list with direct API call');
        
        try {
          // Generate a predictable name with timestamp to identify this specific test
          const timestamp = new Date().toISOString().slice(11, 19).replace(/:/g, '');
          const listName = `Test List ${timestamp}`;
          
          // Use saved permission value if available, or default to permission=2
          const savedPermission = localStorage.getItem('lastSuccessfulPermission');
          const permissionValue = savedPermission ? parseInt(savedPermission, 10) : 2;
          
          // Prepare the list data
          const listData = {
            name: listName,
            type: 'test',
            permissions: permissionValue
          };
          
          // Log what we're about to do
          addLogEntry('info', `Creating list using Redux createList action with permission=${permissionValue}`, listData);
          
          try {
            // Use Redux action to create the list
            const result = await createList(listData);
            
            // Log success
            addLogEntry('success', 'List created successfully via Redux action', result);
            message.success(`List created: ${listName}`);
            
            // If we got here, save the permission value for future use
            localStorage.setItem('lastSuccessfulPermission', permissionValue.toString());
          } catch (error) {
            // If the first attempt fails, try with other permission values
            addLogEntry('warning', `First list creation attempt failed with permission=${localStorage.getItem('lastSuccessfulPermission') || 2}`, {
              error: error.message,
              response: error.response?.data
            });
            
            // Try alternative permission values
            const permissionValues = [0, 1, 2].filter(p => 
              p !== parseInt(localStorage.getItem('lastSuccessfulPermission') || '2', 10)
            );
            
            addLogEntry('info', `Trying alternative permission values: ${permissionValues.join(', ')}`);
            
            // Try each permission value
            let successfulPermission = null;
            let lastError = null;
            
            for (const permValue of permissionValues) {
              try {
                // Generate new list name for each attempt
                const newTimestamp = new Date().toISOString().slice(11, 19).replace(/:/g, '');
                const newListName = `Test List ${newTimestamp}`;
                
                const listData = {
                  name: newListName,
                  type: 'test',
                  permissions: permValue
                };
                
                addLogEntry('info', `Trying list creation with permission=${permValue}`, listData);
                
                // Use Redux action to create the list
                const result = await createList(listData);
                
                // If successful, stop trying
                addLogEntry('success', `Successfully created list with permission=${permValue}!`, result);
                successfulPermission = permValue;
                localStorage.setItem('lastSuccessfulPermission', permValue.toString());
                message.success(`List created: ${newListName} (permission=${permValue})`);
                
                // Break out of the loop - we succeeded
                break;
              } catch (retryError) {
                // Log the error but continue to the next permission value
                addLogEntry('warning', `Failed with permission=${permValue}: ${retryError.message}`, {
                  status: retryError.response?.status,
                  data: retryError.response?.data
                });
                
                lastError = retryError;
              }
            }
            
            // If all attempts failed
            if (!successfulPermission) {
              addLogEntry('error', 'List creation failed with all permission values', {
                tried: [parseInt(localStorage.getItem('lastSuccessfulPermission') || '2', 10), ...permissionValues],
                lastError: lastError?.message || error.message || 'Unknown error'
              });
              
              message.error(`Failed to create list: ${lastError?.message || error.message || 'Unknown error'}`);
            }
          }
          
          // Always update the user lists and clear processing state
          readUserLists();
          setProcessingTable(null);
          return;
        } catch (finalError) {
          // Enhanced error logging for any unexpected errors outside the main try/catch
          console.error('Unexpected error in list creation:', finalError);
          addLogEntry('error', `Unexpected error in list creation: ${finalError.message}`);
          message.error(`Unexpected error creating list: ${finalError.message}`);
          setProcessingTable(null);
          return;
        }
      }
      
      // For list items, use Redux actions
      if (tableName === 'list_items') {
        // Implementation coming soon - can add Redux action for list items
      }
      
      // For user_link_lists, use Redux actions 
      if (tableName === 'users_link_lists') {
        // Implementation coming soon - can add Redux action for user-list links
      }
      
      // For regular API calls, proceed with the standard approach
      // Prepare payload based on table
      let payload;
      
      switch (tableName) {
        case 'lists':
          // This should never be reached due to the handler above
          addLogEntry('warning', 'Falling back to direct API for list creation');
          
          // Check for previously successful permission value or try multiple values
          const savedPermission = localStorage.getItem('lastSuccessfulPermission');
          const permissionValue = savedPermission ? parseInt(savedPermission, 10) : 2;
          
          addLogEntry('info', `Using permission value ${permissionValue} from previous success`);
          
          payload = {
            name: `Test List ${Math.floor(Math.random() * 1000)}`,
            type: 'test',
            permissions: permissionValue
          };
          break;
          
        case 'list_items':
          // We need a list_uuid to create a list item
          const allLists = tables.find(t => t.name === 'lists')?.records || [];
          
          if (allLists.length === 0) {
            const missingMsg = 'Need to create a list first';
            addLogEntry('warning', missingMsg, { availableLists: [] });
            message.warning(missingMsg);
            setProcessingTable(null);
            return;
          }
          
          // Use the first real list
          const listId = allLists[0].uuid;
          addLogEntry('info', `Using list with ID ${listId} for list item`);
          
          // Regular API payload for backend lists
          payload = {
            lists_uuid: listId,
            entity_uuid: 'test-' + Math.random().toString(36).substring(2, 15)
          };
          addLogEntry('info', `Prepared payload for list_items table`, payload);
          break;
          
        case 'users':
          payload = {
            email: `test${Math.floor(Math.random() * 1000)}@example.com`,
            first_name: 'Test',
            last_name: 'User',
            password: 'test123'
          };
          addLogEntry('info', `Prepared payload for users table`, { ...payload, password: '[REDACTED]' });
          break;
          
        case 'categories':
          // Use data from Redux for categories if available
          payload = {
            name: `Test Category ${Math.floor(Math.random() * 1000)}`,
            description: `Description for test category ${Math.floor(Math.random() * 1000)}`
          };
          addLogEntry('info', `Prepared payload for categories table (will be created via API directly, not Redux)`, payload);
          break;
          
        case 'products':
          // We need a category_uuid to create a product - use Redux data if available
          let categoryId;
          
          if (categories && categories.allCategories && categories.allCategories.length > 0) {
            // Use category ID from Redux store
            categoryId = categories.allCategories[0].uuid;
            addLogEntry('info', 'Using category ID from Redux store', { categoryId });
          } else {
            // Fallback to local tables data
            categoryId = tables.find(t => t.name === 'categories')?.records[0]?.uuid;
          }
          
          if (!categoryId) {
            const missingMsg = 'Need to create a category first';
            addLogEntry('warning', missingMsg, { 
              categoriesInRedux: categories?.allCategories?.length || 0,
              availableCategories: tables.find(t => t.name === 'categories')?.records || [] 
            });
            message.warning(missingMsg);
            setProcessingTable(null);
            return;
          }
          
          payload = {
            name: `Test Product ${Math.floor(Math.random() * 1000)}`,
            description: `Description for test product ${Math.floor(Math.random() * 1000)}`,
            category_uuid: categoryId
          };
          addLogEntry('info', `Prepared payload for products table`, payload);
          break;
          
        case 'users_link_lists':
          // We need both user_uuid and list_uuid
          const userId = tables.find(t => t.name === 'users')?.records[0]?.uuid;
          const linkListId = tables.find(t => t.name === 'lists')?.records[0]?.uuid;
          if (!userId || !linkListId) {
            const missingMsg = 'Need to create both a user and a list first';
            addLogEntry('warning', missingMsg, { 
              availableUsers: tables.find(t => t.name === 'users')?.records || [],
              availableLists: tables.find(t => t.name === 'lists')?.records || []
            });
            message.warning(missingMsg);
            setProcessingTable(null);
            return;
          }
          
          // Regular API payload for backend lists
          payload = {
            users_uuid: userId,
            lists_uuid: linkListId
          };
          addLogEntry('info', `Prepared payload for users_link_lists table`, payload);
          break;
          
        default:
          // Generate a generic payload based on table columns
          payload = {};
          table.columns.forEach(column => {
            if (column.name !== 'uuid' && 
                column.name !== 'inserted_at' && 
                column.name !== 'updated_at') {
              
              if (column.name === 'name') {
                payload[column.name] = `Test ${tableName} ${Math.floor(Math.random() * 1000)}`;
              } else if (column.name === 'description') {
                payload[column.name] = `Description for test ${tableName} ${Math.floor(Math.random() * 1000)}`;
              } else if (column.name.endsWith('_uuid')) {
                // Find related table and use first record's uuid
                const relatedTableName = column.name.replace('_uuid', 's');
                const relatedTable = tables.find(t => t.name === relatedTableName);
                if (relatedTable && relatedTable.records.length > 0) {
                  payload[column.name] = relatedTable.records[0].uuid;
                }
              }
            }
          });
          addLogEntry('info', `Prepared generic payload for ${tableName} table`, payload);
      }
      
      // Log the API call
      addLogEntry('info', `Making API call to create record in ${tableName}`, {
        endpoint,
        method: 'POST',
        payload
      });
      
      console.log(`POST ${endpoint}`, payload);
      message.info(`API Call: POST ${endpoint}`);
      
      // Make the actual API call
      const startTime = Date.now();
      let response;
      
      try {
        response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt') || sessionStorage.getItem('jwt')}`
          },
          body: JSON.stringify(payload)
        });
        
        const endTime = Date.now();
        const duration = endTime - startTime;
        
        addLogEntry('info', `Received response from ${endpoint} in ${duration}ms`, {
          status: response.status,
          statusText: response.statusText,
          headers: Object.fromEntries([...response.headers.entries()])
        });
      } catch (fetchError) {
        const errorMsg = `Network error while posting to ${endpoint}: ${fetchError.message}`;
        addLogEntry('error', errorMsg, fetchError);
        throw new Error(errorMsg);
      }
      
      if (!response.ok) {
        const responseText = await response.text();
        const errorMsg = `API call to ${endpoint} failed with status: ${response.status}`;
        addLogEntry('error', errorMsg, {
          responseText,
          status: response.status,
          statusText: response.statusText
        });
        throw new Error(errorMsg);
      }
      
      // Parse the response
      let newRecord;
      try {
        const responseText = await response.text();
        addLogEntry('info', `Raw response for create record in ${tableName}`, {
          responseText: responseText.length > 500 ? 
            responseText.substring(0, 500) + '... [truncated]' : responseText
        });
        
        newRecord = responseText ? JSON.parse(responseText) : {};
        addLogEntry('success', `Successfully parsed response for new record in ${tableName}`, newRecord);
      } catch (parseError) {
        const errorMsg = `Failed to parse response from ${endpoint} as JSON`;
        addLogEntry('error', errorMsg, parseError);
        throw new Error(errorMsg);
      }
      
      // Update the table records
      const updatedTables = [...tables];
      updatedTables[tableIndex] = {
        ...table,
        records: [...table.records, newRecord]
      };
      
      setTables(updatedTables);
      const successMsg = `Record created successfully in ${tableName}`;
      message.success(successMsg);
      addLogEntry('success', successMsg, newRecord);
      
      // Show the API response
      const responseMsg = `API Response: UUID=${newRecord.uuid || newRecord.id || 'created'}`;
      message.info(responseMsg);
      
      // Refresh the table to make sure we have the latest data
      addLogEntry('info', `Refreshing ${tableName} table to get latest data`);
      await refreshTable(tableName);
      
    } catch (err) {
      const errorMsg = `Error creating record in ${tableName}: ${err.message}`;
      console.error(errorMsg, err);
      addLogEntry('error', errorMsg, err);
      message.error(errorMsg);
    } finally {
      setProcessingTable(null);
    }
  };
  
  // This function is no longer needed as we're using real API calls
  // But we'll keep it for reference or if we need to implement fallback behavior
  const generateMockRecord = (table) => {
    console.warn('Using mock record generation as fallback');
    
    const newRecord = {};
    const uuid = 'test-' + Math.random().toString(36).substring(2, 15);
    
    // Set basic fields
    newRecord.uuid = uuid;
    newRecord.inserted_at = new Date().toISOString();
    newRecord.updated_at = new Date().toISOString();
    
    if (table.name === 'categories') {
      newRecord.name = `Test Category ${Math.floor(Math.random() * 1000)}`;
      newRecord.description = `This is a test category`;
    } else if (table.name === 'products') {
      newRecord.name = `Test Product ${Math.floor(Math.random() * 1000)}`;
      newRecord.description = `This is a test product`;
      // Try to find a category UUID
      const categoriesTable = tables.find(t => t.name === 'categories');
      if (categoriesTable && categoriesTable.records.length > 0) {
        newRecord.category_uuid = categoriesTable.records[0].uuid;
      }
    }
    
    return newRecord;
  };
  
  // Function to refresh data for a specific table using Redux actions
  const refreshTable = async (tableName) => {
    setProcessingTable(tableName);
    
    try {
      message.loading(`Refreshing ${tableName} data...`, 0.5);
      addLogEntry('info', `Starting refresh for table '${tableName}'`);
      
      // Use appropriate Redux action based on table name
      switch (tableName) {
        case 'categories':
          addLogEntry('info', 'Using Redux action to fetch categories');
          readAllCategories();
          message.success('Categories data refreshed via Redux');
          break;
          
        case 'products':
          addLogEntry('info', 'Using Redux action to fetch products');
          readCategoriesWithProducts();
          message.success('Products data refreshed via Redux');
          break;
          
        case 'topics':
          addLogEntry('info', 'Using Redux action to fetch topics');
          readAllTopics();
          message.success('Topics data refreshed via Redux');
          break;
          
        case 'lists':
          addLogEntry('info', 'Using Redux action to fetch lists');
          readUserLists();
          message.success('Lists data refreshed via Redux');
          break;
          
        default:
          // For tables without direct Redux actions, use API directly
          const tableIndex = tables.findIndex(t => t.name === tableName);
          if (tableIndex === -1) {
            const errorMsg = `Table '${tableName}' not found in tables list`;
            addLogEntry('error', errorMsg);
            throw new Error(errorMsg);
          }
          
          const table = tables[tableIndex];
          // Make sure we're using the correct endpoint path by removing /api/v1 prefix
          const endpoint = `${process.env.REACT_APP_API_URL}${table.endpoint.replace('/api/v1', '')}`;
          
          addLogEntry('info', `Making API call to refresh table '${tableName}'`, {
            endpoint,
            method: 'GET',
            timestamp: new Date().toISOString()
          });
          
          console.log(`GET ${endpoint}`);
          message.info(`API Call: GET ${endpoint}`);
          
          const startTime = Date.now();
          try {
            // Use axios to maintain consistency with Redux actions
            const response = await axios.get(endpoint, {
              headers: { Authorization: 'Bearer ' + localStorage.getItem('jwt') }
            });
            const endTime = Date.now();
            const duration = endTime - startTime;
            
            addLogEntry('info', `Received response from ${endpoint} in ${duration}ms`, {
              status: response.status,
              statusText: response.statusText
            });
            
            // Process successful axios response
            const data = response.data;
            const records = Array.isArray(data) ? data : (data.items || []);
            
            addLogEntry('success', `Successfully refreshed ${tableName}`, {
              recordCount: records.length,
              sampleRecord: records.length > 0 ? records[0] : null
            });
            
            // Update the table with new records
            const updatedTables = [...tables];
            updatedTables[tableIndex] = {
              ...table,
              records,
              lastRefreshed: new Date().toISOString()
            };
            
            setTables(updatedTables);
            
            if (records.length > 0) {
              message.success(`${tableName} data refreshed (${records.length} records found)`);
            } else {
              message.info(`No records found in ${tableName}`);
            }
          } catch (error) {
            const errorMsg = `Error refreshing ${tableName}: ${error.message}`;
            
            if (error.response) {
              addLogEntry('error', `API error response: ${error.response.status}`, {
                data: error.response.data,
                headers: error.response.headers
              });
            } else if (error.request) {
              addLogEntry('error', 'No response received from API', {
                request: error.request
              });
            } else {
              addLogEntry('error', 'Error setting up request', {
                message: error.message
              });
            }
            
            console.error(errorMsg, error);
            message.error(errorMsg);
          }
      }
    } catch (err) {
      const errorMsg = `Error refreshing ${tableName}: ${err.message}`;
      console.error(errorMsg, err);
      addLogEntry('error', errorMsg, err);
      message.error(errorMsg);
    } finally {
      setProcessingTable(null);
    }
  };

  // Function to render the API logs
  const renderApiLogs = () => {
    if (apiLogs.length === 0) {
      return (
        <div style={{ 
          padding: '20px', 
          textAlign: 'center', 
          backgroundColor: '#f9f9f9', 
          borderRadius: '4px' 
        }}>
          <Text type="secondary">No logs available. Perform an action to see API logs.</Text>
          <div style={{ marginTop: '10px' }}>
            <Text type="secondary">
              Try clicking "Refresh" or "Create Record" to see API communication logs.
            </Text>
          </div>
        </div>
      );
    }
    
    const getLogTypeStyle = (type) => {
      switch (type) {
        case 'error': return { color: '#ff4d4f', fontWeight: 'bold' };
        case 'warning': return { color: '#faad14', fontWeight: 'bold' };
        case 'success': return { color: '#52c41a', fontWeight: 'bold' };
        case 'info': 
        default: return { color: '#1890ff' };
      }
    };
    
    // Count logs by type for summary
    const logCounts = apiLogs.reduce((acc, log) => {
      acc[log.type] = (acc[log.type] || 0) + 1;
      return acc;
    }, {});
    
    // Group logs by operation to make them easier to track
    const now = new Date();
    const last5Minutes = new Date(now.getTime() - 5 * 60 * 1000);
    const last15Minutes = new Date(now.getTime() - 15 * 60 * 1000);
    const filteredLogs = apiLogs.filter(log => new Date(log.timestamp) > last15Minutes);
    
    return (
      <div style={{ overflow: 'auto', marginBottom: '16px' }}>
        <div style={{ 
          marginBottom: '12px', 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          padding: '6px 10px',
          backgroundColor: '#f5f5f5',
          borderRadius: '4px'
        }}>
          <div>
            <Text strong>API Logs: {apiLogs.length} Total</Text>
            {Object.entries(logCounts).map(([type, count]) => (
              <Tag 
                key={type} 
                color={
                  type === 'error' ? 'red' : 
                  type === 'warning' ? 'orange' : 
                  type === 'success' ? 'green' : 'blue'
                }
                style={{ marginLeft: '6px' }}
              >
                {type}: {count}
              </Tag>
            ))}
          </div>
          <div>
            <Button 
              onClick={copyLogsToClipboard} 
              type="primary" 
              size="small"
              style={{ marginRight: '8px' }}
            >
              Copy Logs to Clipboard
            </Button>
            <Button 
              onClick={() => setGroupLogs(!groupLogs)}
              type={groupLogs ? "primary" : "default"}
              size="small"
              style={{ marginRight: '8px' }}
            >
              {groupLogs ? "Ungroup Logs" : "Group Logs"}
            </Button>
            <Button 
              onClick={clearLogs} 
              size="small"
            >
              Clear Logs
            </Button>
          </div>
        </div>
        
        <div style={{ 
          marginBottom: '8px', 
          padding: '4px 8px', 
          backgroundColor: '#f9f9f9', 
          borderRadius: '4px',
          fontSize: '12px'
        }}>
          <Text type="secondary">
            Showing recent logs first. {apiLogs.length} total logs 
            ({filteredLogs.length} in the last 15 minutes).
            {groupLogs ? " Logs are grouped by operation." : ""}
          </Text>
        </div>
        
        {groupLogs ? (
          // Group logs by similar operations
          Object.entries(
            apiLogs.reduce((groups, log) => {
              // Create groups based on operation patterns in the messages
              const operationMatch = log.message.match(/^(Starting to |Making API call to |Successfully |Error |Refreshing |Creating )/i);
              const operation = operationMatch ? operationMatch[0] : 'Other';
              
              // Use timestamp to further break up groups
              const timestamp = new Date(log.timestamp).toISOString().substring(0, 16); // Group by minute
              const groupKey = `${timestamp}_${operation}`;
              
              if (!groups[groupKey]) {
                groups[groupKey] = [];
              }
              groups[groupKey].push(log);
              return groups;
            }, {})
          ).sort((a, b) => {
            // Sort groups by timestamp, newest first
            return new Date(b[1][0].timestamp) - new Date(a[1][0].timestamp);
          }).map(([groupKey, logs]) => (
            <div 
              key={groupKey} 
              style={{ 
                marginBottom: '12px', 
                border: '1px solid #f0f0f0',
                borderRadius: '4px',
                overflow: 'hidden'
              }}
            >
              <div style={{ 
                padding: '8px', 
                backgroundColor: '#f5f5f5', 
                borderBottom: '1px solid #f0f0f0',
                fontWeight: 'bold'
              }}>
                <Text>
                  {logs[0].message.split('.')[0]} • {new Date(logs[0].timestamp).toLocaleTimeString()}
                  <Tag style={{ marginLeft: '8px' }}>{logs.length} logs</Tag>
                </Text>
              </div>
              <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                {logs.map(log => (
                  <div key={log.id} style={{ 
                    padding: '8px', 
                    borderBottom: '1px solid #f0f0f0',
                    borderLeft: `3px solid ${getLogTypeStyle(log.type).color}`,
                    backgroundColor: log.type === 'error' ? '#fff1f0' : 
                                    log.type === 'warning' ? '#fffbe6' : 
                                    log.type === 'success' ? '#f6ffed' : '#f0f7ff',
                  }}>
                    <div style={{ marginBottom: '4px', display: 'flex', justifyContent: 'space-between' }}>
                      <Text style={{ ...getLogTypeStyle(log.type) }}>{log.type.toUpperCase()}</Text>
                      <Text type="secondary" style={{ fontSize: '12px' }}>
                        {new Date(log.timestamp).toLocaleTimeString()}
                      </Text>
                    </div>
                    <div>
                      <Text style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{log.message}</Text>
                    </div>
                    {log.details && (
                      <div style={{ 
                        marginTop: '4px', 
                        padding: '4px',
                        borderRadius: '3px',
                        backgroundColor: 'rgba(0,0,0,0.02)'
                      }}>
                        <Text style={{ 
                          fontFamily: 'monospace', 
                          fontSize: '12px',
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'break-word'
                        }}>
                          {typeof log.details === 'string' ? 
                            log.details : 
                            JSON.stringify(log.details, null, 2)
                          }
                        </Text>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          // Standard log display - one by one
          apiLogs.map(log => (
          <div key={log.id} style={{ 
            padding: '8px', 
            marginBottom: '4px', 
            borderBottom: '1px solid #f0f0f0',
            borderLeft: `3px solid ${getLogTypeStyle(log.type).color}`,
            paddingLeft: '12px',
            backgroundColor: log.type === 'error' ? '#fff1f0' : 
                            log.type === 'warning' ? '#fffbe6' : 
                            log.type === 'success' ? '#f6ffed' : '#f0f7ff',
            borderRadius: '3px',
          }}>
            <div style={{ marginBottom: '4px', display: 'flex', justifyContent: 'space-between' }}>
              <Text style={{ ...getLogTypeStyle(log.type) }}>{log.type.toUpperCase()}</Text>
              <Text type="secondary" style={{ fontSize: '12px' }}>
                {new Date(log.timestamp).toLocaleTimeString()}
              </Text>
            </div>
            <div>
              <Text style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{log.message}</Text>
            </div>
            {log.details && (
              <div style={{ 
                marginTop: '4px', 
                padding: '4px',
                borderRadius: '3px',
                backgroundColor: 'rgba(0,0,0,0.02)'
              }}>
                <Text style={{ 
                  fontFamily: 'monospace', 
                  fontSize: '12px',
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word'
                }}>
                  {typeof log.details === 'string' ? 
                    log.details : 
                    JSON.stringify(log.details, null, 2)
                  }
                </Text>
              </div>
            )}
          </div>
        )))}
      </div>
    );
  };

  return (
    <Modal
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div><DatabaseOutlined /> Database Tables</div>
          <div>
            <Button 
              type={showLogs ? "primary" : "default"}
              size="small"
              onClick={() => setShowLogs(!showLogs)}
            >
              {showLogs ? "Hide API Logs" : "Show API Logs"}
            </Button>
          </div>
        </div>
      }
      open={visible}
      onCancel={onClose}
      width="90%"
      style={{ top: 20 }}
      footer={[
        <Button key="debug-permissions" onClick={debugPermissions} type="primary" danger icon={<ReloadOutlined />}>
          Run Permission Diagnostics
        </Button>,
        <Button key="debug-tokens" onClick={debugTokens} type="primary" icon={<ReloadOutlined />} style={{ marginLeft: 8 }}>
          Debug Authentication
        </Button>,
        <Button key="close" onClick={onClose} style={{ marginLeft: 8 }}>Close</Button>
      ]}
    >
      {loading ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Spin size="large" />
          <div style={{ marginTop: '20px' }}>
            <Text>Loading database information...</Text>
            <div style={{ marginTop: '10px' }}>
              <Text type="secondary">Connecting to backend API endpoints</Text>
            </div>
          </div>
        </div>
      ) : error ? (
        <div style={{ padding: '20px' }}>
          <div style={{ 
            backgroundColor: '#fff2f0', 
            padding: '20px', 
            borderRadius: '6px', 
            border: '1px solid #ffccc7',
            marginBottom: '20px' 
          }}>
            <Title level={4} style={{ color: '#ff4d4f' }}>Error Loading Database Information</Title>
            <Text type="danger" style={{ fontSize: '16px' }}>{error}</Text>
            <div style={{ marginTop: '12px' }}>
              <Text>There was a problem connecting to the backend API endpoints. Check the logs below for details.</Text>
            </div>
          </div>
          
          <div style={{ marginTop: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
              <Title level={4} style={{ margin: 0 }}>API Connection Logs</Title>
              <Button 
                onClick={copyLogsToClipboard} 
                type="primary"
              >
                Copy Logs to Clipboard
              </Button>
            </div>
            {renderApiLogs()}
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', height: '80vh' }}>
          {/* API Logs Panel - Always visible now */}
          <div style={{ 
            padding: '10px', 
            borderBottom: '1px solid #f0f0f0', 
            marginBottom: '10px',
            maxHeight: showLogs ? '40%' : '0',
            overflow: 'auto',
            transition: 'max-height 0.3s ease-in-out',
            display: showLogs ? 'block' : 'none'
          }}>
            <div style={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              marginBottom: '10px', 
              paddingBottom: '5px',
              borderBottom: '1px solid #e8e8e8'
            }}>
              <Title level={4} style={{ margin: 0 }}>API Connection Logs</Title>
              <Button size="small" onClick={clearLogs}>Clear Logs</Button>
            </div>
            {renderApiLogs()}
          </div>
          
          {/* Table Tabs - Take remaining height */}
          <div style={{ flex: 1, overflow: 'auto' }}>
            <Tabs defaultActiveKey="0">
              {/* Products by Category Tab */}
              <TabPane 
                tab={
                  <span>
                    <TableOutlined />
                    Products by Category {Object.keys(categoryProducts).length > 0 ? 
                      `(${Object.values(categoryProducts).reduce((sum, category) => sum + (category.products?.length || 0), 0)})` : 
                      '(0)'}
                  </span>
                } 
                key="category-products"
              >
                {renderCategoryProductsTab()}
              </TabPane>
              
              {/* Topics, Categories and Products Tab */}
              <TabPane 
                tab={
                  <span>
                    <TableOutlined />
                    Topics → Categories → Products 
                    ({allTopics?.length || 0} - {allCategoriesInTopics?.length || 0} - {categoriesWithProducts?.reduce((sum, category) => sum + (category.products?.length || 0), 0) || 0})
                  </span>
                } 
                key="topics-categories-products"
              >
                {renderTopicsCategoriesProductsTab()}
              </TabPane>
              
              {/* Database Tables Tabs */}
              {tables.map((table, index) => (
                <TabPane 
                  tab={
                    <span>
                      <TableOutlined />
                      {table.name} ({table.records?.length || 0})
                    </span>
                  } 
                  key={index}
                >
                  <div style={{ 
                    marginBottom: '20px', 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center' 
                  }}>
                    <div>
                      <Title level={4}>{table.name} <Tag color="blue">{table.records?.length || 0} Records</Tag></Title>
                      <Text type="secondary">{table.description}</Text>
                      <div style={{ marginTop: '5px' }}>
                        <Text type="secondary">Last Updated: {table.fetchTime ? new Date(table.fetchTime).toLocaleTimeString() : 'Never'}</Text>
                        {table.error && <Tag color="red" style={{ marginLeft: '10px' }}>Error: {table.error}</Tag>}
                        
                        {/* Special note for lists table */}
                        {table.name === 'lists' && (
                          <div style={{ 
                            marginTop: '10px', 
                            padding: '10px', 
                            backgroundColor: '#fff2e8', 
                            border: '1px solid #ffd591',
                            borderRadius: '4px' 
                          }}>
                            <Text strong style={{ color: '#d4380d' }}>Important:</Text> The backend may return 500 errors for some permission values. 
                            <br />
                            <Text strong>Using Redux Actions:</Text> We're using the Redux createList action to 
                            persist lists to the database, trying multiple permission values if needed.
                            <br />
                            <Text mark>Click "Create Record" to attempt creating a list with the recommended permission value.</Text>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      {table.name === 'lists' && (
                        <>
                          <Button
                            type="primary"
                            danger
                            icon={<ReloadOutlined />}
                            onClick={debugPermissions}
                            style={{ marginRight: '10px' }}
                          >
                            Check Permissions First
                          </Button>
                        </>
                      )}
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => createRecord(table.name)}
                        loading={processingTable === table.name}
                        style={{ 
                          marginRight: '10px',
                          background: '#5c9dff',
                          borderColor: '#4b87e8'
                        }}
                      >
                        Create Record
                      </Button>
                      <Button
                        icon={<ReloadOutlined />}
                        onClick={() => refreshTable(table.name)}
                        loading={processingTable === table.name}
                      >
                        Refresh
                      </Button>
                    </div>
                  </div>

                  <Collapse defaultActiveKey={['schema']}>
                    <Panel header="Table Schema" key="schema">
                      {renderColumns(table.columns)}
                    </Panel>
                    <Panel header="Records Data" key="records">
                      {renderRecords(table.records, table.columns)}
                    </Panel>
                  </Collapse>
                </TabPane>
              ))}
            </Tabs>
          </div>
        </div>
      )}
    </Modal>
  );
};

// Map Redux state to component props
const mapStateToProps = (state) => ({
  // Redux state objects
  categories: state.categories,
  products: state.products,
  topics: state.topics,
  lists: state.lists,
  
  // Specific data arrays
  allCategories: state.categories.allCategories,
  categoriesWithProducts: state.products.categoriesWithProducts,
  productsInCategory: state.products.products,
  allTopics: state.topics.allTopics,
  userLists: state.lists.userLists,
  allCategoriesInTopics: state.topics.allCategoriesInTopics,
  
  // Loading states
  allCategoriesLoading: state.categories.readAllCategoriesInProgress,
  productsLoading: state.products.readAllProductsInCategoryInProgress,
  categoriesWithProductsLoading: state.products.readCategoriesWithProductsInProgress,
  topicsLoading: state.topics.readAllTopicsInProgress,
  listsLoading: state.lists.readUserListsInProcess,
  categoriesInTopicsLoading: state.topics.readAllCategoriesInAllTopicsInProgress,
  // List action states
  createListInProcess: state.lists.createListInProcess,
  createListError: state.lists.createListError
});

// Map Redux actions to component props
const mapDispatchToProps = {
  readAllCategories,
  readProductsInCategory,
  readCategoriesWithProducts,
  readAllTopics,
  readAllCategoriesInAllTopics,
  readUserLists,
  createList
};

export default connect(mapStateToProps, mapDispatchToProps)(DatabaseTestView);