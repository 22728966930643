import React from 'react';
import { Typography, Row, Col, Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import BibiLogo from '../../assets/images/BibiLogo';
import CreateListForm from '../components/lists/CreateListForm';
import { HomeOutlined, UnorderedListOutlined, PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;

/**
 * View component for the Create List page
 * Contains the CreateListForm component which handles the actual list creation
 */
const CreateListView = () => {
  const history = useHistory();

  return (
    <div className="create-list-view" style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
      <div className="logo-container" style={{ 
        display: 'flex', 
        justifyContent: 'flex-start', 
        alignItems: 'center', 
        padding: '16px 24px',
        margin: 0,
        marginBottom: '15px',
        backgroundColor: 'white',
        borderBottom: 'none',
        boxShadow: '0 1px 4px rgba(0, 0, 0, 0.05)',
        marginLeft: 'calc(-50vw + 50%)',
        width: '100vw'
      }}>
        <BibiLogo width={200} height={28} />
      </div>
      
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item href="/" onClick={(e) => { e.preventDefault(); history.push('/'); }}>
          <HomeOutlined /> Home
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/plan" onClick={(e) => { e.preventDefault(); history.push('/plan'); }}>
          <UnorderedListOutlined /> Plans
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <PlusOutlined /> Create List
        </Breadcrumb.Item>
      </Breadcrumb>
      
      <Title level={2} style={{ marginBottom: '30px' }}>Create a New List</Title>
      
      <Row>
        <Col xs={24}>
          <CreateListForm />
        </Col>
      </Row>
      
      {/* Add space at the bottom for mobile navigation */}
      <div style={{ height: '120px' }}></div>
    </div>
  );
};

export default CreateListView;