import axios from 'axios';
import * as types from './actionTypes';

// Action creators for reading user lists
function readUserListsInProcess(bool) {
  return {
    type: types.READ_USER_LISTS_IN_PROCESS,
    readUserListsInProcess: bool
  };
}

function readUserListsSuccess(lists) {
  return {
    type: types.READ_USER_LISTS_SUCCESS,
    userLists: lists
  };
}

function readUserListsError(error) {
  return {
    type: types.READ_USER_LISTS_ERROR,
    error
  };
}

// Action creators for reading suggested lists
function readSuggestedListsInProcess(bool) {
  return {
    type: types.READ_SUGGESTED_LISTS_IN_PROCESS,
    readSuggestedListsInProcess: bool
  };
}

function readSuggestedListsSuccess(lists) {
  return {
    type: types.READ_SUGGESTED_LISTS_SUCCESS,
    suggestedLists: lists
  };
}

function readSuggestedListsError(error) {
  return {
    type: types.READ_SUGGESTED_LISTS_ERROR,
    error
  };
}

// Action creators for reading suggested list items
function readSuggestedListItemsInProcess(bool) {
  return {
    type: types.READ_SUGGESTED_LIST_ITEMS_IN_PROCESS,
    readSuggestedListItemsInProcess: bool
  };
}

function readSuggestedListItemsSuccess(items) {
  return {
    type: types.READ_SUGGESTED_LIST_ITEMS_SUCCESS,
    suggestedListItems: items
  };
}

function readSuggestedListItemsError(error) {
  return {
    type: types.READ_SUGGESTED_LIST_ITEMS_ERROR,
    error
  };
}

// Action creators for generating list suggestions
function generateListSuggestionsInProcess(bool) {
  return {
    type: types.GENERATE_LIST_SUGGESTIONS_IN_PROCESS,
    generateListSuggestionsInProcess: bool
  };
}

function generateListSuggestionsSuccess(message) {
  return {
    type: types.GENERATE_LIST_SUGGESTIONS_SUCCESS,
    message
  };
}

function generateListSuggestionsError(error) {
  return {
    type: types.GENERATE_LIST_SUGGESTIONS_ERROR,
    error
  };
}

// Action creators for generating list item suggestions
function generateListItemSuggestionsInProcess(bool) {
  return {
    type: types.GENERATE_LIST_ITEM_SUGGESTIONS_IN_PROCESS,
    generateListItemSuggestionsInProcess: bool
  };
}

function generateListItemSuggestionsSuccess(message) {
  return {
    type: types.GENERATE_LIST_ITEM_SUGGESTIONS_SUCCESS,
    message
  };
}

function generateListItemSuggestionsError(error) {
  return {
    type: types.GENERATE_LIST_ITEM_SUGGESTIONS_ERROR,
    error
  };
}

// Action creators for accepting list suggestions
function acceptListSuggestionInProcess(bool) {
  return {
    type: types.ACCEPT_LIST_SUGGESTION_IN_PROCESS,
    acceptListSuggestionInProcess: bool
  };
}

function acceptListSuggestionSuccess(suggestionUuid) {
  return {
    type: types.ACCEPT_LIST_SUGGESTION_SUCCESS,
    suggestionUuid
  };
}

function acceptListSuggestionError(error) {
  return {
    type: types.ACCEPT_LIST_SUGGESTION_ERROR,
    error
  };
}

// Action creators for rejecting list suggestions
function rejectListSuggestionInProcess(bool) {
  return {
    type: types.REJECT_LIST_SUGGESTION_IN_PROCESS,
    rejectListSuggestionInProcess: bool
  };
}

function rejectListSuggestionSuccess(suggestionUuid) {
  return {
    type: types.REJECT_LIST_SUGGESTION_SUCCESS,
    suggestionUuid
  };
}

function rejectListSuggestionError(error) {
  return {
    type: types.REJECT_LIST_SUGGESTION_ERROR,
    error
  };
}

// Action creators for adding items to lists
function addItemToListInProcess(bool) {
  return {
    type: types.ADD_ITEM_TO_LIST_IN_PROCESS,
    addItemToListInProcess: bool
  };
}

function addItemToListSuccess(listItem) {
  return {
    type: types.ADD_ITEM_TO_LIST_SUCCESS,
    listItem
  };
}

function addItemToListError(error) {
  return {
    type: types.ADD_ITEM_TO_LIST_ERROR,
    error
  };
}

// Clear list data
export function clearListData() {
  return {
    type: types.CLEAR_LIST_DATA
  };
}

// Clear list errors
export function clearListErrors() {
  return {
    type: types.CLEAR_LIST_ERRORS
  };
}

// Thunk action to fetch user lists
export function readUserLists() {
  return (dispatch) => {
    dispatch(readUserListsInProcess(true));
    console.log('Fetching user lists from API...');
    
    const config = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('jwt')}` }
    };
    
    return axios.get(`${process.env.REACT_APP_API_URL}/lists/user`, config)
      .then(response => {
        console.log('User lists API response:', response);
        console.log('User lists data:', response.data);
        
        // Check if we actually got an array of lists
        if (Array.isArray(response.data)) {
          console.log(`Received ${response.data.length} lists from API`);
          dispatch(readUserListsSuccess(response.data));
        } else {
          console.warn('Expected an array of lists but got:', typeof response.data);
          // If the response is not an array, convert it to an empty array
          dispatch(readUserListsSuccess([]));
        }
      })
      .catch(error => {
        console.error('Error fetching user lists:', error);
        console.error('Error details:', error.response || error.message);
        let errorMessage = 'Error fetching lists';
        if (error.response?.data?.Error) {
          errorMessage = error.response.data.Error;
        }
        dispatch(readUserListsError(errorMessage));
      })
      .finally(() => {
        dispatch(readUserListsInProcess(false));
      });
  };
}

// Thunk action to fetch suggested lists
export function readSuggestedLists(status = 'pending') {
  return (dispatch) => {
    dispatch(readSuggestedListsInProcess(true));
    
    const config = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('jwt')}` },
      params: { status }
    };
    
    return axios.get(`${process.env.REACT_APP_API_URL}/lists/suggestions`, config)
      .then(response => {
        dispatch(readSuggestedListsSuccess(response.data));
      })
      .catch(error => {
        console.error('Error fetching suggested lists:', error);
        let errorMessage = 'Error fetching suggested lists';
        if (error.response?.data?.Error) {
          errorMessage = error.response.data.Error;
        }
        dispatch(readSuggestedListsError(errorMessage));
      })
      .finally(() => {
        dispatch(readSuggestedListsInProcess(false));
      });
  };
}

// Action creators for creating a list
function createListInProcess(bool) {
  return {
    type: types.CREATE_LIST_IN_PROCESS,
    createListInProcess: bool
  };
}

function createListSuccess(list) {
  return {
    type: types.CREATE_LIST_SUCCESS,
    list
  };
}

function createListError(error) {
  return {
    type: types.CREATE_LIST_ERROR,
    error
  };
}

// Thunk action to create a list
export function createList(listData) {
  return (dispatch) => {
    dispatch(createListInProcess(true));
    
    const config = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('jwt')}` }
    };
    
    return axios.post(`${process.env.REACT_APP_API_URL}/lists`, listData, config)
      .then(response => {
        // Detailed logging to debug the response structure
        console.log('Create list response:', JSON.stringify(response, null, 2));
        console.log('Response data type:', typeof response.data);
        console.log('Response data:', JSON.stringify(response.data, null, 2));
        console.log('Response data keys:', Object.keys(response.data));
        
        // Extract UUID with better error handling
        let listUuid = null;
        let processedData = { ...response.data };
        
        if (response.data) {
            console.log('Checking response.data for UUID');
            if (response.data.uuid) {
                listUuid = response.data.uuid;
                console.log('Found UUID in response.data.uuid:', listUuid);
            } else if (response.data.Success === 'List Created' && response.data.uuid) {
                // The standard success response format
                listUuid = response.data.uuid;
                console.log('Found UUID in success response:', listUuid);
                
                // Add list data from the original request since the response only has UUID
                processedData = {
                    ...listData,
                    uuid: listUuid,
                    Success: response.data.Success
                };
            } else if (typeof response.data === 'object') {
                console.log('Response data is an object, checking all keys');
                // Log each key and value to find where the UUID might be
                Object.entries(response.data).forEach(([key, value]) => {
                    console.log(`Key: ${key}, Value:`, value);
                    if (key === 'uuid') {
                        listUuid = value;
                        console.log('Found UUID in response.data.uuid:', listUuid);
                    }
                });
            }
        }
        
        console.log('Final extracted list UUID:', listUuid);
        
        // Ensure the processed data has the UUID
        if (listUuid && !processedData.uuid) {
            processedData.uuid = listUuid;
        }
        
        console.log('Processed data to dispatch:', processedData);
        
        // Dispatch the processed data that includes the UUID
        dispatch(createListSuccess(processedData));
        
        // After creating the list, refresh the user lists to ensure we have the latest data
        dispatch(readUserLists());
        
        // Return the processed data with uuid
        return processedData;
      })
      .catch(error => {
        console.error('Error creating list:', error);
        let errorMessage = 'Error creating list';
        if (error.response?.data?.Error) {
          errorMessage = error.response.data.Error;
        }
        dispatch(createListError(errorMessage));
        throw error;
      })
      .finally(() => {
        dispatch(createListInProcess(false));
      });
  };
}

// Thunk action to fetch suggested list items
export function readSuggestedListItems(status = 'pending') {
  return (dispatch) => {
    dispatch(readSuggestedListItemsInProcess(true));
    
    const config = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('jwt')}` },
      params: { status }
    };
    
    return axios.get(`${process.env.REACT_APP_API_URL}/lists/items/suggestions`, config)
      .then(response => {
        dispatch(readSuggestedListItemsSuccess(response.data));
      })
      .catch(error => {
        console.error('Error fetching suggested list items:', error);
        let errorMessage = 'Error fetching suggested list items';
        if (error.response?.data?.Error) {
          errorMessage = error.response.data.Error;
        }
        dispatch(readSuggestedListItemsError(errorMessage));
      })
      .finally(() => {
        dispatch(readSuggestedListItemsInProcess(false));
      });
  };
}

// Thunk action to generate list suggestions
export function generateListSuggestions() {
  return (dispatch) => {
    dispatch(generateListSuggestionsInProcess(true));
    
    const config = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('jwt')}` }
    };
    
    return axios.post(`${process.env.REACT_APP_API_URL}/lists/suggestions/generate`, {}, config)
      .then(response => {
        dispatch(generateListSuggestionsSuccess(response.data.Success));
        // Reload suggestions after generating
        dispatch(readSuggestedLists());
      })
      .catch(error => {
        console.error('Error generating list suggestions:', error);
        let errorMessage = 'Error generating list suggestions';
        if (error.response?.data?.Error) {
          errorMessage = error.response.data.Error;
        }
        dispatch(generateListSuggestionsError(errorMessage));
      })
      .finally(() => {
        dispatch(generateListSuggestionsInProcess(false));
      });
  };
}

// Thunk action to generate list item suggestions
export function generateListItemSuggestions() {
  return (dispatch) => {
    dispatch(generateListItemSuggestionsInProcess(true));
    
    const config = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('jwt')}` }
    };
    
    return axios.post(`${process.env.REACT_APP_API_URL}/lists/items/suggestions/generate`, {}, config)
      .then(response => {
        dispatch(generateListItemSuggestionsSuccess(response.data.Success));
        // Reload suggestions after generating
        dispatch(readSuggestedListItems());
      })
      .catch(error => {
        console.error('Error generating list item suggestions:', error);
        let errorMessage = 'Error generating list item suggestions';
        if (error.response?.data?.Error) {
          errorMessage = error.response.data.Error;
        }
        dispatch(generateListItemSuggestionsError(errorMessage));
      })
      .finally(() => {
        dispatch(generateListItemSuggestionsInProcess(false));
      });
  };
}

// Thunk action to accept a list suggestion
export function acceptListSuggestion(suggestionUuid) {
  return (dispatch) => {
    dispatch(acceptListSuggestionInProcess(true));
    
    const config = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('jwt')}` }
    };
    
    const data = { status: 'accepted' };
    
    return axios.patch(`${process.env.REACT_APP_API_URL}/lists/suggestions/${suggestionUuid}/status`, data, config)
      .then(response => {
        dispatch(acceptListSuggestionSuccess(suggestionUuid));
        // Refresh user lists after accepting a suggestion
        dispatch(readUserLists());
        // Refresh suggested lists to remove the accepted one
        dispatch(readSuggestedLists());
      })
      .catch(error => {
        console.error('Error accepting list suggestion:', error);
        let errorMessage = 'Error accepting list suggestion';
        if (error.response?.data?.Error) {
          errorMessage = error.response.data.Error;
        }
        dispatch(acceptListSuggestionError(errorMessage));
      })
      .finally(() => {
        dispatch(acceptListSuggestionInProcess(false));
      });
  };
}

// Thunk action to reject a list suggestion
export function rejectListSuggestion(suggestionUuid) {
  return (dispatch) => {
    dispatch(rejectListSuggestionInProcess(true));
    
    const config = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('jwt')}` }
    };
    
    const data = { status: 'rejected' };
    
    return axios.patch(`${process.env.REACT_APP_API_URL}/lists/suggestions/${suggestionUuid}/status`, data, config)
      .then(response => {
        dispatch(rejectListSuggestionSuccess(suggestionUuid));
        // Refresh suggested lists to remove the rejected one
        dispatch(readSuggestedLists());
      })
      .catch(error => {
        console.error('Error rejecting list suggestion:', error);
        let errorMessage = 'Error rejecting list suggestion';
        if (error.response?.data?.Error) {
          errorMessage = error.response.data.Error;
        }
        dispatch(rejectListSuggestionError(errorMessage));
      })
      .finally(() => {
        dispatch(rejectListSuggestionInProcess(false));
      });
  };
}

// Thunk action to accept a list item suggestion
export function acceptListItemSuggestion(suggestionUuid, targetListUuid) {
  return (dispatch) => {
    dispatch(acceptListSuggestionInProcess(true));
    
    const config = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('jwt')}` }
    };
    
    const data = { 
      status: 'accepted',
      target_list_uuid: targetListUuid
    };
    
    return axios.patch(`${process.env.REACT_APP_API_URL}/lists/items/suggestions/${suggestionUuid}/status`, data, config)
      .then(response => {
        dispatch(acceptListSuggestionSuccess(suggestionUuid));
        // Refresh suggested list items to remove the accepted one
        dispatch(readSuggestedListItems());
      })
      .catch(error => {
        console.error('Error accepting list item suggestion:', error);
        let errorMessage = 'Error accepting list item suggestion';
        if (error.response?.data?.Error) {
          errorMessage = error.response.data.Error;
        }
        dispatch(acceptListSuggestionError(errorMessage));
      })
      .finally(() => {
        dispatch(acceptListSuggestionInProcess(false));
      });
  };
}

// Thunk action to reject a list item suggestion
export function rejectListItemSuggestion(suggestionUuid) {
  return (dispatch) => {
    dispatch(rejectListSuggestionInProcess(true));
    
    const config = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('jwt')}` }
    };
    
    const data = { status: 'rejected' };
    
    return axios.patch(`${process.env.REACT_APP_API_URL}/lists/items/suggestions/${suggestionUuid}/status`, data, config)
      .then(response => {
        dispatch(rejectListSuggestionSuccess(suggestionUuid));
        // Refresh suggested list items to remove the rejected one
        dispatch(readSuggestedListItems());
      })
      .catch(error => {
        console.error('Error rejecting list item suggestion:', error);
        let errorMessage = 'Error rejecting list item suggestion';
        if (error.response?.data?.Error) {
          errorMessage = error.response.data.Error;
        }
        dispatch(rejectListSuggestionError(errorMessage));
      })
      .finally(() => {
        dispatch(rejectListSuggestionInProcess(false));
      });
  };
}

// Thunk action to add an item to a list
export function addItemToList(listUuid, entityUuid) {
  return (dispatch) => {
    dispatch(addItemToListInProcess(true));
    
    const config = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('jwt')}` }
    };
    
    const data = { 
      list_uuid: listUuid,
      entity_uuid: entityUuid
    };
    
    return axios.post(`${process.env.REACT_APP_API_URL}/lists/items/add`, data, config)
      .then(response => {
        dispatch(addItemToListSuccess(response.data));
        // Could refresh user lists here if needed
      })
      .catch(error => {
        console.error('Error adding item to list:', error);
        let errorMessage = 'Error adding item to list';
        if (error.response?.data?.Error) {
          errorMessage = error.response.data.Error;
        }
        dispatch(addItemToListError(errorMessage));
      })
      .finally(() => {
        dispatch(addItemToListInProcess(false));
      });
  };
}