import React from 'react';

const BibiLogo = ({ width = 150, height = 40 }) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="30 120 140 40" preserveAspectRatio="xMinYMid meet" style={{ display: 'block', padding: 0 }}>
      {/* Large starting footprint (scaled to be about the size of the "B") */}
      <g transform="translate(50,140.2) scale(2.2)">
        <circle cx="-6" cy="-4" r="3" fill="#5c9dff" />
        <circle cx="0" cy="-6" r="3" fill="#5c9dff" />
        <circle cx="6" cy="-4" r="3" fill="#5c9dff" />
        <ellipse cx="0" cy="4" rx="6" ry="5" fill="#5c9dff" />
      </g>

      {/* "Bibi" text with regular "i"s and closer spacing */}
      <text x="90" y="160" fontFamily="Arial Rounded MT Bold, sans-serif" fontSize="40" fill="#000000" textAnchor="middle">
        B
      </text>
      <text x="115" y="160" fontFamily="Arial Rounded MT Bold, sans-serif" fontSize="40" fill="#000000" textAnchor="middle">
        i
      </text>
      <text x="140" y="160" fontFamily="Arial Rounded MT Bold, sans-serif" fontSize="40" fill="#000000" textAnchor="middle">
        b
      </text>
      <text x="165" y="160" fontFamily="Arial Rounded MT Bold, sans-serif" fontSize="40" fill="#000000" textAnchor="middle">
        i
      </text>
    </svg>
  );
};

export default BibiLogo;